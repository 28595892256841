// phone-number.validator.ts
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isValidPhoneNumber } from '../helpers';

@Directive({
  selector: '[appPhoneNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class PhoneNumberValidatorDirective implements Validator {
  @Input() appPhoneNumberValidator = true;

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value)
      return null;

    return isValidPhoneNumber(value) ? null : { invalidPhoneNumber: true };
  }
}