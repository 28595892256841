<div class="portal-app-header-container">
	<div [ngClass]="[environmentBackgroundClass]" class="server-bar flex flex-row justify-end items-center">
		<span>
			{{ environment }}
		</span>
	</div>

	<kendo-appbar position="top">
		<kendo-appbar-section>
			<div class="operator-logo"></div>
		</kendo-appbar-section>

		<kendo-appbar-section class="portal-app-search-container">
			<kendo-autocomplete (afterValueChanged)="onAfterValueChange($event)" [data]="globalSearchList"
			                    [filterable]="true" [placeholder]="'Search for a Customer, Premise, Task or Serial Number'" [value]="searchQuery" class="portal-app-search">
				<ng-template kendoAutoCompleteItemTemplate let-dataItem>
					<div class="portal-app-search-results flex flex-col">
						<div class="portal-app-search-category">
							{{ dataItem.heading }}
						</div>
						<div (click)="navigateTo(item)" *ngFor="let item of dataItem.data"
						     class="flex flex-row justify-between items-center portal-app-search-item">
							<div>{{ item.primaryVal }}</div>
							<div>{{ item.secondaryVal }}</div>
						</div>
					</div>
				</ng-template>
			</kendo-autocomplete>
		</kendo-appbar-section>

		<kendo-appbar-section>
			<app-portal-container-header-settings></app-portal-container-header-settings>
		</kendo-appbar-section>
	</kendo-appbar>
</div>

