import { Observable } from "rxjs";
import { DialogManagerService } from "../services";

export function confirmAndExecute<T>(
    dialogService: DialogManagerService,
    title: string,
    message: string,
    action: () => Observable<T>,
    successCallback?: (result: T) => void,
    cancelCallback?: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorCallback?: (error: any) => void,
  ): void {
    dialogService.openYesNoDialog(title, message).subscribe((confirmed: boolean) => {
      if (!confirmed) {
        cancelCallback?.();
        return;
      }
  
      action().subscribe({
        next: (result: T) => {
          successCallback?.(result);
        },
        error: (error) => {
          errorCallback?.(error);
        },
      });
    });
  }
