import { DASHBOARD_TABS, PORTAL } from '@aex/shared/common-lib';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LayoutComponent } from './module/layout/layout.component';
import { AutomatedInsightsComponent } from './automated-insights/automated-insights.component';
import { FaqComponent } from './layout/faq/faq.component';
import { PortalContainerComponent } from './layout/portal-container/portal-container.component';
import { UserProfileComponent } from './layout/user-profile/user-profile.component';
import { CheckEmailComponent } from './module/auth/check-email/check-email.component';
import { ForgotPasswordComponent } from './module/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './module/auth/login/login.component';
import { ResetPasswordComponent } from './module/auth/reset-password/reset-password.component';
import { DevicesComponent } from './module/devices/devices.component';
import { PortalAuthRouteGuard } from './shared/portal-route-guard';

export const PORTAL_APP_ROUTES: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: `${PORTAL.dashboardPage.name}/${DASHBOARD_TABS.services.name}`,
	},
	{
		path: `${PORTAL.loginPage.name}`,
		component: LoginComponent,
	},
	{
		path: `${PORTAL.forgotPassword.name}`,
		component: ForgotPasswordComponent,
	},
	{
		path: `${PORTAL.checkEmail.name}`,
		component: CheckEmailComponent,
	},
	{
		path: `${PORTAL.resetPassword.name}`,
		component: ResetPasswordComponent,
	},
	{
		path: ``,
		component: PortalContainerComponent,
		canActivate: [PortalAuthRouteGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('./module/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: PORTAL.customerPage.name,
				loadChildren: () => import('./module/customer/customer.module').then((m) => m.CustomerModule),
			},
			{
				path: PORTAL.taskPage.name,
				loadChildren: () => import('./module/task/task.module').then((m) => m.TaskModule),
			},
			{
				path: PORTAL.devices.name,
				component: DevicesComponent,
			},
			{
				path: PORTAL.manageProfilePage.name,
				component: UserProfileComponent,
			},
			{
				path: `${PORTAL.faq.name}`,
				component: FaqComponent,
			},
			{
				path: PORTAL.automatedInsights.name,
				component: AutomatedInsightsComponent,
			},
			{
				path: '',
				loadChildren: () => import('./module/classic-portal/classic-portal.module').then((m) => m.ClassicPortalModule),
			},
			{
				path: '',
				loadChildren: () => import('@aex/promo-discount').then((m) => m.PromoDiscountModule),
			},
			{
				path: '',
				loadChildren: () => import('@aex/customers').then((m) => m.CustomersModule),
			},
			{
				path: '',
				loadChildren: () => import('@aex/work-orders').then((m) => m.WorkOrdersModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(PORTAL_APP_ROUTES)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
