import {
	Directive,
	EventEmitter,
	Injector, Output,
} from "@angular/core";
import {
	ActivatedRoute,
	Router,
} from "@angular/router";
import {
	PageChangeEvent,
	SelectableMode,
} from "@progress/kendo-angular-grid";
import {
	infoCircleIcon,
} from '@progress/kendo-svg-icons';
import { ToastrService } from "ngx-toastr";

import { DialogManagerService } from "../services";

import {
	IPagedData,
	ActionItem,
	GridColumnItem,
	GridPageChangeEmitData,
 } from "..";

@Directive()
export abstract class BaseSearchGridComponent<TSearchDataItem>{

	public readonly svgInfoCircleIcon = infoCircleIcon;

	protected readonly toastr: ToastrService = this.injector.get(ToastrService);
	protected readonly dialogManagerService: DialogManagerService = this.injector.get(DialogManagerService);

	protected readonly router: Router = this.injector.get(Router);
	protected readonly route: ActivatedRoute = this.injector.get(ActivatedRoute);

	@Output() public readonly fetchGridPageEmitData : EventEmitter<GridPageChangeEmitData> = new EventEmitter<GridPageChangeEmitData>();
	protected constructor(
		protected readonly injector: Injector,
	){
	}

	public filteredData: TSearchDataItem[] = [];

	public pagedData: IPagedData<TSearchDataItem> = {
		page: 1,
		count: 0,
		total: 0,
		items: [],
	};

	public columns: GridColumnItem[] = [];

	// Pagination
	public pageSize = 20;
	public buttonCount = 5;
	public skip = 0;
	public currentPage = 1;

	// protected readonly defaultFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

	public kendoGridSelectKeyId : string = this.getKendoGridSelectKeyId(); // Key Id for the Kendo Grid Select

	public showCheckboxColumn : boolean = this.getShowCheckboxColumn();   // Show the checkbox column
	public gridSelectable : SelectableMode = this.checkboxSelectable();   // 'single' | 'multiple'
	// public selectableSettings : SelectableSettings = {
	// 	checkboxOnly: this.showCheckboxColumn,
	// 	mode: this.gridSelectable,
	// }

	// public hideQuickToolsList : IYesNo[] = [
	// 	{ id: true, name: 'Show Quick Tools' },
	// 	{ id: false, name: 'Hide Quick Tools' },
	// ];

	// public quickButtonDisabled: boolean = true;
	// public hideQuickTools: boolean = true;

	// protected readonly exportFileName = this.getExportFileName();

	public gridPageChangeEvent(event: PageChangeEvent) : void {
		this.pageSize = event.take;
		const currentPage = Math.floor(event.skip / this.pageSize) + 1; // Calculate the page number
		const skip = event.skip;
		this.fetchGridData(currentPage, this.pageSize, skip);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public nameHyperlinkActionEvent(event: any): void {
		const	actionItem = event as ActionItem<TSearchDataItem>;
		this.nameHyperlinkAction(actionItem.fieldName, actionItem.dataItem);
	}

	public checkBoxSelectionEvent(selectedItems: string[]):void{
		// this.selectedItems = selectedItems;
		// this.quickButtonDisabled = this.selectedItems.length === 0;
		this.onCheckBoxSelectionChange(selectedItems);
	}

	// Abstract Methods
	protected getShowCheckboxColumn() : boolean
	{
		return false;
	}
	protected checkboxSelectable() : SelectableMode
	{
		return 'multiple';
	}
	protected abstract fetchGridData(pageNumber: number, pageSize: number, skip: number): void;
	protected abstract getKendoGridSelectKeyId() : string;

	protected nameHyperlinkAction(_field:string, _action: TSearchDataItem): void {/* do nothing by default*/}
	protected onCheckBoxSelectionChange(_selectedItems: string[]) : void {/* do nothing by default*/}

}
