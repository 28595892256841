import { NgModule } from '@angular/core';
import { AfterValueChangedDirective, NoWhitespaceValidatorDirective, NumericOnlyDirective, PasswordValidatorDirective, PhoneNumberValidatorDirective } from './directives';
import { SafePipe } from './pipes';

@NgModule({
	declarations: [
		SafePipe,
		AfterValueChangedDirective,
		NoWhitespaceValidatorDirective,
		NumericOnlyDirective,
		PasswordValidatorDirective,
		PhoneNumberValidatorDirective,
	],
	exports: [
		SafePipe,
		AfterValueChangedDirective,
		NoWhitespaceValidatorDirective,
		NumericOnlyDirective,
		PasswordValidatorDirective,
		PhoneNumberValidatorDirective,
	],
})
export class CommonLibModule {}
