import {IExtendedProductBase} from "../interfaces/search-service";

const MONTH_TO_MONTH_CONTRACT = 'Month-to-Month';

export function getContractDescription(product: Partial<IExtendedProductBase>): string {
	if (product.min_contract <= 1)
		return MONTH_TO_MONTH_CONTRACT;

	return `${product.min_contract} months`;
}
