import { SearchService } from '@aex/shared/apis';
import { ConfigString, GlobalSearchObjects, IGlobalSearchItems, IInformationalObjects, MyRecordType, PORTAL } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ASSET_REFERENCE_COLUMN, CUSTOMER_COLUMN, DEVICE_ASSET_COLUMN, PREMISE_COLUMN, REFERENCE_COLUMN, SERVICE_COLUMN, WORKORDER_COLUMN } from '../../shared/constant';
import { IGlobalSearchData, IGlobalSearchObject } from '../../shared/interfaces/interface';

@Component({
	selector: 'app-portal-container-header',
	templateUrl: './portal-container-header.component.html',
	styleUrls: ['./portal-container-header.component.scss'],
})
export class PortalContainerHeaderComponent {
	public environment = '';
	public environmentBackgroundClass = '';

	public readonly searchIcon: string = 'assets/img/portal/search-grey.svg';
	public searchQuery: string = '';
	public globalSearchMap: Map<string, IGlobalSearchData> = new Map();
	public readonly serviceKey: string = SERVICE_COLUMN;
	public readonly customerKey: string = CUSTOMER_COLUMN;
	public readonly workOrderKey: string = WORKORDER_COLUMN;
	public readonly referenceKey: string = REFERENCE_COLUMN;
	public readonly premiseKey: string = PREMISE_COLUMN;
	public readonly assetReferenceKey: string = ASSET_REFERENCE_COLUMN;
	public readonly deviceAssetKey: string = DEVICE_ASSET_COLUMN;

	constructor(
		private readonly searchService: SearchService,
		private readonly router: Router,
		private readonly configService: ConfigService,
	) {
		this.initialiseServerDetails();

		this.globalSearchMap.set(this.customerKey, {
			heading: 'Customers',
			key: this.customerKey,
			data: [],
		});

		this.globalSearchMap.set(this.premiseKey, {
			heading: 'Premises',
			key: this.premiseKey,
			data: [],
		});

		this.globalSearchMap.set(this.workOrderKey, {
			heading: 'Tasks',
			key: this.workOrderKey,
			data: [],
		});

		this.globalSearchMap.set(this.deviceAssetKey, {
			heading: 'Serial Numbers',
			key: this.deviceAssetKey,
			data: [],
		});
	}

	private initialiseServerDetails(): void {
		const configName = this.configService.getConfigName();
		this.environment = configName.toLowerCase() !== ConfigString.Prod ? `${configName.toUpperCase()} SERVER`: '';
		this.environmentBackgroundClass = configName;
	}

	public get globalSearchList(): IGlobalSearchData[] {
		return Array.from(this.globalSearchMap.values());
	}

	public onAfterValueChange(value: string): void {
		this.searchQuery = value;
		if (this.searchQuery !== '')
			this.loadData();
	}

	public loadData(): void {
		this.searchService.globalSearch({ search_string: this.searchQuery }).subscribe((res) => {
			this.createGlobalSearchRecords(res.body.items);
		});
	}

	public createGlobalSearchRecords(items: IGlobalSearchItems[]): void {
		let customerRecords: IGlobalSearchObject[] = [];
		let premiseRecords: IGlobalSearchObject[] = [];
		let taskRecords: IGlobalSearchObject[] = [];
		let serialNumberRecords: IGlobalSearchObject[] = [];

		items.map((el) => {
			let { service: serviceObject, premise: premiseObject, customer: customerObject } = this.getRecordObjects(el?.informational_objects);
			const matchedObj = el.matched_objects[0];
			const matchedObjFieldName = matchedObj.field_matches[0].field_name;
			const item = {
				title: matchedObj?.object_type,
				primaryVal: matchedObj?.field_matches[0].matches[0].replace(/{{/g, '').replace(/}}/g, ''),
				secondaryVal: customerObject?.full_name as string,
				serviceId: serviceObject?.id as string,
				customerId: customerObject?.id as string,
			};

			if (item.title === this.customerKey) {
				const customerFullName = matchedObj?.field_matches.find((obj) => obj.field_name === `${this.customerKey}.full_name`);
				if (customerFullName)
					item.primaryVal = customerFullName.matches[0].replace(/{{/g, '').replace(/}}/g, '');

				item.secondaryVal = premiseObject?.external_reference as string;
				customerRecords.push(item);
			} else if (item.title === this.workOrderKey && matchedObjFieldName === `${this.workOrderKey}.${this.referenceKey}`)
				taskRecords.push(item);
			else if (matchedObjFieldName === `${this.premiseKey}.${this.assetReferenceKey}`)
				serialNumberRecords.push(item);
			else if (item.title === this.premiseKey)
				premiseRecords.push(item);
			else
				serialNumberRecords.push(item);
		});

		const keyToRecordsMapper = {
			[this.customerKey]: customerRecords,
			[this.premiseKey]: premiseRecords,
			[this.workOrderKey]: taskRecords,
			[this.deviceAssetKey]: serialNumberRecords,
		};

		Object.keys(keyToRecordsMapper).forEach((key) => {
			this.globalSearchMap.set(key, { ...this.globalSearchMap.get(key), data: keyToRecordsMapper[key] });
		});
	}

	public navigateTo(value: IGlobalSearchObject): void {
		if (value.serviceId)
			this.router.navigate([PORTAL.customerPage.name, value.serviceId]).then();
		else
			this.searchService.getAllCustomers({ search_string: value.customerId }).subscribe((response) => {
				this.router.navigate([PORTAL.customerPage.name, response.body.items[0].service.id]).then();
			});
		this.searchQuery = '';
	}

	private getRecordObjects(informationalObjects?: IInformationalObjects[]): GlobalSearchObjects {
		let serviceObject: MyRecordType;
		let customerObject: MyRecordType;
		let premiseObject: MyRecordType;

		if (informationalObjects && Array.isArray(informationalObjects))
			for (const informationalObject of informationalObjects)
				switch (informationalObject.object_type) {
					case this.serviceKey:
						serviceObject = informationalObject.object;
						break;
					case this.customerKey:
						customerObject = informationalObject.object;
						break;
					case this.premiseKey:
						premiseObject = informationalObject.object;
						break;
					default:
						break;
				}

		return { service: serviceObject, customer: customerObject, premise: premiseObject };
	}
}
