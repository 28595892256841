import {Injectable} from "@angular/core";
import {NotificationsService} from "@aex/shared/apis";
import {INotificationRequest} from "@aex/shared/common-lib";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class NotificationsDataService {
	constructor(private readonly notificationsService: NotificationsService) {}

	public publishCustomerNotification(
		notificationRequest:INotificationRequest,
	): Observable<void> {
		return this.notificationsService.publishCustomerNotification(notificationRequest)
	}
}
