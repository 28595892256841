<div class="w-11/12" data-testid="customer-container">
	<div class="flex flex-col gap-7">
		<div class="flex items-baseline justify-between">
			<h3 class="layout-heading">Devices</h3>
			<p (click)="toggleQuickTools()" class="flex items-center gap-3 quick-tools-button" data-testid="toggle-quick-tools-button">
				{{ !hideQuickTools ? 'Hide': 'Show' }} Quick Tools
				<img [height]="imageHeight" [ngSrc]="arrowDownIcon" [width]="imageWidth" alt="arrowDownIcon" />
			</p>
		</div>
		<div *ngIf="!hideQuickTools" class="flex justify-between tools" data-testid="quick-tools">
			<div class="flex flex-wrap items-center gap-2">
				<kendo-buttongroup class="flex-wrap gap-2" data-testid="filter-button-group-primary" selection="single">
					<app-grid-view-button
						(handleClick)="applyView($event)"
						(handleDelete)="deleteView($event)"
						*ngFor="let view of savedViews; index as i"
						[removable]="view.removable"
						[selected]="selectedView.name === view.name"
						[view]="view.name"
						kendoButton
					></app-grid-view-button>
				</kendo-buttongroup>
				<kendo-textbox
					(keydown.enter)="onEnterKeyPressed()"
					(keydown.escape)="onEscKeyPressed($event)"
					*ngIf="viewNameAdd"
					[(ngModel)]="viewNameChange"
					class="grid-view-input"
					id="save-view-textbox"
					placeholder="Enter View Name"
				></kendo-textbox>
			</div>
			<div class="flex gap-2">
				<div *ngIf="(canEdit | async) && !isViewPredefined">
					<a (click)="updateView()" class="base-button" data-testid="update-button"> UPDATE</a>
				</div>
				<div>
					<button (click)="saveView()" [disabled]="(canSave | async) === false" class="primary-button disabled-button" data-testid="save-view-button" kendoButton>SAVE VIEW</button>
				</div>
				<div>
					<button (click)="exportData()" class="export-button" data-testid="export-button">
						<img [height]="imageHeight" [ngSrc]="downloadIcon" [width]="imageWidth" alt="downloadIcon" />
						EXPORT
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="hideQuickTools" class="flex justify-between">
			<div class="flex justify-between hidden-tools">
				<div class="flex flex-wrap items-center gap-2">
					<kendo-buttongroup *ngIf="selectedView" class="flex-wrap gap-2" data-testid="filter-button-group-secondary" selection="single">
						<app-grid-view-button
							(handleClick)="applyView($event)"
							(handleDelete)="deleteView($event)"
							[removable]="selectedView?.removable"
							[selected]="true"
							[view]="selectedView?.name"
							kendoButton
						></app-grid-view-button>
					</kendo-buttongroup>
				</div>
				<button (click)="exportData()" class="export-button-sm" data-testid="export-button-short">
					<img [height]="imageHeight" [ngSrc]="downloadIcon" [width]="imageWidth" alt="downloadIcon" />
				</button>
			</div>
		</div>
		<div *ngIf="!hideQuickTools" class="flex justify-between">
			<div class="w-1/4 relative">
				<button
					#filterAnchor
					(click)="toggleFiltersPopup()"
					[svgIcon]="filterIcon"
					class="w-full justify-start h-content-container-v3"
					data-testid="customer-filter-dropdown-button"
					id="customer-filter-dropdown-button"
					kendoButton
				>
					Filter
				</button>
				<app-listing-filters
					(applyFilter)="applyFilter($event)"
					(toggle)="toggleFiltersPopup($event)"
					[anchor]="filterAnchor"
					[filterValue]="filterValue"
					[filters]="filters"
					[opened]="filtersOpened"
					data-testid="customer-filter-popup"
				></app-listing-filters>
			</div>
			<div class="w-1/4 relative">
				<kendo-multiselect
					(valueChange)="handleColumnsChange($event)"
					[autoClose]="false"
					[checkboxes]="true"
					[data]="dataGridColumns"
					[filterable]="true"
					[kendoDropDownFilter]="filterSettings"
					[value]="selectedGridColumns"
					class="fields"
					data-testid="customer-grid-columns-dropdown"
					kendoMultiSelectSummaryTag
					textField="heading"
					valueField="heading"
				>
					<ng-template kendoMultiSelectGroupTagTemplate let-dataItems> {{ dataItems.length }} column(s) selected</ng-template>
				</kendo-multiselect>
			</div>
			<div class="w-2/5 flex">
				<kendo-textbox (afterValueChanged)="onAfterValueChange($event)" class="search" data-testid="customer-grid-search-input" placeholder="Search Keyword">
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svgicon [icon]="kendoSearchIcon" [size]="'medium'" [themeColor]="'dark'"></kendo-svgicon>
					</ng-template>
				</kendo-textbox>
			</div>
		</div>
		<!-- grid start -->

		<app-data-grid
			(handleCellClick)="goToCustomerDetail($event)"
			(handleGridStateChange)="gridStateChange($event)"
			(handlePagination)="onPaginate($event)"
			(handleSort)="onSort($event)"
			[data]="data"
			[highlightedHeading]="highlightedHeading"
			[pageSize]="pageSize"
			[page]="page"
			[selectedGridColumns]="selectedGridColumns"
			[sort]="sort"
			[totalCount]="totalCount"
			data-testid="customer-data-grid"
		></app-data-grid>
	</div>
</div>
<!--<div kendoDialogContainer></div>-->
