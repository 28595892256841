import { pathify } from '@aex/ngx-toolbox';
import {
	ConfigurationMappingType,
	knoxUrl,
	maybeSurl,
	notificationServiceServer,
	paymentServiceServer,
	portalServer,
	pricingServiceServer,
	purchaseServiceServer,
	searchUrl,
	securityServiceUrl,
	serverUrl,
	supportUrl,
	Urls,
	wifiServer,
} from '@aex/shared/common-lib';
import { LatLngLiteral } from '@agm/core';

export class OperatorApi {
	public static operator(): string {
		return serverUrl('fnos');
	}
}

export class SettingsApi {
	public static settings(): string {
		return serverUrl('settings');
	}
}


/* Move to apis folder */
export class AssetApi {
	public static interestQuestions = (locationId: string, surl = true) =>
		maybeSurl(surl, Urls.Assets, Urls.InterestQuestions, Urls.Location, locationId);
}

export class NotificationApi {
	public static get notifications(): string { return serverUrl(Urls.Notifications); }
	public static notification = (id: string) => serverUrl(Urls.Notifications, id);
}

export class ProductApi {
	public static get providers(): string { return serverUrl(Urls.Providers); }
	public static get products(): string { return serverUrl(Urls.Products); }
	public static productsByLocation = (mapInfo: LatLngLiteral) => serverUrl(Urls.Products, Urls.Availability, mapInfo.lat, mapInfo.lng);
	public static product = (productsId: string) => serverUrl(Urls.Products, productsId);
	public static provider = (providerId: string) => serverUrl(Urls.Providers, providerId);
	public static deviceFromSerial = (serialNumber: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.Status);
	public static serviceFromSerial = (serialNumber: string) => serverUrl('premises', serialNumber, 'services');
	public static get services(): string { return serverUrl('services'); }
	public static productPriceOverrides(): string { return serverUrl(Urls.ServicePriceOverrides); }

	public static boltOnProducts(): string { return serverUrl(Urls.BoltOnProducts) };
}

export class StatusApi {
	public static deviceStatus = (deviceId: string) => serverUrl(Urls.NOC, Urls.Devices, deviceId);
	public static parentStatus = (parentId: string) => serverUrl(Urls.NOC, Urls.Devices, parentId);
	public static networkStatus = (networkId: string) => serverUrl(Urls.ServiceSatus, Urls.NMS, Urls.Networks, networkId);
	public static deviceDetailedStatus = (deviceId: string) => serverUrl(Urls.ServiceSatus, Urls.NMS, Urls.Networks, Urls.Nodes, deviceId, 'status');
	public static deviceFromSerial = (serialNumber: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.Status);

	public static fullServiceDeviceConfigFromSerial = (serialNumber: string, portNo: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.FullService, portNo);

	public static fullServiceDeviceStatusFromSerial = (serialNumber: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.FullStatus);

	public static serviceRadiusStatusFromSerial = (serialNumber: string, portNo: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.RadiusStatus, portNo);
	public static serviceConfigurations = () => serverUrl(Urls.ServiceConfigurations);

	public static deviceHistory = (serialNumber: string) => serverUrl(Urls.Network, Urls.Status, serialNumber, Urls.History);
	public static networkDataUsage = (serialNumber: string) => { return serverUrl(Urls.Network, serialNumber) };
	public static radiusHistory = (ppoeUserName: string) => { return serverUrl(Urls.Network, Urls.Radius, ppoeUserName) };
}

export class DeviceApi {
	public static fsanVerificationStatus = (fsan: string) => serverUrl(Urls.Devices, fsan, Urls.Verify);
	public static referenceStatus = (reference: string) => serverUrl(Urls.Devices, reference, Urls.Services);
	public static availablePorts = (reference: string) => serverUrl(Urls.Devices, reference, Urls.AvailablePorts);
	public static dataUsage = (fsan: string) => serverUrl(Urls.DevicesActions, fsan, Urls.DataUsage);
	public static pingTest = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.DiagnosticTest, Urls.Ping);
	public static traceRouteTest = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.DiagnosticTest, Urls.TraceRoute);
	public static traceRouteHistory = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.DiagnosticTest, Urls.TracerRoute);
	public static nSLookupTest = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.DiagnosticTest, Urls.NSLookup);

	public static speedTest = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.SpeedTest);
	public static speedTestHistory = (serialNumber: string) => serverUrl(Urls.DeviceActions, serialNumber, Urls.SpeedTests);
	public static rebootOnt = (serialNumber: string) => serverUrl(Urls.Devices, serialNumber, Urls.RebootOnt);

}

export class SupportApi {
	public static get triageConfig(): string { return supportUrl(Urls.Triage, Urls.Configurations); }
	public static get triageInstances(): string { return supportUrl(Urls.Triage, Urls.Instances); }
	public static triageInstance = (instanceId: string) => supportUrl(Urls.Triage, Urls.Instances, instanceId);
	public static triageInstancesPerPerson = () => supportUrl(Urls.Triage, Urls.Instances);
	public static triageInstancesPerId = (externalId: string) => supportUrl(Urls.Triage, Urls.Instances, Urls.ExternalId, externalId);
	public static get triageSnapshot(): string { return supportUrl(Urls.Triage, Urls.Snapshots); }
}

export class PaymentApi {
	public static get banks(): string { return paymentServiceServer(Urls.Banks); }
	public static payments = (serviceId: string) => paymentServiceServer(Urls.Payments, Urls.Payments, serviceId);
	public static get creditCardDetails(): string { return paymentServiceServer(Urls.Payments, Urls.CreditCard); }
	public static captureRefund = (paymentId: string): string => paymentServiceServer(Urls.Refunds, paymentId, 'refund');
	public static getPayFastCardMediumUpdateUrl = (mediumDetailId: string): string =>
		paymentServiceServer(Urls.PayFastPayments,'card-medium-update', mediumDetailId)
	public static initiatePreAuthorizationCheckout(): string {
		return paymentServiceServer(Urls.CardPayments, Urls.PeachPreAuthCheckout);
	}

}

export class PurchaseOrderApi {
	private static readonly PURCHASE_PLAN_CONTROLLER = 'purchase-plan';
	public static get paymentMediums(): string { return purchaseServiceServer(Urls.PaymentMediums); }
	public static get bankAccountDetails(): string { return purchaseServiceServer(Urls.BankDetails); }
	public static get creditCardDetails(): string { return purchaseServiceServer(Urls.CreditCardDetails); }
	public static get addPurchaseOrder(): string { return purchaseServiceServer(Urls.PurchaseOrder); }
	public static get createBuyNowPurchaseOrder(): string { return purchaseServiceServer(Urls.Purchases, Urls.CreateBuyNowPurchaseOrder); }
	public static get submitPurchaseOrderForCollection(): string { return purchaseServiceServer(Urls.Purchases, Urls.SubmitPurchaseOrderForCollection); }
	public static getServiceDetailsAndActions(serviceId: string): string { return purchaseServiceServer(Urls.Services, serviceId.toString(), Urls.ServiceDetailsAndActions); }
	public static get usageInfo(): string { return purchaseServiceServer(Urls.Usage); }
	public static get paymentHistory(): string { return purchaseServiceServer(Urls.Purchases); }
	public static updatePlanPaymentMedium = (serviceId: string, mediumDetailId: string) => purchaseServiceServer(Urls.PlanMediumUpdate, serviceId, mediumDetailId);
	public static setDebitDay = (serviceId: string, debitDay: number) => purchaseServiceServer(Urls.Services, serviceId.toString(), Urls.SetDebitDay, debitDay);
	public static get activePaymentMediumForService(): string { return purchaseServiceServer(Urls.ActivePaymentMediumForService); }
	public static get createPaymentLink(): string { return purchaseServiceServer(Urls.PaymentLinkMediumDetails); }
	public static get createStripePaymentMedium(): string { return purchaseServiceServer(Urls.CreateStripePaymentMedium); }
	public static get createPaymentMedium(): string { return purchaseServiceServer(Urls.CreatePaymentMedium); }
	public static retryPurchasePayment = (purchaseId: string): string => purchaseServiceServer(Urls.retryPurchasePayment, purchaseId);
	public static deletePaymentMedium = (customerId: string, mediumDetailId: string): string =>
		purchaseServiceServer(Urls.Medium, 'customer', customerId, 'medium', mediumDetailId);
	public static updateCardMediums = (customerId: string): string => purchaseServiceServer(Urls.Medium, 'customer', customerId, 'mediums', 'card');
	public static getPlanByService = (serviceId: string): string => purchaseServiceServer(PurchaseOrderApi.PURCHASE_PLAN_CONTROLLER, 'service', serviceId);
	public static get stripePaymentMediumExists(): string { return purchaseServiceServer(Urls.StripePaymentMediumExists); }
	public static peachRedirect = (providerId: string): string => purchaseServiceServer(Urls.PeachRedirect, providerId);
	public static get createService(): string { return purchaseServiceServer(Urls.Service); }
	public static get createPurchaseOnly(): string { return purchaseServiceServer(Urls.PurchaseOnly); }
	public static createPaymentLinkPurchase = (serviceId: string) => purchaseServiceServer(Urls.PaymentLinkPurchase, serviceId);
	public static createPaymentLinkPayment = (purchaseId: string): string => purchaseServiceServer(Urls.PaymentLinkPayment, purchaseId);
	public static get purchaseSource(): string { return purchaseServiceServer(Urls.Purchases, Urls.Sources); }
	public static createOffSystemPayment = (purchaseId: string): string => purchaseServiceServer(Urls.Purchases, purchaseId, 'payments');
	public static createOffSystemPaymentForUnSuspension = (serviceId: string): string => purchaseServiceServer(Urls.Services, serviceId, 'purchases', 'from-payment');
	public static createOffSystemPaymentOnDemand = (serviceId: string): string => purchaseServiceServer(Urls.Services, serviceId, 'purchases', 'on-demand');
	public static get getOffSystemPurchaseDetail(): string { return purchaseServiceServer(Urls.OffSystemPurchaseDetail); }
	public static captureOffset = (purchaseId: string): string => purchaseServiceServer(Urls.Purchases, purchaseId, 'offset');
	public static captureDayBundleOffset = (serviceId: string): string => purchaseServiceServer(Urls.Services, 'offset', serviceId);
}

export class RulesEngineApi {
	public static get createConfiguration(): string { return purchaseServiceServer(Urls.Configuration); }
	public static updateConfiguration = (configId: number): string => purchaseServiceServer(Urls.Configuration, configId);
	public static getConfiguration = (configId: number): string => purchaseServiceServer(Urls.Configuration, configId);
	public static getBillingScheme = (configId: number): string => purchaseServiceServer(Urls.Configuration, 'billingScheme', configId);
	public static getConfigurations = (): string => purchaseServiceServer(Urls.Configuration);
	public static deleteConfiguration = (configId: number): string => purchaseServiceServer(Urls.Configuration, configId);
	public static searchConfigurations = (): string => purchaseServiceServer(Urls.Configuration, 'search');
	public static getBillingSchemeByType = (configurationMappingType: ConfigurationMappingType, value: string): string =>
		purchaseServiceServer(Urls.Configuration, 'billingScheme', configurationMappingType, value);
}

export class NotificationsApi {
	public static getRequestList = (): string => notificationServiceServer(Urls.NotificationRequests);
	public static getRequest = (NotificationId: number): string => notificationServiceServer(Urls.NotificationRequests, NotificationId);
	public static publishCustomerNotification = (): string => notificationServiceServer("publish","customer");
}

export class CardPaymentApi {
	public static get prepareCheckout(): string { return paymentServiceServer(Urls.CardPayments, 'checkout'); }
	public static get returnStatus(): string { return paymentServiceServer(Urls.CardPayments, 'status'); }
}

export class MediumDetailApi {
	public static get updateStripePaymentMediumDetail(): string { return paymentServiceServer('medium-details', 'stripe-payment-medium'); }
	public static get updatePaymentMediumDetail(): string { return paymentServiceServer('medium-details', 'update-payment-medium'); }
}

export class ProvidersApi {
	public static getDefaultProviderByMediumType() { return paymentServiceServer('default'); }
}

export class StripePaymentIntentApi {
	public static get createSetupIntent(): string { return paymentServiceServer('stripe-payments', 'create-setup-intent'); }
}

export class InvoiceApi {
	public static DownloadInvoice = (operatorId: string, serviceId: string, id: string) => purchaseServiceServer('invoice', operatorId, serviceId, id);
	public static DownloadRefundDocument = () => purchaseServiceServer(Urls.RefundDocument);
}

export class MapApi {
	public static areas = (latitude: number | string, longitude: number | string) => serverUrl('areas', latitude, longitude);
}

export class AdvertApi {
	public static get ads(): string { return serverUrl('ads'); }
}

export class AuthApi {
	public static get authBackOffice(): string { return serverUrl('core', 'auth'); }
	public static get auth(): string { return securityServiceUrl('auth'); }
}

export class ProxyApi {
	public static get proxy(): string { return securityServiceUrl('auth', 'token', 'proxy'); }
}

export class CustomerApi {
	public static get installers(): string { return serverUrl('installers'); }
	public static customerPremises = (customerId: string, surl = true) => maybeSurl(surl, 'customers', customerId, 'premises');
	public static customer = (customerId: string) => serverUrl('customers', customerId);
	public static get customers(): string { return serverUrl('customers'); }
	public static resetPassword = (customerId: string) => serverUrl('customers', customerId, 'password', 'reset');
	public static get premises(): string { return serverUrl('premises'); }
	public static updatePremise = (premiseId: string) => serverUrl('premises', premiseId);
	public static updatePremiseDevice = (premiseId: string) => serverUrl('premises', premiseId, "serial-number");
	public static get idTypes(): string { return serverUrl('identification-types'); }
	public static files(): string { return serverUrl('files'); }
	public static customerRoles = (customerId: string) => serverUrl('customers', customerId, 'roles');
	public static get customerSalesAgents(): string { return serverUrl('customers/sales-agents'); }

	public static emailVerification(): string { return portalServer(Urls.Account, Urls.VerifyEmail); }
}

export class WorkOrderApi {
	public static get workOrders(): string { return serverUrl(Urls.WorkOrders); }
	public static createWorkOrder = () => serverUrl(Urls.WorkOrders);
	public static workOrder = (workOrderId: string) => serverUrl(Urls.WorkOrders, workOrderId);
	public static companies = (workOrderId: string, statusId: string) =>
		serverUrl(Urls.WorkOrders, workOrderId, statusId);
	public static companyPeople = (workOrderId: string, statusId: string, companyId: string) =>
		serverUrl(Urls.WorkOrders, workOrderId, statusId, companyId);
	public static quoteLocation = (workOrderRef: string) => serverUrl('files?namespace=work-orders', workOrderRef, 'Quote_Preparation');
	public static workOrderStatuses = () => {
		return serverUrl('statuses');
		}
	public static workOrderStatusesWithParams = (workOrderTypeId: number, count: number, enabled: boolean = false) => {
		let url = `statuses?type=${workOrderTypeId}&count=${count}`;
		if (enabled)
			url = `${url}&enabled=${enabled}`
		return serverUrl(url);
	}
	public static workOrderHistory = (workOrderId: string) => serverUrl(Urls.WorkOrders, workOrderId, Urls.History);
	public static get workOrdersSearch(): string {return searchUrl(Urls.WorkOrders, Urls.Search); }
	public static getWorkOrderStatusTransitions(statusId: string): string {
		return serverUrl(`${Urls.StatusTransitions}?source=${statusId}`);
	}
	public static get workOrderStatusTransitions(): string {return serverUrl(`${Urls.StatusTransitions}?source=`); }
	public static proxyWorkOrdersByStatus = (id: string, status: string = '') =>
		serverUrl(Urls.Proxy, Urls.WorkOrders, id, status);
	public static workOrderStatus = (workOrderStatusId: string) => serverUrl(`${Urls.Statuses}/${workOrderStatusId}`);
	public static statuses = () => serverUrl(`${Urls.Statuses}?count=200`);
	public static types = () => serverUrl(`${Urls.Types}?count=200`);
	public static providers = () => serverUrl(`${Urls.Providers}?count=200`);
	public static installers = () => serverUrl(`${Urls.Installers}?count=200`);
	public static main = (workOrderId?: string, statusId?: string, companyId?: string) =>
		serverUrl(Urls.WorkOrders, workOrderId, statusId, companyId);
	public static columns = () => searchUrl(Urls.WorkOrders, 'columns');
	public static exportCsv = () => searchUrl(Urls.WorkOrders, 'csv');
	public static statusCounter = () => searchUrl(Urls.WorkOrders, 'status-counter');

	public static cancellationReasons = () => portalServer(Urls.References, Urls.CancellationReasons);
}

export class KnoxApi {
	public static knoxUrlGeneral = (url: string) => knoxUrl(url);
}

export class ServiceApi {
	public static get servicesFull(): string { return serverUrl(Urls.Services, 'full'); }
	public static get services(): string { return serverUrl(Urls.Services); }
	public static get servicesSearch(): string { return serverUrl(Urls.Services, 'search'); }
	public static service = (serviceId: string) => serverUrl(Urls.Services, serviceId);
	public static workOrderTypes = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'work-order-types');
	public static fullService = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'full');
	public static serviceFromSerial = (serialNumber: string) => serverUrl('premises', serialNumber, 'services');
	public static get serviceChange(): string { return serverUrl('service-changes'); }
	public static statusCounter = () => searchUrl(Urls.Services, 'status-counter');
	public static get searchServiceServicesSearch(): string { return searchUrl(Urls.Services, 'search'); }
	public static get servicesColumns(): string { return searchUrl(Urls.Services, 'columns'); }
	public static servicesExportCsv = () => searchUrl(Urls.Services, 'csv');
	public static get globalSearch(): string { return searchUrl(Urls.GlobalSearch, 'results'); }
	public static customerServices = (customerId: string) => serverUrl(Urls.Customers, customerId, 'services');
	public static suspendService = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'suspend');
	public static activateService = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'activate');
	public static serviceStatuses = () => serverUrl('service-statuses');
	public static serviceHistory = () => serverUrl('service-histories');
	public static serviceWorkOrder = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'work_orders');
	public static get searchWorkOrdersServicesSearch(): string { return searchUrl(Urls.WorkOrders, 'search'); }
	public static get workOrderColumns(): string { return searchUrl(Urls.WorkOrders, 'columns'); }
	public static workOrdersExportCsv = () => searchUrl(Urls.WorkOrders, 'csv');
	public static serviceCancellationEffectiveDate = (serviceId: string) => serverUrl(Urls.Services, serviceId, 'cancellation-effective-date')
}

export class PremiseApi {
	public static premise = (premiseId: string) => serverUrl('premises', premiseId);
	public static predefinedPremise = (predefinedPremiseId: string) => serverUrl('predefined-premises', predefinedPremiseId);
	public static get predefined(): string { return serverUrl('predefined-premises'); }
	public static get client():  string { return serverUrl('client-premises'); }
	public static get clientPremisesFiltered(): string { return serverUrl('client-premises', 'client-premises-filtered'); }
	public static updateClientPremises = (clientPremisesId: number) => serverUrl(`client-premises/${clientPremisesId}`);
	public static clientPremises = (predefinedPremiseId: string) => serverUrl(`client-premises?predefined_premise_id=${predefinedPremiseId}`);
	public static predefinedByText = (searchString: string) => serverUrl(`predefined-premises?${searchString}&count=1`);

	public static fullPredefinedPremises = (predefinedPremiseId: number) => serverUrl(`predefined-premises/${predefinedPremiseId}/full`);

	public static updatePredefinedPremises = (predefinedPremiseId: number) => serverUrl(`predefined-premises/${predefinedPremiseId}`);

	public static predefinedPremiseStatuses = () => serverUrl(`predefined-premise-statuses`);

	public static fullPredefinedPremiseStatusList = () => serverUrl(`predefined-premise-status/full`);
	public static premiseHistory= () => serverUrl('premise-history');

}

export class ConfigApi {
	public static get dynamicEnvironment(): string { return pathify('assets', 'environment', 'app-config.json'); }
	public static environment = (environment: string) => pathify(`assets/environment/${environment}.json`);
	public static config = (fnoName: string, environment: string) => pathify(`assets/config/${fnoName}/${environment}.json`);
}

export class QuestionsApi {
	public static questionsPath = (questionsOperator: string) => pathify(`assets/data/interest-questions.${questionsOperator}.json`);
}

export class FilesApi {
	public static get files(): string { return serverUrl(Urls.Files); }
	public static file = (id: string) => serverUrl(Urls.Files, id);
	public static download = (id: number) => serverUrl(Urls.Files, id, 'download');
}

export class NocApi {
	public static serviceNodes = (assetReference: string) => serverUrl('noc', 'devices', assetReference);
	public static serviceVlans = (providerId: string, oltId: string) => serverUrl(`vlans?device_id=${oltId}&provider_id=${providerId}`);
}

export class WifiApi {
	public static wifiDevices = (fsan: string) => wifiServer(Urls.WifiInformation, fsan);
	public static aliasName = (mac: string) => wifiServer('alias-device', mac);
	public static deviceCount = (fsan: string) => wifiServer('device-count', fsan);
	public static wifiDevicesInfo = (fsan: string) => serverUrl(Urls.Network, Urls.Status, fsan, Urls.WifiDevices);
	public static wifiInformation = (fsan: string) => serverUrl(Urls.Network, Urls.Status, fsan, Urls.WifiInformation);

}

export class PortalApi {
	public static get emailConfirmLink(): string { return portalServer(Urls.Account, Urls.ClientAppConfirmationLink); }
	public static get emailConfirm(): string { return portalServer(Urls.Account, Urls.ClientAppConfirmEmail); }
	public static get resetPassword(): string { return portalServer(Urls.Account, Urls.ClientAppPasswordReset); }
	public static get resetPasswordLink(): string { return portalServer(Urls.Account, Urls.ClientAppPasswordResetLink); }
	public static get accountActivationLink(): string { return portalServer(Urls.Activate, Urls.Link); }
	public static qrRedirect(reference: string): string { return portalServer(`deviceManager/QRredirect/?reference=${reference}`); }
}

export class InstallsApi {
	public static get installs(): string { return serverUrl(Urls.Installers); }
	public static install = (id: string) => serverUrl(Urls.Installers, id);
	public static get repairs(): string { return serverUrl(Urls.Repairs); }
	public static repair = (id: string) => serverUrl(Urls.Repairs, id);
	public static get preorders(): string { return serverUrl(Urls.PreOrders); }
	public static preorder = (id: string) => serverUrl(Urls.PreOrders, id);
	public static get relocations(): string { return serverUrl(Urls.Relocations); }
	public static relocation = (id: string) => serverUrl(Urls.Relocations, id);
	public static get faults(): string { return serverUrl(Urls.Faults); }
	public static fault = (id: string) => serverUrl(Urls.Fault, id);
	public static workOrder = (id: string) => serverUrl(Urls.WorkOrders, id);
}

export class NIDApi {
	public static installs(latitude: number, longitude: number): string { return serverUrl(Urls.NIDInstall, latitude, longitude); }
	public static serviceWithNIDInstalled(id: string): string { return serverUrl(Urls.NIDInstall, id); }
	public static get nidInstalls(): string { return serverUrl(Urls.NIDInstall); }
}

export class AreaApi {
	public static areaTypes = () => serverUrl('area-types');
	public static areasByType = (typeId: number) => serverUrl(`${Urls.Areas}?type=${typeId}`);
	public static areasByParent = (parentAreaId: string) => serverUrl(`${Urls.Areas}?parent=${parentAreaId}`);
}

export class PredefinePremisesStatusApi {
	public static predefinePremisesStatuses = () => serverUrl(Urls.PredefinedPremiseStatuses);
	public static predefinePremisesStatusesById = (id: number) => serverUrl(`${Urls.PredefinedPremiseStatuses}/${id}`);
}

export class AutomatedActionsApi {
	// Rule Endpoints
	public static get createRule(): string { return serverUrl(`action-rules`); }
	public static rules = () => serverUrl('action-rules');
	public static rulesFull = () => serverUrl('action-rules/full');
	public static rule = (ruleId: number) => serverUrl(`action-rules/${ruleId}`);
	public static ruleFull = (ruleId: number) => serverUrl(`action-rules/${ruleId}/full`);
	public static updateRule = (ruleId: number) => serverUrl(`action-rules/${ruleId}`);
	public static runRule = () => serverUrl('action-rules/run-rule');

	//Rule History
	public static ruleHistory = () => serverUrl(`action-rule-histories`);

	// Condition Endpoints
	public static get createCondition(): string { return serverUrl(`action-conditions`); }
	public static get createFullCondition(): string { return serverUrl(`action-conditions/create-full-condition`); }
	public static conditions = () => serverUrl('action-conditions');
	public static conditionsFull = () => serverUrl('action-conditions/full');
	public static condition = (conditionId: number) => serverUrl(`action-conditions/${conditionId}`);
	public static conditionFull = (conditionId: number) => serverUrl(`action-conditions/${conditionId}/full`);
	public static updateCondition = (conditionId: number) => serverUrl(`action-conditions/${conditionId}`);

	// Condition Type Endpoints
	public static get createConditionType(): string { return serverUrl(`action-conditions-types`); }
	public static conditionTypes = () => serverUrl('action-conditions-types');
	public static conditionType = (conditionTypeId: number) => serverUrl(`action-conditions-types/${conditionTypeId}`);
	public static updateConditionType = (conditionTypeId: number) => serverUrl(`action-conditions-types/${conditionTypeId}`);

	// Operation Endpoints
	public static get createOperation(): string { return serverUrl(`action-operations`); }
	public static operations = () => serverUrl('action-operations');
	public static operation = (operationId: number) => serverUrl(`action-operations/${operationId}`);
	public static updateOperation = (operationId: number) => serverUrl(`action-operations/${operationId}`);

	// Condition Value Endpoints
	public static get createConditionValue(): string { return serverUrl(`action-conditions-values`); }
	public static conditionValues = () => serverUrl('action-conditions-values');
	public static conditionValue = (conditionValueId: number) => serverUrl(`action-conditions-values/${conditionValueId}`);
	public static updateConditionValue = (conditionValueId: number) => serverUrl(`action-conditions-values/${conditionValueId}`);

	// Step Endpoints
	public static get createStep(): string { return serverUrl(`action-steps`); }
	public static steps = () => serverUrl('action-steps');
	public static stepsFull = () => serverUrl('action-steps/full');
	public static step = (stepId: number) => serverUrl(`action-steps/${stepId}`);
	public static stepFull = (stepId: number) => serverUrl(`action-steps/${stepId}/full`);
	public static updateStep = (stepId: number) => serverUrl(`action-steps/${stepId}`);

	// Step Type Endpoints
	public static get createStepType(): string { return serverUrl(`action-steps-types`); }
	public static stepTypes = () => serverUrl('action-steps-types');
	public static stepType = (stepTypeId: number) => serverUrl(`action-steps-types/${stepTypeId}`);
	public static updateStepType = (stepTypeId: number) => serverUrl(`action-steps-types/${stepTypeId}`);

	// Step Type Transition Endpoints
	public static get createStepTypeTransition(): string { return serverUrl(`action-steps-types-transitions`); }
	public static stepTypeTransitions = () => serverUrl('action-steps-types-transitions');
	public static stepTypeTransition = (operationId: number) => serverUrl(`action-steps-types-transitions/${operationId}`);
	public static updateStepTypeTransition = (operationId: number) => serverUrl(`action-steps-types-transitions/${operationId}`);

	// Domain Endpoints
	public static domains = () => serverUrl('action-domains');
	public static domain = (domainId: number) => serverUrl(`action-domains/${domainId}`);
	public static updateDomain = (domainId: number) => serverUrl(`action-domains/${domainId}`);

	// Domain Field Endpoints
	public static domainFields = () => serverUrl('action-domains-fields');
	public static domainField = (domainFieldId: number) => serverUrl(`action-domains-fields/${domainFieldId}`);
	public static updateDomainField = (domainFieldId: number) => serverUrl(`action-domains-fields/${domainFieldId}`);

	// Rule Scheduler Endpoints
	public static ruleSchedules = () => serverUrl('action-schedule');
	public static ruleSchedule = (ruleScheduleId: number) => serverUrl(`action-schedule/${ruleScheduleId}`);
}

export class PricingServiceApi {
	public static promoDiscountTypeList = () => pricingServiceServer(Urls.PromoDiscountTypes);					// Price Reductions = Promo Discount Type
	public static promoPriceTypeList = () => pricingServiceServer(Urls.PriceTypes);										// Price Type is used for Promo Price Reduction
	public static promoCategoryList = () => pricingServiceServer(Urls.PromoCategories);								// Promo Category List
	public static promoReductionTypeList = () => pricingServiceServer(Urls.PromoReductionTypes);				// Promo Reduction Type List
	public static promoPeriodTypeList = () => pricingServiceServer(Urls.PromoPeriodTypes);							// Promo Period Type List
	public static promoCodeUsageList = () => pricingServiceServer(Urls.PromoCodeUsages);								// Promo Code Usage List
	public static promoAppliedByList = () => pricingServiceServer(Urls.PromoAppliedBy);								// Promo Applied By List
	public static promoAreaTreeList = () => pricingServiceServer(Urls.PromoAreaTree);				  				// Promo Area Tree
	public static promoProductList = () => pricingServiceServer(Urls.PromoProductList);								// Promo Product List
	public static promoBillingRuleList = () => pricingServiceServer(Urls.PromoBillingRules);						// Promo Billing Rule List
	public static promoServiceStatusList = () => pricingServiceServer(Urls.PromoServiceStatuses);  		// Promo Service Status List
	public static promoBillingStatusList = () => pricingServiceServer(Urls.PromoBillingStatuses);  		// Promo Billing Status List
	public static promotionDiscount = () => pricingServiceServer(Urls.PromotionDiscounts);  		    // Promotion Discounts
}
