import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { zip } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gtag: any;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  private hasInjected = false;

  constructor(
    private readonly router: Router,
    private readonly configService: ConfigService,
    @Inject(ENVIRONMENT_CONFIG_TOKEN) private readonly env: IEnvironment,
  ) {}

  public initialize(): Promise<void> {
	const debugMode = (this.env as unknown as { debug?: boolean })?.debug ?? false;
    return new Promise(resolve => {
      zip(
        this.configService.configValuesStream,
        this.configService.operatorStream,
      ).pipe(
        filter(([x, y]) => !!x && !!y),
      ).subscribe(([configValues]) => {
        if (configValues.hasGoogleAnalytics4 && configValues.googleAnalytics4Key)
			this.init(configValues.googleAnalytics4Key, debugMode);
        resolve();
      });
    });
  }

  private init(key: string, debugMode = false): void {
    if (!key || this.hasInjected) return;

    const scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
    scriptTag.async = true;
    document.head.appendChild(scriptTag);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${key}', ${debugMode ? '{ debug_mode: true }' : '{}'});
    `;
    document.head.appendChild(inlineScript);

    this.hasInjected = true;
    this.trackPageViews();
  }

  private trackPageViews(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd && typeof gtag !== 'undefined')
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
            page_location: window.location.href,
            page_title: document.title,
          });
      });
  }

  public setUserId(userId: string): void {
    if (typeof gtag !== 'undefined')
      gtag('set', { user_id: userId });
  }

  public sendEvent(name: string, params: Record<string, string | number | boolean | undefined> = {}): void {
    if (typeof gtag !== 'undefined')
      gtag('event', name, params);
  }
}
