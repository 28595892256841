import { WithDestroy } from '@aex/ngx-toolbox';
import { CustomerService } from '@aex/shared/apis';
import { CustomerUserService, FormMode, ICustomer } from '@aex/shared/common-lib';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends WithDestroy() {
	public currentUser: ICustomer;

	public formMode: FormMode = FormMode.View;
	public passwordFormMode: FormMode = FormMode.View;

	public firstName = '';
	public lastName = '';
	public emailAddress = '';
	public isEmailConfirmed = false;
	public contactNumber = '';
	public newPassword = '';
	public confirmPassword = '';

	public readonly emailMaxLength = 254;

	public get isViewMode(): boolean {
		return this.formMode === FormMode.View;
	}

	public get isPasswordViewMode(): boolean {
		return this.passwordFormMode === FormMode.View;
	}

	constructor(
		private readonly customerUserService: CustomerUserService,
		private readonly customerService: CustomerService,
		private readonly toastrService: ToastrService,
	) {
		super();
		this.noZombie(this.customerUserService.currentUserStream).subscribe((user) => {
			this.currentUser = user;
			this.initialiseUserProfile(user);
		});
	}

	public initialiseUserProfile(user: ICustomer): void {
		this.firstName = user.first_name;
		this.lastName = user.last_name;
		this.emailAddress = user.email;
		this.contactNumber = user.mobile_number;
	}

	public cancelClick(): void {
		this.formMode = FormMode.View;
		this.initialiseUserProfile(this.currentUser);
	}

	public saveClick(): void {
		this.updateProfile();
	}

	public editClick(): void {
		this.formMode = FormMode.Edit;
	}

	public cancelPasswordClick(): void {
		this.passwordFormMode = FormMode.View;
		this.newPassword = '';
		this.confirmPassword = '';
	}

	public savePasswordClick(): void {
		this.updatePassword();
	}

	public editPasswordClick(): void {
		this.passwordFormMode = FormMode.Edit;
	}

	public updateProfile(): void {
		const customer: Partial<ICustomer> = {
			first_name: this.firstName.trim(),
			last_name: this.lastName.trim(),
			email: this.emailAddress.trim(),
			mobile_number: this.contactNumber.trim(),
		};

		this.customerService.updateCustomer(
			this.currentUser.id,
			customer,
		).subscribe((user) => {
			this.customerUserService.currentUser = user;
			this.formMode = FormMode.View;
			this.toastrService.success('Your profile has been updated.');
		});
	}

	public resendEmail(): void {
		this.customerService.sendVerificationEmail(
			this.currentUser.email,
		).subscribe((result) => {
			if (result)
				this.toastrService.success('Verification email has been sent.');
			else
				this.toastrService.warning('Failed to send verification email. Please try again later.');
		});
	}

	public updatePassword(): void {
		if (this.newPassword !== this.confirmPassword)
			this.toastrService.error('Passwords do not match');
		else {
			const customer: Partial<ICustomer> = {
				password: this.newPassword,
			};

			this.customerService.updateCustomer(
				this.currentUser.id,
				customer,
			).subscribe(() => {
				this.passwordFormMode = FormMode.View;
				this.toastrService.success('Your password has been updated.');
			});
		}
	}
}
