<div class="portal-settings-container">
	<div class="flex flex-row justify-start items-center profile-settings-container">
		<kendo-avatar [rounded]="'full'" [fill]="'outline'" [themeColor]="'primary'" [border]="true" [size]="'medium'" [svgIcon]="userIconSvg"></kendo-avatar>
		<div class="flex flex-col">
			<span>{{ currentUserDisplayName}}</span>
			<button kendoButton [fillMode]="'link'" [routerLink]="manageProfileRoute" (click)="viewManageProfile()">Manage Profile</button>
		</div>
	</div>

	<!--	TODO:portal2 what does selecting this do?-->
	<kendo-dropdownlist [data]="profileList" [value]="currentUser" textField="id" valueField="id">
		<ng-template kendoDropDownListValueTemplate let-dataItem>
			<ng-container *ngTemplateOutlet="userDisplayTemplate; context: { $implicit: dataItem }"></ng-container>
		</ng-template>
		<ng-template kendoDropDownListItemTemplate let-dataItem>
			<ng-container *ngTemplateOutlet="userDisplayTemplate; context: { $implicit: dataItem }"></ng-container>
		</ng-template>
	</kendo-dropdownlist>

	<ng-template #userDisplayTemplate let-dataItem>
		<span>{{ dataItem.full_name || dataItem.first_name + ' ' + dataItem.last_name || dataItem.username }}</span>
	</ng-template>

	<button kendoButton (click)="navigateToClassicPortal()">CLASSIC PORTAL</button>
	<button kendoButton themeColor="primary" (click)="initiateLogOut()">LOG OUT</button>
</div>

<iframe #portalIframeLogout title="classic portal" id="authPortalIframe" [src]="trustedUrl | safe">
</iframe>
