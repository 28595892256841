<div class="page-container">
	<h3>
		Frequently Asked Questions
	</h3>

	<h5>
		Introducing Portal 2.0
	</h5>

	<div class="flex flex-col lg:flex-row gap-x-4 gap-y-4">
		<div class="flex flex-col lg:flex-1 gap-y-4">
			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What are the new features introduced in the updated portal?
					</span>
				</ng-template>
				<p>
					Portal 2.0 offers a redesigned front end that is easy to navigate. We have added the following new features to
					enhance the capabilities of the portal:
				</p>
				<ol>
					<li>
						Global search: A new search functionality available on the home page that allows you to search for a
						customer or a feature and will suggest results in the results bar. You can then click on one of the
						suggestions to take you to that customer/feature. The global search function will also allow you to search
						for old terms (such as services, work orders) and populate results based on the new features.
					</li>
					<li>
						Single pane dashboard: The new dashboard appears on the home page and provides you with a holistic view of
						active customers and statuses of Tasks.
					</li>
					<li>
						Tool tips: We've designed Portal 2.0 with the aim to help you navigate the system with ease. The portal has
						tool tips that are always available to give you insight into how to use a function.
					</li>
				</ol>
				<p>
					In addition, Portal 2.0 boasts a new look and feel for the Service view now known as the Customer view and the
					Work Order view, now known as Tasks.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						How do I access and use the new features in the new portal?
					</span>
				</ng-template>
				<p>
					Portal 2.0 has a left navigation pane and all features (both new and old) are accessible from one of these
					navigation items.
				</p>
				<ol>
					<li>
						Global Search is available on the home screen.
					</li>
					<li>
						Single pane dashboard is available on the home screen.
					</li>
					<li>
						Tool tips appear throughout the portal.
					</li>
				</ol>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						How do the new features integrate with existing functionality in the portal?
					</span>
				</ng-template>
				<p>
					All existing functionality from the portal has been mapped to Portal 2.0, so you are still able to complete
					any Task or function.
				</p>
			</kendo-expansionpanel>
		</div>

		<div class="flex flex-col lg:flex-1 gap-y-4">
			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						Where can I find the Task (Work Orders) that have been assigned to me?
					</span>
				</ng-template>
				<p>
					Work orders have been redesigned to be called Tasks. From the home page, click on the Tasks tab. Here you will
					be able to view all the Tasks that have been assigned to you, your team if you are a team leader, or all Tasks
					that have been created.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						Where can I find historical/completed Tasks (Work Orders)?
					</span>
				</ng-template>
				<p>
					You can access this from the Tasks tab from the home page or alternatively, you can select a customer, click
					on Tasks from the customer view and view that customers Task history.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						Where can I find service information?
					</span>
				</ng-template>
				<p>
					Services are now refocused to be centered around customer information. You can access the customer
					information from the home page by clicking on the customers tab. Here you will be able to view all customers,
					and when selected you can view all active services associated to that customer.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						How do I manage the entity that I am currently working on?
					</span>
				</ng-template>
				<p>
					At the top, right hand corner of the screen is your user management icon. Here you can manage your profile and
					toggle between entities.
				</p>
			</kendo-expansionpanel>
		</div>
	</div>

	<h5>
		General FAQ
	</h5>

	<div class="flex flex-col lg:flex-row gap-x-4 gap-y-4">
		<div class="flex flex-col lg:flex-1 gap-y-4">
			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the Operational Dashboard?
					</span>
				</ng-template>
				<p>
					This is a dashboard displayed on the portal home page which displays a count of all Service Statuses of
					Devices discoverable on the network as well as all current Tasks and their respective statuses.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a Service (Customer)?
					</span>
				</ng-template>
				<p>
					A Service, also known as a Customer, is a Person (User) with both a Premise (Address/Lat Long) and a Product
					(Internet package) associated. We refer to the 3 Ps as a Service - Person, Premise and Package.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the Customer view?
					</span>
				</ng-template>
				<p>
					When a customer is selected, you get a full view of all information related to that customer such as Tasks
					(Work Orders), Addresses and Products (Services) associated to that customer. The customer view will highlight
					all information related to that customer, and allows for dynamic population of certain information depending
					on the service that is selected (if more than one exists for that customer).
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the Product view?
					</span>
				</ng-template>
				<p>
					A service is in essence a product that has been linked to a customer, but this is not a 1-1 mapping. A
					customer could have multiple services, both active and inactive. The approach would be to not view this in
					isolation either, but rather as an attribute of the customer. Products can be accessed and viewed from the
					customer view, with all products that are linked to that customer presented at the top of the screen. You can
					then navigate between products for a customer and the data presented will dynamically update to match the
					service you are viewing.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a Task (Work Order)?
					</span>
				</ng-template>
				<p>
					A Task, also known as a Work Order, is a set of steps organized in a flow to perform actions against a
					Service. Examples of Tasks are Installation, Change of Service, Provisioning.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is Task view?
					</span>
				</ng-template>
				<p>
					Work to be done on this product (installation, changes, cancellations, etc.) are just Tasks that need to be
					completed and are associated to a specific end user and will never live in isolation. Each Task is associated
					to a user and the appropriate service that the Task needs to be completed against.
				</p>
				<p>
					Tasks will be accessed in the following ways:
				</p>
				<ul>
					<li>
						Customer view: When a customer is selected, you can choose which product you want to view (if the customer
						has more than one product). If that product has an active Task, this will be presented upfront. Or click on
						the Tasks tab on the left navigation pane to view all historical Tasks.
					</li>
					<li>
						Tasks: There will be a Tasks tab on the main navigation pane where the portal user can view all Tasks that
						are assigned to them.
					</li>
				</ul>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						How do I manage Tasks (Work Orders)?
					</span>
				</ng-template>
				<p>
					Manage Status Allocation is where Task (Work Order) Statuses are managed in the portal. Allocation refers to
					assigning to both company and people within that company.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What are Unassigned Devices?
					</span>
				</ng-template>
				<p>
					Unassigned Devices refers to the total of devices that are on the network with no active provisioning/service
					associated.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is an alert?
					</span>
				</ng-template>
				<p>
					An alert is a notification to draw attention to a problem or an issue. Within the portal, alerts will make the
					blocks on the Device status appear orange (amber).
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is an alarm?
					</span>
				</ng-template>
				<p>
					An alarm is a notification to draw attention to a very serious problem or an issue. Alarms require urgent
					attention and will make the Device Status blocks red.
				</p>
			</kendo-expansionpanel>
		</div>

		<div class="flex flex-col lg:flex-1 gap-y-4">
			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a VLAN?
					</span>
				</ng-template>
				<p>
					A virtual local area network (VLAN) is a virtualized connection that connects multiple devices and network
					nodes from different LANs into one logical network. The VLAN consists of an STAG/CTAG: STAG (Outer VLAN) and a
					CTAG (Inner VLAN). A VLAN has a range which is set and supplied by the ISPs.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a CTAG/ CVLAN tag?
					</span>
				</ng-template>
				<p>
					A CTAG/ CVLAN tag is the Customer VLAN Tag and it identifies the VLAN to which the frame belongs when it is
					transmitted in the customer network.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a STAG/ SVLAN tag?
					</span>
				</ng-template>
				<p>
					A STAG/ SVLAN tag is the Service VLAN Tag and it identifies the VLAN to which the QinQ frame belongs when it
					is transmitted in the service provider network. The service provider allocates the SVLAN tag to the customer.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the Cancellation Effective Date?
					</span>
				</ng-template>
				<p>
					The Cancellation Effective Date is when the cancellation of a service will be actioned by the system.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the Effective Date?
					</span>
				</ng-template>
				<p>
					The Effective Date is when a service will be “On Network” or provisioned.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the ISP Effective Date?
					</span>
				</ng-template>
				<p>
					The ISP Effective Date is when the service is billable to the customer (end-user).
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is a FSAN?
					</span>
				</ng-template>
				<p>
					A FSAN or Full-Service Access Network is an optical access network that provides high-speed internet.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is the FSAN Number?
					</span>
				</ng-template>
				<p>
					The FSAN Number or Full-Service Access Network Number is the Serial Number on your ONT (on occasion the MAC
					number is used instead). During Installation the FSAN Number is assigned to the Premise where the ONT is
					installed. It is used to Identify the fiber line to the Premise. The FSAN number can be found on the back or
					bottom of the ONT.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is NMS?
					</span>
				</ng-template>
				<p>
					NMS stands for Network Management System and this identifies, configures, monitors, updates and troubleshoots
					network devices.
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is an OLT?
					</span>
				</ng-template>
				<p>
					An OLT or Optical Line Terminal is the device used to connect to a network. It is related to the Point of
					Presence (POP).
				</p>
			</kendo-expansionpanel>

			<kendo-expansionpanel [expanded]="true">
				<ng-template kendoExpansionPanelTitleDirective>
					<span class="faq-title">
						What is an ONT?
					</span>
				</ng-template>
				<p>
					An ONT or Optical Network Terminal is the device which is installed within a Premise which connects the home
					to the wider network.
				</p>
			</kendo-expansionpanel>
		</div>
	</div>
</div>