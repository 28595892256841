import { IPagedResponse } from '@aex/ngx-toolbox';
import { PremiseApi } from '@aex/shared/apis';
import {
	AuthType,
	IClientPremise,
	IPredefinedPremise,
	IPredefinedPremiseSearch, IPremiseHistory,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PremiseService {

	public customerId: string;

	constructor(
		private readonly http: HttpClient,
	) { }

	public searchPredefinedPremises(query: IPredefinedPremiseSearch): Observable<IPagedResponse<IPredefinedPremise>> {
		return this.http.get<IPagedResponse<IPredefinedPremise>>(PremiseApi.predefined, {
			params: new ParamMetaData({ handleError: 'premise', authToken: AuthType.PROXIED })
					.set('q', query.full_text),
		});
	}

	public getPredefinedPremise(predefinedPremiseID: string): Observable<IPredefinedPremise> {
		return this.http.get<IPredefinedPremise>(PremiseApi.predefinedPremise(predefinedPremiseID), {
			params: new ParamMetaData({ handleError: 'premise', authToken: AuthType.PROXIED }),
		});
	}

	public getClientPremises(predefinedPremiseID: string): Observable<IPagedResponse<IClientPremise>> {
		return this.http.get<IPagedResponse<IClientPremise>>(PremiseApi.clientPremises(predefinedPremiseID), {
			params: new ParamMetaData({ handleError: 'premise', authToken: AuthType.PROXIED  }),
		});
	}

	public getPremiseHistory(premiseId: string): Observable<IPagedResponse<IPremiseHistory>>
	{
		return this.http.get<IPagedResponse<IPremiseHistory>>(
			`${PremiseApi.premiseHistory()}?premise=${premiseId}&count=all`, {
			params: new ParamMetaData({ handleError: 'premise', authToken: AuthType.PROXIED  }),
		});
	}

}
