import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import { SearchService, WorkOrderService } from "@aex/shared/apis";
import {
  IColumnDto,
  IFullWorkOrder,
  IWorkOrderSearchParams,
} from "../interfaces/search-service";
import {
  Company,
	getAreaStatusClass,
	getServiceStatusClass,
	getWorkOrderStatusClass,
	IPagedData,
	IWorkOrder,
	IWorkOrderHistory,
	IWorkOrderPagedData,
  IWorkOrderStatus,
  IWorkOrderTransition,
  IFullWorkOrderData,
} from "..";
import { IPagedResponse } from "@aex/ngx-toolbox";

export const WORK_ORDERS_COLUMNS : string[]= [
  'Work Order: Reference',
  'Customer: Full Name',
  'Work Order: Status',
  'Isp Product: Name',
  'Premise: Full Address',
  'Area: Area Status',
  'Service: Status',
];

@Injectable({
  providedIn: 'root',
})
export class WorkOrderDataService {

  constructor(
    private readonly toastr: ToastrService,
    // private readonly tableCacheService: TableCacheService,
    private readonly searchService: SearchService,
    private readonly workOrderService: WorkOrderService,
  ){}

  public getWorkOrderServices(request: Partial<IWorkOrderSearchParams>): Observable<IPagedData<IFullWorkOrderData>> {
    return this.searchService.getWorkOrders(request).pipe(
      map((response) => {
        const responseResult = response.body;
        const extendedFullWorkOrderData: IFullWorkOrderData[] = responseResult.items.map((fullWorkOrder: IFullWorkOrder) => ({
          ...fullWorkOrder,
          area_status_class: getAreaStatusClass(fullWorkOrder.area.area_status),
          service_status_class: getServiceStatusClass(fullWorkOrder.service.status),
          work_order_status_class: getWorkOrderStatusClass(fullWorkOrder.workorder.status),
        }));

        const pagedData: IPagedData<IFullWorkOrderData> = {
          page: responseResult.page,
          count: responseResult.count,
          total: responseResult.total,
          items: extendedFullWorkOrderData,
        };
        return pagedData;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching work-orders: ${error.message || error}`);
        return of(null);
      }),
    );
  }

  public getGridWorkOrderColumns(): Observable<IColumnDto[]> {
    return this.searchService.getWorkOrderColumns().pipe(
      map((response) => {
        const columns: IColumnDto[] = response.body;
        columns.sort((a, b) => a.heading.localeCompare(b.heading));
        return columns;
      }),
      catchError((error) => {
        this.toastr.error(`Error fetching customer columns: ${error.message || error}`);
        return of([]); // Return an empty array as a fallback
      }),
    );
  }

  public workOrdersExportCsv(request: Partial<IWorkOrderSearchParams>): Observable<HttpResponse<Blob>> {
    return this.searchService.workOrdersExportCsv(request);
  }

  public getServiceWorkOrders(serviceId: string): Observable<IWorkOrder[]> {
    return this.workOrderService.getServiceWorkOrders(serviceId).pipe(
      map(workOrders => workOrders.items),
      catchError(error => {
        this.toastr.error('Error Service Work Orders:', error.message);
        return of([]);
      }),
    );
  }

	public getWorkOrderHistory(workOrderId: string): Observable<IWorkOrderPagedData<IWorkOrderHistory>>{
		return this.workOrderService.getWorkOrderHistory(workOrderId);
	}

	public getWorkOrderTypeStatuses(workOrderTypeId: number): Observable<IPagedResponse<IWorkOrderStatus>>{
		return this.workOrderService.getWorkOrderTypeStatuses(workOrderTypeId);
	}

  public getTransitionsForStatus(statusId: string): Observable<IPagedResponse<IWorkOrderTransition>>{
    return this.workOrderService.getTransitionsForStatus(statusId);
  }

  public getWorkOrderStatus(workOrderStatusId: string): Observable<IWorkOrderStatus>{
    return this.workOrderService.getWorkOrderStatus(workOrderStatusId);
  }

  public getWorkOrderCompanies(workOrderId: string, statusId: string): Observable<Company[]> {
    return this.workOrderService.getWorkOrderCompanies(workOrderId, statusId);
  }

	public getWorkOrderCompanyPeoples(workOrderId: string, statusId: string, companyId: string): Observable<Company[]> {
    return this.workOrderService.getWorkOrderCompanyPeoples(workOrderId, statusId, companyId);
  }

  public updateWorkOrder(workOrderId: string, request: Partial<IWorkOrder>): Observable<IWorkOrder> {
    return this.workOrderService.updateWorkOrder(workOrderId, request).pipe(
      catchError(error => {
        this.toastr.error('Error updating work order:', error.message);
        return of(null);
      }),
    );
  }

  // Fetch the Orders with the WO OrderStatus List
	public fetchServiceWorkOrdersWithStatuses(
    serviceId: string,                        // Service ID to fetch the Work Orders
    workOrderClosed: boolean,                 // Filter the Work Orders based on the Closed Status. True=Closed Work Orders, False=Open Work Orders
  ): Observable<{ workOrderFiltered: IWorkOrder[], workOrderStatusList: IWorkOrderStatus[] }> {
    // Fetch all work orders for the service
    if (!serviceId)
      return of({ workOrderFiltered: [], workOrderStatusList: [] });

		return this.getServiceWorkOrders(serviceId).pipe(
			switchMap((workOrders: IWorkOrder[]) => {
				if (workOrders.length === 0)
					return of({ workOrderFiltered: [], workOrderStatusList: [] });

				// Extract unique valid WO status IDs
				const uniqueStatusIds = Array.from(new Set(
					workOrders.map(wo => wo.status_id).filter((id): id is string => !!id),
				));

				if (uniqueStatusIds.length === 0)
					return of({ workOrderFiltered: workOrders, workOrderStatusList: [] });

        const workOrderStatusCache = new Map<string, IWorkOrderStatus>()

        // Fetch all statuses in parallel
				return this.fetchWorkOrderStatuses(workOrderStatusCache, uniqueStatusIds).pipe(
					map(statusList => {
						const statusMap = new Map(statusList.map(status => [status.id, status]));

						return {
							workOrderFiltered: workOrders.filter(wo =>
								statusMap.get(wo.status_id)?.closed === workOrderClosed,
							),
							workOrderStatusList: statusList,
						};
					}),
				);
			}),
		);
	}

	// Fetch Workorder Status with Caching
	private fetchWorkOrderStatuses(
    workOrderStatusCache: Map<string, IWorkOrderStatus>,
    statusIds: string[],
  ): Observable<IWorkOrderStatus[]> {
		if (statusIds.length === 0)
			return of([]);

		// Fetch all statuses in parallel
		return forkJoin(statusIds.map(statusId =>
			this.getCachedOrFetchStatus(workOrderStatusCache, statusId).pipe(
				catchError(error => {
					console.warn(`Failed to fetch status ${statusId}:`, error);
					const errorResult = of({
						id: statusId,
						closed: false,
						name: 'Unknown',
						description: 'Status unavailable',
					} as unknown);
					return errorResult as Observable<IWorkOrderStatus>;
				}),
			),
		));
	}

  // Fetch Workorder Status with Caching
	private getCachedOrFetchStatus(
    workOrderStatusCache: Map<string, IWorkOrderStatus>,
    statusId: string,
  ): Observable<IWorkOrderStatus> {
		if (workOrderStatusCache.has(statusId))
			return of(workOrderStatusCache.get(statusId)!);

		return this.getWorkOrderStatus(statusId).pipe(
			tap(status => workOrderStatusCache.set(statusId, status)),
		);
	}

}
