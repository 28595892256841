import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import {
	CustomerService,
	DeviceStatusService, WorkOrderService,
} from "@aex/shared/apis";
import {
	IDeviceHistory,
	IDeviceSpeedTest,
	IDeviceSpeedTestHistory,
	IFullDeviceNetworkConfig,
	IFullDeviceNetworkStatus,
	ILayer3Details,
	INetworkDataUsage,
	INmsPagedData,
	INSLookup,
	INSLookupHistory,
	IPing,
	IPingHistory, IPremises, IPremisesUpdate,
	IRadiusHistory,
	IRadiusStatus,
	IRawWifiDevices,
	IService,
	IRebootOntResponseDto,
	IServiceConfiguration,
	ITraceRoute,
	ITraceRouteHistory, IWorkOrder, WorkOrderType,
} from "..";
import { HttpResponse } from "@angular/common/http";
import { IPagedResponse } from "@aex/ngx-toolbox";
import { bytesToSize, DateHelper, secondsToTime } from "../helpers";

import MOCK_DEVICE_STATUS_DATA from './device-status-mock-data.json';

interface IDeviceStatusData {
  MockDeviceConfig: IFullDeviceNetworkConfig;
  MockDeviceStatus: IFullDeviceNetworkStatus;
  MockDeviceRadiusStatus: IRadiusStatus;
  MockDeviceHistory: IDeviceHistory[];
  MockNetworkDataUsage: INetworkDataUsage[];
  MockRadiusHistory: IRadiusHistory;
  MockPingTest: IPing;
  MockPingTestHistory: IPingHistory[];
  MockTraceRoute: ITraceRoute;
  MockTraceRouteHistory: ITraceRouteHistory[];
  MockConnectedDevices : IRawWifiDevices;
  MockDeviceSpeedTest: IDeviceSpeedTest;
  MockDeviceSpeedTestHistory: INmsPagedData<IDeviceSpeedTestHistory>;
  MockNsLookupHistory: INSLookupHistory[];
  MockWifiInformation: ILayer3Details;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceStatusDataService {

  private readonly USE_MOCK = true;
	private readonly USE_TEST_MOCK = true;
  private readonly MOCK_DATA = MOCK_DEVICE_STATUS_DATA as IDeviceStatusData;

  constructor(
    private readonly toastr: ToastrService,
    private readonly customerService: CustomerService,
    private readonly deviceStatusService: DeviceStatusService,
    private readonly workOrderService: WorkOrderService,
  ){}

  public getDeviceConfigFromSerial(
    serialNumber: string,
    serviceId: string,
    refreshStatus : boolean = false,
    port: string = '1',
  ): Observable<IFullDeviceNetworkConfig | null> {
	  if (this.USE_TEST_MOCK)
      return of(this.getMockDeviceConfig(serialNumber, serviceId, port));

    return this.deviceStatusService.getFullServiceDeviceConfigFromSerial(serialNumber, port, serviceId, refreshStatus).pipe(
      map((response: HttpResponse<IFullDeviceNetworkConfig>) => response.body ?? null),
      catchError((error) => {
        // TODO:portal2 replace with toastr when unblocked - done for getters
        // this.toastr.error('Error fetching device configuration:', error.message);
        console.error('Error fetching device configuration:', error.message);
        return of(null);
      }),
    );
  }

  public getDeviceStatusFromSerial(serialNumber: string, serviceId: string, refreshStatus: boolean = false): Observable<IFullDeviceNetworkStatus> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockDeviceStatus(serialNumber, serviceId));

  	return this.deviceStatusService.getFullServiceDeviceStatusFromSerial(serialNumber, serviceId, refreshStatus).pipe(
  		catchError((error) => {
  			// this.toastr.error('Error fetching device status', error.message);
        console.error('Error fetching device status', error.message);
  			return of(null);
  		}),
  	);
  }

  public getDeviceRadiusStatus(serialNumber: string, refreshStatus: boolean = false): Observable<IRadiusStatus> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockDeviceRadiusStatus(serialNumber));

    const portNo = '1';
    return this.deviceStatusService.getServiceRadiusStatusFromSerial(serialNumber, portNo, refreshStatus).pipe(
      catchError((error) => {
        console.error('Error fetching device radius status', error.message);
        return of(null);
      }),
    );
  }

  public getServiceConfigurationList(): Observable<IPagedResponse<IServiceConfiguration>> {
    return this.deviceStatusService.getServiceConfigurationList().pipe(
      catchError((error) => {
        console.error('Error fetching Service Configuration', error.message);
        return of(null);
      }),
    );
  }

  public saveIspReference(serviceId: string, ispReference: string): Observable<IService> {
    const request : Partial<IService> = {
      isp_reference: ispReference,
    }
    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Isp Reference', error.message);
        console.error('Error saving Isp Reference', error.message);
        return of(null);
      }),
    );
  }

  public saveServiceConfiguration(serviceId: string, serviceConfigurationId: number): Observable<IService> {
    // Request Dto
    const request : Partial<IService> = {
      service_configuration_id: serviceConfigurationId,
    }

    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Service Configuration', error.message);
        console.error('Error saving Service Configuration', error.message);
        return of(null);
      }),
    );
  }

  public saveServiceWifiDetails(serviceId: string, wifiUsername: string, wifiUserPassword: string): Observable<IService> {
    const request : Partial<IService> = {
      wifi_username: wifiUsername,
      wifi_password: wifiUserPassword,
    };

    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Service Wifi Details', error.message)
        console.error('Error saving Service Wifi Details', error.message);
        return of(null);
      }),
    );
  }

  public saveServicePPPoEDetails(serviceId: string, pppoeUsername: string, pppoeUserPassword: string): Observable<IService> {
    const request : Partial<IService> = {
      pppoe_username: pppoeUsername,
      pppoe_password: pppoeUserPassword,
    };

    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Service PPPoE Details', error.message)
        console.error('Error saving PPPoE Details', error.message);
        return of(null);
      }),
    );
  }

  public saveServiceIpv6Details(
    serviceId: string,
    publicIpv4Address: boolean,
    publicIpv6Address: boolean,
    ipv6Address: string,
  ): Observable<IService> {

    // Build Request
    const request : Partial<IService> = {
      ip_v4_public : publicIpv4Address,
      ip_v6_public : publicIpv6Address,
      pppoe_v6_address : ipv6Address,
    };

    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Service Ipv6 Details', error.message)
        console.error('Error saving Ipv6 Details', error.message);
        return of(null);
      }),
    );
  }

  public saveServicePortNumberDetails(
    serviceId: string,
    portNumber: string,
  ): Observable<IService> {

    // Build Request
    const request : Partial<IService> = {
      port_number: portNumber,
    };

    return this.deviceStatusService.updateService(serviceId, request).pipe(
      catchError((error) => {
        this.toastr.error('Error saving Service Port Number Details', error.message)
        console.error('Error saving Ipv6 Details', error.message);
        return of(null);
      }),
    );
  }

  // Wifi Information
  public getWifiInformation(serialNumber: string, refreshStatus: boolean = false): Observable<ILayer3Details> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockWifiInformation(serialNumber));

    return this.deviceStatusService.getWifiInformation(serialNumber, refreshStatus).pipe(
      catchError((error) => {
        console.error('Error fetching Wifi Information', error.message);
        return of(null);
      }),
    );
  }

  public getDeviceHistory(serialNumber: string, page: number = 1, count: number = 10): Observable<IPagedResponse<IDeviceHistory>> {
    if (!this.USE_TEST_MOCK)
      return of(this.getMockDeviceHistory(serialNumber, page, count));

    return this.deviceStatusService.getDeviceHistory(serialNumber, page, count).pipe(
      catchError((error) => {
        // this.toastr.error('Error fetching device history', error.message);
        console.error('Error fetching device history', error.message);
        return of({ items: [], page: page, count: 0, total: 0 });
      }),
    );
  }

  public getRadiusHistory(ppoeUserName: string): Observable<IRadiusHistory> {
    if (this.USE_MOCK) return of(this.getMockRadiusHistory(ppoeUserName));

    return this.deviceStatusService.getRadiusHistory(ppoeUserName).pipe(
      map((response: IRadiusHistory) => {
        if (!response) {
          // this.toastr.error('Error fetching radius history');
          console.error('Error fetching radius history');
          return response;
        }

        // Convert bytes to human-readable sizes
        response.accounting_active = response.accounting_active.map((item) => ({
          ...item,
          humanSessionTime: secondsToTime(item.acct_session_time),
          humanInputSize: bytesToSize(item.acct_input_octets + item.acct_ipv6_input_octets),
          humanOutputSize: bytesToSize(item.acct_output_octets + item.acct_ipv6_output_octets),
        }));

        response.accounting = response.accounting.map((item) => ({
          ...item,
          humanSessionTime: secondsToTime(item.acct_session_time),
          humanInputSize: bytesToSize(item.acct_input_octets + item.acct_ipv6_input_octets),
          humanOutputSize: bytesToSize(item.acct_output_octets + item.acct_ipv6_output_octets),
        }));

        return response;
      }),
      catchError((error) => {
        this.toastr.error('Error fetching radius history', error.message);
        return of(null);
      }),
    );
  }

  public getNetworkDataUsage(serialNumber: string, fromDate: Date, toDate: Date): Observable<INetworkDataUsage[]> {
    if (this.USE_MOCK)
      return of(this.getMockNetworkDataUsage(serialNumber, fromDate, toDate));

    const from = DateHelper.formatDate(fromDate, "/");
    const to = DateHelper.formatDate(toDate, "/");
    return this.deviceStatusService.getNetworkDataUsage(serialNumber, from, to).pipe(
      catchError((error) => {
        // this.toastr.error('Error fetching Network Data Usage', error.message);
        console.error('Error fetching Network Data Usage', error.message);
        return of([]);
      }),
    );
  }

  public getConnectedDevices(serialNumber: string): Observable<IRawWifiDevices> {
    if (this.USE_MOCK)
      return of(this.getMockConnectedDevices(serialNumber));

    return this.deviceStatusService.getConnectedWifiDevices(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error fetching Wifi Devices', error.message);
        console.error('Error fetching Wifi Devices', error.message);
        return of(null);
      }),
    );
  }

  public deviceSpeedTest(serialNumber: string): Observable<IDeviceSpeedTest> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockDeviceSpeedTest(serialNumber));

    return this.deviceStatusService.deviceSpeedTest(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error Device Speed Test', error.message);
        console.error('Error Device Speed Test', error.message);
        return of(null);
      }),
    );
  }

  public deviceSpeedTestHistory(serialNumber: string): Observable<INmsPagedData<IDeviceSpeedTestHistory>> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockDeviceSpeedTestHistory(serialNumber));

    return this.deviceStatusService.deviceSpeedTestHistory(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error Device Speed Test History', error.message);
        console.error('Error Device Speed Test History', error.message);
        return of(null);
      }),
    );
  }

  // public deviceDiagnosticTest(serialNumber: string): Observable<IDeviceDiagnostic> {
  // }

  public pingTest(serialNumber: string): Observable<IPing> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockPingTest(serialNumber));

    return this.deviceStatusService.pingTest(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error Ping', error.message);
        console.error('Error Ping', error.message);
        return of(null);
      }),
    );
  }

  public pingTestHistory(serialNumber: string): Observable<IPingHistory[]> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockPingTestHistory(serialNumber));

    return this.deviceStatusService.pingTestHistory(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error Ping History', error.message);
        console.error('Error Ping History', error.message);
        return of(null);
      }),
    );
  }

  public traceRoute(serialNumber: string): Observable<ITraceRoute> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockTraceRoute(serialNumber));

    return this.deviceStatusService.traceRoute(serialNumber).pipe(
      catchError((error) => {
        this.toastr.error('Error Trace Route', error.message);
        return of(null);
      }),
    );
  }

  public traceRouteHistory(serialNumber: string): Observable<ITraceRouteHistory[]> {
    if (this.USE_TEST_MOCK)
      return of(this.getMockTraceRouteHistory(serialNumber));

    return this.deviceStatusService.traceRouteHistory(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error Trace Route', error.message);
        console.error('Error Trace Route', error.message);
        return of(null);
      }),
    );
  }

  public nsLookup(serialNumber: string): Observable<INSLookup> {
    return this.deviceStatusService.ngLookup(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error NS Lookup', error.message);
        console.error('Error NS Lookup', error.message);
        return of(null);
      }),
    );
  }

  public nsLookupHistory(serialNumber: string): Observable<INSLookupHistory[]> {
    if (this.USE_MOCK)
      return of(this.getMockNsLookupHistory(serialNumber));

    return this.deviceStatusService.ngLookupHistory(serialNumber).pipe(
      catchError((error) => {
        // this.toastr.error('Error NS Lookup History', error.message);
        console.error('Error NS Lookup History', error.message);
        return of(null);
      }),
    );
  }


	public rebootOnt(serialNumber: string): Observable<IRebootOntResponseDto> {
		return this.deviceStatusService.rebootOntDevice(serialNumber).pipe(
			catchError((error) => {
				// this.toastr.error('Error NS Lookup History', error.message);
				console.error('Error NS Lookup History', error.message);
				return of(null);
			}),
		);
	}

	public changeOnt(premiseId: string, mewSerialNumber: string, comments: string): Observable<IPremises> {
		const premiseUpdateDto: Partial<IPremisesUpdate> = {
			premise: {
				asset_reference: mewSerialNumber,
				provision: true,
			},
			comments: comments,
		};
		return this.customerService.updateCustomerPremiseDevice(premiseId, premiseUpdateDto).pipe(
			catchError((error) => {
				// this.toastr.error('Error NS Lookup History', error.message);
				console.error('Error changing ONT', error.message);
				return of(null);
			}),
		);
	}

	public clearOnt(premiseId: string, comments: string): Observable<IPremises> {
		const premiseUpdateDto: Partial<IPremisesUpdate> = {
			premise: {
				asset_reference: '',
				provision: true,
			},
			comments: comments,
		};
		return this.customerService.updateCustomerPremiseDevice(premiseId, premiseUpdateDto).pipe(
			catchError((error) => {
				// this.toastr.error('Error NS Lookup History', error.message);
				console.error('Error clearing ONT', error.message);
				return of(null);
			}),
		);
	}

	public reconfigureDevice(serviceId: string, comments: string): Observable<void> {
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.Provisioning,
			comments: comments,
		};
		return this.workOrderService.createServiceWorkOrder(serviceId, workOrder);
	}

	public deprovisionDevice(serviceId: string, comments: string): Observable<void> {
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.DeProvisioning,
			comments: comments,
		};
		return this.workOrderService.createServiceWorkOrder(serviceId, workOrder);
	}

  //#region --- Mock data ---

  private getMockDeviceConfig(_serialNumber: string, _serviceId: string, _port: string): IFullDeviceNetworkConfig {
    return this.MOCK_DATA.MockDeviceConfig;
  }

  private getMockDeviceStatus(serialNumber: string, serviceId: string): IFullDeviceNetworkStatus {
    const data = this.MOCK_DATA.MockDeviceStatus;
    data.serial_number = serialNumber;
    data.pop = `OFUS-NC-INDIANTRAIL-OLT02 - ${serviceId}`;
    return data;
  }

  private getMockDeviceRadiusStatus(_serialNumber: string): IRadiusStatus {
    return this.MOCK_DATA.MockDeviceRadiusStatus;
  };

  private getMockDeviceHistory(_serialNumber: string, page: number, _count: number): IPagedResponse<IDeviceHistory> {
    const data = this.MOCK_DATA.MockDeviceHistory;
    return {
      items: data,
      page: page,
      count: 1,
      total: 1,
    };
  }

  private getMockNetworkDataUsage(_serialNumber: string, fromDate: Date, toDate: Date): INetworkDataUsage[] {
    const usageRecords = this.MOCK_DATA.MockNetworkDataUsage;

    // Range filter
    const from = DateHelper.formatDate(fromDate, "");
    const to = DateHelper.formatDate(toDate, "");
    return usageRecords.filter((record) =>
      record.Cal_Key_Workings >= from &&
      record.Cal_Key_Workings <= to,
    );
  }

  private getMockRadiusHistory(ppoeUserName: string): IRadiusHistory {
    const data = this.MOCK_DATA.MockRadiusHistory;
    data.username = ppoeUserName;
    return data;
  }

  private getMockPingTest(serialNumber: string): IPing {
    const data = this.MOCK_DATA.MockPingTest;
    data.device_reference = serialNumber;
    return data;
  }

  private getMockPingTestHistory(serialNumber: string): IPingHistory[] {
    const data = this.MOCK_DATA.MockPingTestHistory;
    data[0].device_reference = serialNumber;
    return data
  }

  private getMockTraceRoute(serialNumber: string): ITraceRoute {
    const data = this.MOCK_DATA.MockTraceRoute;
    data.device_reference = serialNumber;
    return data;
  }

  private getMockTraceRouteHistory(_serialNumber: string): ITraceRouteHistory[] {
    return this.MOCK_DATA.MockTraceRouteHistory;
  }

  private getMockConnectedDevices(_serialNumber: string) : IRawWifiDevices {
    return this.MOCK_DATA.MockConnectedDevices;
  }

  private getMockDeviceSpeedTest(serialNumber: string): IDeviceSpeedTest {
    const data = this.MOCK_DATA.MockDeviceSpeedTest;
    data.response.device_reference = serialNumber;
    return data;
  }

  public getMockDeviceSpeedTestHistory(serialNumber: string): INmsPagedData<IDeviceSpeedTestHistory> {
    const data = this.MOCK_DATA.MockDeviceSpeedTestHistory;
    data.results[0].response.device_reference = serialNumber;
    return data;
  }

  public getMockNsLookupHistory(serialNumber: string): INSLookupHistory[] {
    const data = this.MOCK_DATA.MockNsLookupHistory;
    data[0].device_reference = serialNumber;
    return data;
  }

  public getMockWifiInformation(serialNumber: string): ILayer3Details {
    const data = this.MOCK_DATA.MockWifiInformation;
    data.device_id = serialNumber;
    data.added = new Date('2024-08-21T15:16:44.000Z');;
    return data;
  }

  //#endregion --- Mock data ---

}
