<div class="page-container">
	<div class="flex flex-row justify-between items-center">
		<h5>
			Manage Profile
		</h5>

		<div class="action-button-container">
			<kendo-button *ngIf="isViewMode" (click)="editClick()" themeColor="base">
				Edit
			</kendo-button>
			<ng-container *ngIf="!isViewMode">
				<kendo-button (click)="cancelClick()" themeColor="base" fillMode="outline">
					Cancel
				</kendo-button>
				<kendo-button (click)="saveClick()" themeColor="primary"
					[disabled]="manageUserProfileForm.touched && !manageUserProfileForm.valid">
					Save
				</kendo-button>
			</ng-container>
		</div>
	</div>

	<!-- TODO:portal2 - what is api for this? -->
	<!-- TODO:portal2 - where is the 285px width coming from?? -->
	<kendo-card *ngIf="!isEmailConfirmed" class="unconfirmed-email-card">
		<kendo-card-body>
			<p>
				Your email address is not confirmed.
			</p>
			<ul>
				<li>
					Please check your junk mail. If you have not received it in 10 minutes, <a (click)="resendEmail()">Resend the email</a>
				</li>
			</ul>
		</kendo-card-body>
	</kendo-card>

	<form #manageUserProfileForm="ngForm" class="form-container">
		<div class="form-row">
			<!-- First Name -->
			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="First Name (Required)">
					<kendo-textbox
						#userFirstNameControl="ngModel"
						name="userFirstName"
						[(ngModel)]="firstName"
						[readonly]="isViewMode"
						[required]="!isViewMode"
						[appNoWhitespace]="!isViewMode"
						placeholder="First Name">
					</kendo-textbox>
					<kendo-formerror *ngIf="!isViewMode && userFirstNameControl.touched && userFirstNameControl.errors?.['required']">
						First Name is required.
					</kendo-formerror>
					<kendo-formerror *ngIf="!isViewMode && userFirstNameControl.errors?.['whitespace']">
						First Name is invalid.
					</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>

			<!-- Last Name -->
			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="Last Name (Required)">
					<kendo-textbox
						#userLastNameControl="ngModel"
						name="userLastName"
						[(ngModel)]="lastName"
						[readonly]="isViewMode"
						[required]="!isViewMode"
						[appNoWhitespace]="!isViewMode"
						placeholder="Last Name">
					</kendo-textbox>
					<kendo-formerror *ngIf="!isViewMode && userLastNameControl.touched && userLastNameControl.errors?.['required']">
						Last Name is required.
					</kendo-formerror>
					<kendo-formerror *ngIf="!isViewMode && userLastNameControl.errors?.['whitespace']">
						Last Name is invalid.
					</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>
		</div>

		<div class="form-row">
			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="Email Address (Required)">
					<kendo-textbox
						#userEmailControl="ngModel"
						name="userEmail"
						[(ngModel)]="emailAddress"
						[readonly]="isViewMode"
						[maxlength]="emailMaxLength"
						[required]="!isViewMode"
						[appNoWhitespace]="!isViewMode"
						email
						placeholder="Email Address">
					</kendo-textbox>
					<kendo-formerror *ngIf="!isViewMode && userEmailControl.touched && userEmailControl.errors?.['required']">
						Email is required.
					</kendo-formerror>
					<kendo-formerror *ngIf="!isViewMode && (userEmailControl.errors?.['whitespace'] || userEmailControl.errors?.['email'])">
						Invalid email address.
					</kendo-formerror>
					<kendo-formerror *ngIf="!isEmailConfirmed">
						Not confirmed.
					</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>

			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="Contact Number (Required)">
					<kendo-textbox
						#userContactNumberControl="ngModel"
						name="contactNumber"
						[(ngModel)]="contactNumber"
						[readonly]="isViewMode"
						[required]="!isViewMode"
						[appNoWhitespace]="!isViewMode"
						[appPhoneNumberValidator]="!isViewMode"
						placeholder="Contact Number">
					</kendo-textbox>
					<kendo-formerror *ngIf="!isViewMode && userContactNumberControl.touched && userContactNumberControl.errors?.['required']">
						Mobile Number is required.
					</kendo-formerror>
					<kendo-formerror *ngIf="!isViewMode && (userContactNumberControl.errors?.['whitespace'] || userContactNumberControl.errors?.['invalidPhoneNumber'])">
						Invalid mobile number.
					</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>

		</div>

	</form>

	<div class="flex flex-row justify-between items-center">
		<h5>
			Manage Password
		</h5>

		<div class="action-button-container">
			<kendo-button *ngIf="isPasswordViewMode"  (click)="editPasswordClick()" themeColor="base">
				Edit
			</kendo-button>
			<ng-container *ngIf="!isPasswordViewMode">
				<kendo-button (click)="cancelPasswordClick()" themeColor="base" fillMode="outline">
					Cancel
				</kendo-button>
				<kendo-button (click)="savePasswordClick()" themeColor="primary"
					[disabled]="!managePasswordForm.valid">
					Save
				</kendo-button>
			</ng-container>
		</div>
	</div>

	<form #managePasswordForm="ngForm" class="form-container" [mustMatch]="['newPassword', 'confirmPassword']">
		<div class="form-row">
			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="New Password">
					<kendo-textbox
						#newPasswordControl="ngModel"
						[(ngModel)]="newPassword"
						[disabled]="isPasswordViewMode"
						[required]="!isViewMode"
						appPasswordValidator
						name="newPassword"
						placeholder="New Password">
					</kendo-textbox>
					<kendo-formerror *ngIf="newPasswordControl.errors?.minlength">Password must be at least 8 characters</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordControl.errors?.lowercase">At least one lower case character</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordControl.errors?.uppercase">At least one upper case character</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordControl.errors?.specialChar">At least one special character</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>

			<kendo-formfield class="flex-1">
				<kendo-floatinglabel class="w-full" text="Confirm Password">
					<kendo-textbox
						#confirmPasswordControl="ngModel"
						[(ngModel)]="confirmPassword"
						[disabled]="isPasswordViewMode"
						[required]="!isViewMode"
						name="confirmPassword"
						placeholder="Confirm Password">
					</kendo-textbox>
					<kendo-formerror *ngIf="confirmPasswordControl.errors?.mustMatch">Passwords must match</kendo-formerror>
				</kendo-floatinglabel>
			</kendo-formfield>
		</div>
	</form>
</div>
