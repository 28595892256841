import {Injectable} from "@angular/core";
import {
	ICustomerService,
	IPaymentHistory,
	IPremiseHistory,
	IServiceHistory,
	IWorkOrder,
	IWorkOrderHistory,
	IWorkOrderPagedData,
	ServiceHistoryData,
	ServiceHistoryTypes,
} from "@aex/shared/common-lib";
import {forkJoin, Observable, of} from "rxjs";
import {PremiseService, PurchaseOrderService, ServiceService, WorkOrderService} from "@aex/shared/apis";
import {map, switchMap} from "rxjs/operators";
import {IPagedResponse} from "@aex/ngx-toolbox";
import _ from 'lodash';
import moment from "moment";
import {IService} from "../interfaces/search-service";

@Injectable({
	providedIn: 'root',
})
export class ServiceHistoryDataService {
	constructor(
		private readonly premiseService: PremiseService,
		private readonly purchaseOrderService: PurchaseOrderService,
		private readonly serviceService: ServiceService,
		private readonly workOrderService: WorkOrderService,
	) {}

	public getServicePremiseHistoryData(premiseId: string) : Observable<ServiceHistoryData[]> {
		return this.premiseService.getPremiseHistory(premiseId).pipe(
			switchMap(
				(data: IPagedResponse<IPremiseHistory>) : Observable<ServiceHistoryData[]> => {
					// Convert premises to service history data
					const serviceHistoryDataList: ServiceHistoryData[] = [];
					for (const premiseHistory of data.items)
					{
						const serviceHistoryData: ServiceHistoryData = new ServiceHistoryData();
						serviceHistoryData.id = premiseHistory?.premise_id;
						serviceHistoryData.history_id = premiseHistory?.id.toString();

						serviceHistoryData.date = new Date(premiseHistory.created_at);
						serviceHistoryData.modified_by = premiseHistory.modified_name;
						serviceHistoryData.history_type = ServiceHistoryTypes.Device;
						serviceHistoryData.detail = `${premiseHistory.message}`;
						if (premiseHistory.asset_reference !== '' && premiseHistory.asset_reference !== null)
							serviceHistoryData.detail += ` Asset Reference: {premiseHistory.asset_reference}`;
						serviceHistoryDataList.push(serviceHistoryData);
					}
					return of(serviceHistoryDataList);
				},
			),
		);
	}

	public getServicePaymentHistoryData(serviceId: string) : Observable<ServiceHistoryData[]> {
		return this.purchaseOrderService.getPaymentHistory(serviceId).pipe(
			switchMap(
				(paymentHistories: IPaymentHistory[]) : Observable<ServiceHistoryData[]> => {
					// Convert premises to service history data
					const serviceHistoryDataList: ServiceHistoryData[] = [];
					for (const paymentHistory of paymentHistories)
					{
						const serviceHistoryData: ServiceHistoryData = new ServiceHistoryData();
						serviceHistoryData.id = paymentHistory?.service_id;
						serviceHistoryData.history_id = paymentHistory?.id;

						serviceHistoryData.date = new Date(paymentHistory.payment_date);
						serviceHistoryData.modified_by = 'Purchase Order System';
						serviceHistoryData.history_type = ServiceHistoryTypes.Billing;
						serviceHistoryData.detail = `${paymentHistory.amount} -  ${paymentHistory.status}`;
						serviceHistoryDataList.push(serviceHistoryData);
					}
					return of(serviceHistoryDataList);
				},
			),
		);
	}

	public getServiceServiceHistoryData(service: Partial<IService>) : Observable<ServiceHistoryData[]> {
		return this.serviceService.getServiceHistory(service.id).pipe(
			switchMap(
				(data: IPagedResponse<IServiceHistory>) : Observable<ServiceHistoryData[]> => {
					// Convert premises to service history data
					const serviceHistoryDataList: ServiceHistoryData[] = [];
					for (const serviceHistory of data.items)
					{
						const serviceHistoryData: ServiceHistoryData = new ServiceHistoryData();
						serviceHistoryData.id = service.id;
						serviceHistoryData.history_id = service.id;
						serviceHistoryData.reference = service.isp_reference;

						serviceHistoryData.date = new Date(serviceHistory.created_at);
						serviceHistoryData.modified_by = serviceHistory.transition_user;
						serviceHistoryData.history_type = ServiceHistoryTypes.Service;
						serviceHistoryData.detail = serviceHistory.message;
						serviceHistoryDataList.push(serviceHistoryData);
					}
					return of(serviceHistoryDataList);
				},
			),
		);
	}

	public getServiceWorkOrderHistoryList(serviceId: string): Observable<{
		workOrder: IWorkOrder,
		history: IWorkOrderPagedData<IWorkOrderHistory>
	}[]> {
		return this.workOrderService.getServiceWorkOrders(serviceId).pipe(
			switchMap((workOrderList: IPagedResponse<IWorkOrder>) => {
				// Create an array of observables for fetching history
				const historyRequests: Observable<IWorkOrderPagedData<IWorkOrderHistory>>[] = workOrderList.items.map(workOrder =>
					this.workOrderService.getWorkOrderHistory(workOrder.id),
				);

				// Execute all history requests in parallel
				return forkJoin(historyRequests).pipe(
					map((workOrderHistoryPageDataList: IWorkOrderPagedData<IWorkOrderHistory>[]) => {
						// Combine work orders with their history
						return workOrderList.items.map((workOrder, index) => ({
							workOrder,
							history: workOrderHistoryPageDataList[index],
						}));
					}),
				);
			}),
		);
	}

	public getServiceWorkOrderHistoryData(serviceId: string) : Observable<ServiceHistoryData[]> {
		return this.getServiceWorkOrderHistoryList(serviceId).pipe(
			switchMap(
				(workOrderHistoryList: { workOrder: IWorkOrder, history: IWorkOrderPagedData<IWorkOrderHistory> }[]) =>
				{
					const serviceHistoryDataList: ServiceHistoryData[] = [];
					for (const workOrderHistoryTuple of workOrderHistoryList) {
						let currentOnHold = false;
						const workOrderList = workOrderHistoryTuple.history.list;

						const workOrder = workOrderHistoryTuple.workOrder;
						if (!_.isEmpty(workOrder))
							currentOnHold = workOrder.on_hold;
						else
							console.log('Error in logic - work-order not in list');

						for (const workOrderHistory of workOrderList) {
							const serviceHistoryData: ServiceHistoryData = new ServiceHistoryData();
							serviceHistoryData.id = workOrderHistory?.work_order;
							serviceHistoryData.history_id = workOrderHistory?.id;

							serviceHistoryData.date = new Date(workOrderHistory.modified_date);
							serviceHistoryData.modified_by = workOrderHistory.modified_by;
							serviceHistoryData.reference = workOrder.reference;
							serviceHistoryData.history_type = ServiceHistoryTypes.Task;
							serviceHistoryData.detail = this.buildServiceWorkOrderHistoryDetail(workOrderHistory, currentOnHold);
							serviceHistoryDataList.push(serviceHistoryData);
							currentOnHold = workOrderHistory.on_hold;
						}
					}
					return of(serviceHistoryDataList);
				},
			),
		);
	}

	public getServiceHistoryData(service: ICustomerService) : Observable<ServiceHistoryData[]> {
		// Get premise history
		return forkJoin(
			[
				this.getServicePaymentHistoryData(service.service.id),
				this.getServicePremiseHistoryData(service.premise.id),
				this.getServiceServiceHistoryData(service.service),
				this.getServiceWorkOrderHistoryData(service.service.id),
			],
		).pipe(
			switchMap(
				(responses: ServiceHistoryData[][]) => {
					let serviceHistoryDataArray : ServiceHistoryData[] =[];
					for (const serviceHistoryDataResponse of responses)
						serviceHistoryDataArray = serviceHistoryDataArray.concat(serviceHistoryDataResponse);

					return of(serviceHistoryDataArray);
				},
			),
		);
	}

	private convertComments(comments: string): string{
		return comments ? comments.replace(/\n/g, '<br/>'): '';
	}

	private buildServiceWorkOrderHistoryDetail(item: IWorkOrderHistory, currentOnHold: boolean): string {
		let contentProvided = false;
		const comments: string[] = ['<div class="list-group-item">'];

		const addHistoryEntry = (header: string, value: string) => {
			contentProvided = true;
			comments.push(
				`<div class="list-group-item-text-header"><strong>${header}</strong></div>`,
				`<div class="list-group-item-text-value">${value}</div>`,
			);
		};

		if (item.previous_status)
			comments.push(`<div class="list-group-item-heading"><h5>${item.previous_status}</h5></div>`);

		comments.push('<div class="list-group-item-text">');

		const changeMap = [
			{ condition: item.on_hold && currentOnHold !== item.on_hold, header: 'Hold Status Changed', value: item.on_hold ? 'Placed on Hold' : 'Resumed' },
			{ condition: item.status_changed, header: 'Status Changed', value: `${item.previous_status} to <strong>${item.new_status}</strong>` },
			{ condition: item.company_changed, header: 'Assigned to Company Changed', value: `${item.previous_company} to ${item.new_company}` },
			{ condition: item.person_changed, header: 'Assigned to Person Changed', value: `${item.previous_person} to <strong>${item.new_person}</strong>` },
			{ condition: item.installer_changed, header: 'Installer Changed', value: `${item.previous_installer} to <strong>${item.new_installer}</strong>` },
			{ condition: item.schedule_date_set, header: 'Installation Scheduled', value: moment(item.schedule_date).format('LLL') },
			{ condition: item.comments, header: 'Comments', value: this.convertComments(item.comments)},
		];

		changeMap.forEach(({ condition, header, value }) => {
			if (condition)
				addHistoryEntry(header, value);
		});

		if (!contentProvided)
			comments.push('<div class="list-group-item-text-header"><strong>No changes made</strong></div>');

		comments.push('</div></div>');
		return comments.join('');
	}

}
