export class DateHelper {

	// Format Date to string to display in the format 'YYYY-MM-DD' with the prvided separator
	// Example: formatDate(new Date(), '-') => '2024-12-31'
	public static formatDate(value: Date, separator: string = '-'): string {
		const date = new Date(value);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}${separator}${month}${separator}${day}`;
	}
}

// Convert seconds (number) to time format hh:mm:ss as a string
export function secondsToTime(seconds : number) : string {
	const hours = Math.floor(seconds / (60 * 60));
	const minutes = Math.floor((seconds % (60 * 60)) / 60);
	const remainingSeconds = seconds % 60;
	return `${hours}h ${minutes}m ${remainingSeconds}s`;
}

// Convert a Date string to Date object
export function convertStringToDate(dateString: string | null | undefined): Date | null {
	// Return null for empty string, null, or undefined
  if (!dateString)
    return null;

  const date = new Date(dateString);

  return isNaN(date.getTime()) ? null : date; // Return null if invalid date
}

// Convert Time hh:mm:ss to Date when we have the time portion only for the Kendo TimePicker
export function convertTimeStringToDate(timeString: string | null | undefined): Date | null {
  if (!timeString) {
		const date = timeString ? new Date(timeString) : new Date();
  	date.setHours(0, 0, 0, 0);
		return date;
	}

	// Split time string into parts
  const timeParts = timeString.split(":").map(Number);

	// Check if time is in correct format
  if (timeParts.length !== 3 || timeParts.some(isNaN))
    return null;

	// Create a new date object and set the time using the current date
  const [hours, minutes, seconds] = timeParts;
  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);
  return date;
}

// Format Date to Time hh:mm:ss as a string
export function formatTime(date: Date): string {
  return date.toLocaleTimeString('en-GB', { hour12: false });
}

// Helper function to calculate difference in days
export function getDaysDifference(fromDate: Date, toDate: Date) : number {
	const hoursPerDay = 24;
	return Math.ceil((toDate.getTime() - fromDate.getTime()) / (1000 * (60 * 60) * hoursPerDay));
}

// Convert bytes (number) to display in human readable format
export function bytesToSize(bytes : number) : string {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'EB', 'ZB', 'YB'];
	const kilobyteMultiplier = 1024;
	if (bytes === 0)
		return '0 Byte';
	const i = Math.floor(Math.log(bytes) / Math.log(kilobyteMultiplier));
	return `${parseFloat((bytes / Math.pow(kilobyteMultiplier, i)).toFixed(2))} ${sizes[i]}`;
}

//----------------------------------------------
// Convert amount to string with currency format
//----------------------------------------------
// locale: 'en-US' is used to specify the locale for formatting (e.g., US English)
//
// style: 'decimal', 'currency', 'percent', 'unit'
// 					Example of each style:
// 					const number = 1234.56;
// 					Decimal
// 					console.log(new Intl.NumberFormat('en-US', { style: 'decimal' }).format(number));
// 					Output: "1,234.56"
// 					Currency (USD)
// 					console.log(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number));
// 					Output: "$1,234.56"
// 					Percentage
// 					console.log(new Intl.NumberFormat('en-US', { style: 'percent' }).format(0.75));
// 					Output: "75%"
// 					Unit (Kilometers)
// 					console.log(new Intl.NumberFormat('en-US', { style: 'unit', unit: 'kilometer' }).format(number));
// 					Output: "1,234.56 km"
// currency: 'USD' is used to specify the currency for formatting (e.g., US Dollar)
//          eg USD, EUR, GBP, ZAR, etc.
// 					Note: The currency symbol will be based on the locale and currency provided.
//
// Note: locale: string = navigator.language is used to get the user's default locale from the browser settings
export function convertAmountAsInternationalString(
	amount: number | null | undefined,
	locale: string = 'en-US',
	currency: string = 'USD',
): string | null {
  if (!amount)
		return null;

  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
}

export function convertDateAsInternationalString(
  date: Date | null | undefined,
  locale: string = 'en-US',
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' },
): string | null {
  if (!date)
		return null;

  return new Intl.DateTimeFormat(locale, options).format(date);
}
