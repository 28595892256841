<div class="portal-container flex flex-col">
	<!-- TODO:portal2 vaughn - include if header should scroll out of view -->
	<!-- [ngClass]="{'header-in-view': !isHeaderHidden, 'header-out-view': isHeaderHidden}" -->
	<app-portal-container-header class="portal-container-header-animation">
	</app-portal-container-header>

	<!-- TODO:portal2 vaughn - include if header should scroll out of view -->
	<!-- [ngClass]="{ 'header-hidden': isHeaderHidden, 'header-visible': !isHeaderHidden }" -->
	<kendo-drawer-container class="portal-container-navigation-drawer">
		<kendo-drawer #navDrawer
		              (select)="onSelect($event)"
		              [autoCollapse]="false"
		              [expanded]="drawerExpanded"
		              [isItemExpanded]="isItemExpanded"
		              [items]="navMenuItems"
		              [miniWidth]="drawerMiniWidth"
		              [mini]="true"
		              [width]="drawerWidth"
		              mode="push">

			<ng-template kendoDrawerHeaderTemplate>
				<div [ngClass]="{'justify-end': navDrawer.expanded, 'justify-start': !navDrawer.expanded}"
				     class="navigation-menu-toggle flex flex-row items-center">
					<button (click)="navDrawer.toggle()" [svgIcon]="navDrawer.expanded ? chevronLeftIcon : chevronRightIcon" fillMode="flat"
					        kendoButton>
					</button>
				</div>
			</ng-template>

			<ng-template kendoDrawerItemTemplate let-item>
				<div [position]="'top'" [title]="!navDrawer.expanded ? item.text : ''" class="flex flex-row justify-start items-center hierarchical-menu-item" kendoTooltip>
					<kendo-svg-icon [icon]="item.svgIcon"></kendo-svg-icon>
					<span *ngIf="navDrawer.expanded" class="flex flex-1">{{ item.text }}</span>
					<kendo-svg-icon *ngIf="!item.navMenuRoute" [icon]="!item.selected ? chevronDownIcon : chevronUpIcon"></kendo-svg-icon>
				</div>
			</ng-template>
		</kendo-drawer>

		<!-- TODO:portal2 vaughn - include if header should scroll out of view -->
		<!-- (scroll)="onScroll($event)" -->
		<kendo-drawer-content class="portal-container-content">
			<ngx-ui-loader [loaderId]="'inner-loader'"></ngx-ui-loader>
			<div class="flex-1 portal-drawer-container-content">
				<router-outlet></router-outlet>
			</div>
		</kendo-drawer-content>
	</kendo-drawer-container>
</div>

<div kendoDialogContainer></div>
