import { DialogManagerService, GridState, GridStateService, LoadGridStateRequest } from '@aex/shared/common-lib';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { eyeIcon, lockIcon, saveIcon, trashIcon, unlockIcon } from '@progress/kendo-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { GridStateViewService } from './grid-state-view.service';

@Component({
	selector: 'app-grid-state-view',
	templateUrl: './grid-state-view.component.html',
	styleUrls: [
		'./grid-state-view.component.scss',
	],
})
export class GridStateViewComponent {
	// Icons
	public readonly svgLockIcon = lockIcon;
	public readonly svgSaveIcon = saveIcon;
	public readonly svgTrashIcon = trashIcon;
	public readonly svgUnlockIcon = unlockIcon;
	public readonly svgViewIcon = eyeIcon;
	@Output() public readonly loadGridStateEvent: EventEmitter<LoadGridStateRequest> = new EventEmitter<LoadGridStateRequest>();
	@Output() public readonly saveGridStateEvent: EventEmitter<string> = new EventEmitter<string>();
	// Properties
	public gridViewList: string[] = [];															  // List of grid views

	constructor(
		private readonly toastr: ToastrService,
		private readonly dialogManagerService: DialogManagerService,
		private readonly gridStateService: GridStateService,
		private readonly gridStateViewService: GridStateViewService,
	) {
		this.gridStateViewService.$loadGridViews.subscribe(
			() => {
				this.loadGridViews();
			},
		);
	}

	// Inputs
	private _gridViewName: string = '';	  								  // Name of the grid associated with grid view

	public get gridViewName(): string {
		return this._gridViewName;
	}

	@Input()
	public set gridViewName(value: string) {   		// Name of the grid view
		this._gridViewName = value;
		this.gridViewList = this.gridStateService.getAllStateNames(this.gridViewName);
		this.loadDefaultGridView(false);
	}

	@Input()
	public set saveGridState(gridState: GridState) {
		if (!gridState || !this.selectedGridView || (gridState.columns.length === 0))
			return;

		// Set the grid state
		this.gridStateService.saveState(this.gridViewName, this.selectedGridView, gridState);
		const loadGridStateRequest: LoadGridStateRequest = {
			gridViewStateName: this.selectedGridView,
			gridState: gridState,
			reloadData: false,
			clearData: false,
		};
		this.loadGridStateEvent.emit(loadGridStateRequest);

		// Reload the grid views which will include the new/updated grid view
		this.loadGridViews();
	}

	private _selectedGridView: string = '';														// Selected grid view

	public get selectedGridView(): string 														// Selected grid view
	{
		return this._selectedGridView;
	}

	public set selectedGridView(value: string)	// Selected grid view
	{
		this._selectedGridView = value;

		// Reset default grid value
		if (!value) {
			this.gridStateService.setDefaultGridViewName(this.gridViewName, '');
			const loadGridStateRequest: LoadGridStateRequest = {
				gridViewStateName: '',
				gridState: null,
				reloadData: false,
				clearData: true,
			};
			this.loadGridStateEvent.emit(loadGridStateRequest);
		}

	}

	public get hideLoadGridView(): boolean {
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		return !gridState;																							// Use falsy value to hide the button
	}

	public get hideSaveGridView(): boolean {
		if (!this.selectedGridView)
			return true;
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		return gridState ? gridState.lockState: false; 								// Use truthy value to hide the button
	}

	public get hideLockGridView(): boolean {
		if (!this.selectedGridView)
			return true;
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		return !gridState || gridState.lockState;												// Use truthy value to hide the button
	}

	public get hideUnlockGridView(): boolean {
		if (!this.selectedGridView)
			return true;
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		return gridState ? !gridState.lockState: true;										// Use falsy value to hide the button
	}

	public get hideDeleteGridView(): boolean {
		if (!this.selectedGridView)
			return true;
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		return gridState ? gridState.lockState: true;										// Use truthy value to hide the button
	}

	public onGridViewLoad(loadGridViewData: boolean = true): void {
		if (!this.selectedGridView)
			return;
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		if (!gridState) {
			this.toastr.error(`Grid View not found ${this.selectedGridView}`, 'Grid View Load');
			return;
		}

		// Emit event to update the Grid according to this selected State
		const loadGridStateRequest: LoadGridStateRequest = {
			gridViewStateName: this.selectedGridView,
			gridState: gridState,
			reloadData: loadGridViewData,
			clearData: false,
		};
		this.loadGridStateEvent.emit(loadGridStateRequest);

		// The last loaded grid view is the default view
		this.gridStateService.setDefaultGridViewName(this.gridViewName, this.selectedGridView);
	}

	public onGridViewUpsert(): void {
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);

		if (!this.selectedGridView)
			return;

		const confirmMessage = (gridState)
			? `Are you sure you want to UPDATE Grid View ${this.selectedGridView}?`
			: `Are you sure you want to SAVE as a NEW Grid View ${this.selectedGridView}?`;

		this.dialogManagerService.openYesNoDialog(
			'Please Confirm',
			confirmMessage,
		).subscribe(
			(confirmUpsert: boolean) => {
				if (!confirmUpsert)
					// Cancel the operation
					return;

				// Emit event to get Grid State
				this.saveGridStateEvent.emit(this.selectedGridView);
			},
		);
	}

	public onGridViewLockState(lockState: boolean): void {
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		if (!gridState) {
			this.toastr.error(`Grid View not found ${this.selectedGridView} for locking`, 'Grid View Lock Management');
			return;
		}
		gridState.lockState = lockState;
		this.gridStateService.saveState(this.gridViewName, this.selectedGridView, gridState);

		const loadGridStateRequest: LoadGridStateRequest = {
			gridViewStateName: this.selectedGridView,
			gridState: gridState,
			reloadData: false,
			clearData: false,
		};
		this.loadGridStateEvent.emit(loadGridStateRequest);
	}

	public onGridViewDelete(): void {
		const gridState: GridState = this.gridStateService.loadState(this.gridViewName, this.selectedGridView);
		if (!gridState) {
			this.toastr.error(`Grid View not found ${this.selectedGridView} to be deleted`, 'Grid View Delete');
			return;
		}
		const confirmMessage = `Are you sure you want to delete Grid View ${this.selectedGridView}?`;
		this.dialogManagerService.openYesNoDialog(
			'Please Confirm',
			confirmMessage,
		).subscribe(
			(confirmDelete: boolean) => {
				if (!confirmDelete)
					// Cancel the operation
					return;

				// Upsert view
				this.gridStateService.deleteState(this.gridViewName, this.selectedGridView);
				this.loadGridViews();
				this.selectedGridView = '';
				const loadGridStateRequest: LoadGridStateRequest = {
					gridViewStateName: this.selectedGridView,
					gridState: null,
					reloadData: false,
					clearData: false,
				};
				this.loadGridStateEvent.emit(loadGridStateRequest);
				this.loadDefaultGridView();
			},
		);
	}

	private loadGridViews(): void {
		this.gridViewList = this.gridStateService.getAllStateNames(this.gridViewName);
		this.loadDefaultGridView();
	}

	private loadDefaultGridView(loadGridViewData: boolean = true): void {
		if (this.gridViewList.length === 0)
			return;

		// Get default view
		const defaultGridView = this.gridStateService.getDefaultGridViewName(this.gridViewName);
		if (!defaultGridView)
			return;

		// Does the list of views contains the default view?
		const defaultViewExists = this.gridViewList.includes(defaultGridView);
		if (defaultViewExists)
			this.selectedGridView = defaultGridView;
		else
			this.selectedGridView = '';

		this.onGridViewLoad(loadGridViewData);

	}
}
