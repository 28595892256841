import { CommonLibModule } from '@aex/shared/common-lib';
import { CommonModule, NgOptimizedImageModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KendoModule } from '../../kendo.module';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { BasicDataGridComponent } from './basic-data-grid/basic-data-grid.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { GridViewButtonComponent } from './grid-view-button/grid-view-button.component';
import { ListingFiltersComponent } from './listing-filters/listing-filters.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { SaveViewDialogComponent } from './save-view-dialog/save-view-dialog.component';

@NgModule({
	declarations: [
		ListingFiltersComponent,
		DataGridComponent,
		GridViewButtonComponent,
		SaveViewDialogComponent,
		BasicDataGridComponent,
		NotificationMessageComponent,
		AlertBarComponent,
	],
	imports: [CommonModule, ...KendoModule, NgOptimizedImageModule, CommonLibModule],
	exports: [
		ListingFiltersComponent,
		DataGridComponent,
		GridViewButtonComponent,
		SaveViewDialogComponent,
		BasicDataGridComponent,
		NotificationMessageComponent,
		AlertBarComponent,
	],
})
export class SharedModule {}
