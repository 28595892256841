import { Exclude, Type } from "class-transformer";
import { WorkOrderCreatedDateRangeItem } from "./date-range-selector";
import { IAdvanceGridSearch } from "./kendo-grid";
import { IFullWorkOrder } from "./search-service";

export interface IFullWorkOrderData extends IFullWorkOrder{
  area_status_class: string;
  service_status_class: string;
  work_order_status_class: string;
}

export class WorkOrderAdvSearchFilter implements IAdvanceGridSearch
{
  public search_string : string = '';
  public work_order_reference: string = '';
  public work_order_type_id: string = '';
  public work_order_statuses: string[] = [];
  public installer_id: string = '';

  @Exclude()
  public get include_created_date_filter() : boolean {
    return this.workOrderDataRangeSelector.selected;
  };
  @Exclude()
  public get created_date_from() : Date {
    return this.workOrderDataRangeSelector.date_start ? this.workOrderDataRangeSelector.date_start_value : new Date();
  }
  @Exclude()
  public get created_date_to() : Date {
    return this.workOrderDataRangeSelector.date_end ? this.workOrderDataRangeSelector.date_end_value : new Date();
  }

  public parent_area_id : string = '';
  public area_id : string = '';

  @Type(() => WorkOrderCreatedDateRangeItem)
  public workOrderDataRangeSelector : WorkOrderCreatedDateRangeItem = new WorkOrderCreatedDateRangeItem();

  public clearFilters() : void {
    this.search_string = '';
    this.work_order_reference = '';
    this.work_order_type_id = null; // to clear the drop down - it needs to be null
    this.work_order_statuses = [];
    this.installer_id = '';
    this.workOrderDataRangeSelector.selected  = false;
    this.workOrderDataRangeSelector.date_start_value = new Date();
    this.workOrderDataRangeSelector.date_end_value = new Date();

    this.parent_area_id = '';
    this.area_id = '';
  }
}
