<kendo-combobox
	[(ngModel)]="selectedGridView"
	[allowCustom]="true"
	[data]="gridViewList">

	<kendo-combobox-messages
		clearTitle="Clear Selected Grid View"
	>
	</kendo-combobox-messages>

	<ng-template kendoPrefixTemplate>
		<kendo-svgicon [icon]="svgViewIcon" title="Manage Grid Views"></kendo-svgicon>
	</ng-template>

	<ng-template kendoSuffixTemplate>
		<button (click)="onGridViewLoad()" [hidden]="hideLoadGridView" [svgIcon]="svgViewIcon" kendoButton title="Load Grid View"></button>
		<button (click)="onGridViewUpsert()" [hidden]="hideSaveGridView" [svgIcon]="svgSaveIcon" kendoButton title="Save Grid View"></button>
		<button (click)="onGridViewLockState(true)" [hidden]="hideLockGridView" [svgIcon]="svgLockIcon" kendoButton title="Lock Grid View"></button>
		<button (click)="onGridViewLockState(false)" [hidden]="hideUnlockGridView" [svgIcon]="svgUnlockIcon" kendoButton title="Unlock Grid View"></button>
		<button (click)="onGridViewDelete()" [hidden]="hideDeleteGridView" [svgIcon]="svgTrashIcon" kendoButton title="Delete Grid View"></button>
	</ng-template>
</kendo-combobox>
