import { IconThemeColor, SVGIcon } from "@progress/kendo-angular-icons";
import {
  checkCircleIcon,
  warningCircleIcon,
  xCircleIcon,
} from "@progress/kendo-svg-icons";

export function networkMessageType(isError: boolean, isWarning: boolean) : number {
  if (isError)
    return 1;

  if (isWarning)
    return 2;

  return 3;
}

export enum NetworkStatusType {
  Success = 0,
  Warning = 1,
  Error = 2,
}

export type NetworkStatusMessage = { svgIcon: SVGIcon; themeColor: IconThemeColor; message: string };

const networkMessageMap: Record<NetworkStatusType, NetworkStatusMessage> = {
  [NetworkStatusType.Success]: { svgIcon: checkCircleIcon, themeColor: 'success', message: 'No Errors' },
  [NetworkStatusType.Warning]: { svgIcon: warningCircleIcon, themeColor: 'warning', message: 'Warning' },
  [NetworkStatusType.Error]: { svgIcon: xCircleIcon, themeColor: 'error', message: 'Error' },
};

export function getExpansionNetworkMessage(networkMessageType: NetworkStatusType): NetworkStatusMessage {
  return networkMessageMap[networkMessageType] || { svgIcon: xCircleIcon, themeColor: 'error', message: 'Unhandled Error' };
}

export function setNetworkStatusType(error: boolean, warning: boolean) : NetworkStatusType {
  if (error)
    return NetworkStatusType.Error;
  else if (warning)
    return NetworkStatusType.Warning;
  else
    return NetworkStatusType.Success;
}

export type ExpansionPanelStatus = { svgIcon: SVGIcon, themeColor: IconThemeColor, status: string };

export class NetworkStatusDetectionDto {
	public isNniMacMissing : boolean = false;
	public isPppoeStatusDown : boolean = false;
  public isSpeedFailure: boolean = false;            // upload_speed/download_speed
	public isPopStatusDown : boolean = false;
  public isGponDown: boolean = false;
  public isServiceDown: boolean = false;
  public isRadiusStatusNotConnected: boolean = false;

  public isRxStatusNotNormal: boolean = false;
  public isTxStatusNotNormal: boolean = false;
  public isSfpRxStatusNotNormal: boolean = false;
  public isSfpStatusNotNormal: boolean = false;

  public isNoConfiguration: boolean = false;
  public isDeviceNotConnected: boolean = false;
  public hasNoIpAddress: boolean = false;

	public isVlansFailure : boolean = false;            // Warning

  public hasAlarms: boolean = false;
  public hasAlerts: boolean = false;

  public alarms: string[] = [];
  public alerts: string[] = [];

  public get hasErrors(): boolean {
    return this.isNniMacMissing ||
      this.isPppoeStatusDown ||
      this.isPopStatusDown ||
      this.isGponDown ||
      this.isServiceDown ||
      this.isRadiusStatusNotConnected ||
      this.hasAlarms;
  }

  public get hasWarnings(): boolean {
    return this.hasAlerts ||
      this.isVlansFailure;
  }

  public get networkStatusType(): NetworkStatusType {
    return setNetworkStatusType(this.hasErrors, this.hasWarnings);
  }

}

// Determine if Task Actions is disabled based on the parameters provided.
export class TaskActionsDecisionTable {
  private readonly decisionMap = new Map<number, boolean>([
    [0b000, true],  // false-false-false
    [0b001, true],  // false-false-true
    [0b010, false], // false-true-false
    [0b011, false], // false-true-true
    [0b100, true],  // true-false-false
    [0b101, false], // true-false-true
    [0b110, false], // true-true-false
    [0b111, false], // true-true-true
  ]);

  public isTaskActionsDisabled(
    requirePayment: boolean, 
    userAllocated: boolean, 
    adminOverride: boolean,
  ): boolean {
    const key = (requirePayment ? 0b100 : 0) | (userAllocated ? 0b010 : 0) | (adminOverride ? 0b001 : 0);
    return this.decisionMap.get(key) ?? true;
  }
}

export class TaskActionFieldDecisionTable {
  private readonly decisionMap = new Map<number, boolean>([
    [0b000, true],  // false-false-false
    [0b001, false], // false-false-true
    [0b010, true],  // false-true-false
    [0b011, false], // false-true-true
    [0b100, true],  // true-false-false
    [0b101, true],  // true-false-true
    [0b110, false], // true-true-false
    [0b111, false], // true-true-true
  ]);

  public isTaskActionFieldDisabled(
    requirePayment: boolean, 
    adminAllowed: boolean, 
    fieldAllowed: boolean,
  ): boolean {
    const key = (requirePayment ? 4 : 0) | (adminAllowed ? 2 : 0) | (fieldAllowed ? 1 : 0);
    return this.decisionMap.get(key) ?? true;
  }
}
