import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  NgForm,
  NgModel,
} from '@angular/forms';
import {
  dateRangeOperatorAsText,
  DateRangeOperatorItem,
  DateRangeSelectorItem,
  TDateRangeOperator,
} from '@aex/shared/common-lib';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DateRangeSelectorComponent implements OnInit {

  @Input() public promoDateRangeItem : DateRangeSelectorItem;
  @Input() public namePrefix: string;
	@ViewChild('end_date_value', { static: false }) endDate!: NgModel;

  constructor() {
    // Transform enum to dropdown-friendly array
    this.dateRangeOperatorList = Object.keys(TDateRangeOperator)
      .filter((key) => isNaN(Number(key))) // Exclude numeric keys (for enums)
      .map(
        (key) => {
          const enumKey: TDateRangeOperator = TDateRangeOperator[key as keyof typeof TDateRangeOperator];
          const dateRangeOperatorItem: DateRangeOperatorItem = new DateRangeOperatorItem();
          dateRangeOperatorItem.id = enumKey;
          dateRangeOperatorItem.name = dateRangeOperatorAsText(enumKey);
          return dateRangeOperatorItem;
        },
      )
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
  }

  public ngOnInit(): void {
    this.setDefaultDateRangeOperator(this.promoDateRangeItem.date_range_operator_id);
  }

  public dateRangeOperatorList : DateRangeOperatorItem[] = [];

  public get startDateFloatingLabel(): string {
    return this.IsRangeDateOperator() ? 'From' : this.promoDateRangeItem.display_date_range_operator;
  }

  public dateRangeOperatorChanged(date_range_operator_id: number) : void {
    this.promoDateRangeItem.date_range_operator_id = date_range_operator_id;
    this.setDefaultDateRangeOperator(date_range_operator_id);
    if (this.endDateDisabled)
      this.promoDateRangeItem.date_end_value = new Date();
  }

  private setDefaultDateRangeOperator(date_range_operator_id: number) : void {
    const item = this.dateRangeOperatorList.find((item) => item.id === date_range_operator_id);
    if (!item)
      return;
    this.promoDateRangeItem.date_range_operator = date_range_operator_id;
    this.promoDateRangeItem.display_date_range_operator = item.name;
		if (this.promoDateRangeItem.date_end === null)
			this.promoDateRangeItem.date_end_value = new Date();
	}

  public selectedChange(selected: boolean): void {
    this.promoDateRangeItem.selected = selected;
    if (selected){
      this.promoDateRangeItem.date_start_value = new Date();
			if (this.IsRangeDateOperator())
        this.promoDateRangeItem.date_end_value = new Date();
    }
  }

  public get endDateDisabled() : boolean {
    return !this.IsRangeDateOperator();
  }

	public get isEndDateRequiredCheck() : boolean {
		return this.promoDateRangeItem.selected && this.isEndDateTouchedAndEnabled() && this.endDate.errors?.['required'];
	}

	private isEndDateTouchedAndEnabled(): boolean {
		if (!this.endDate)
			return false;
		return this.endDate.touched && !this.endDateDisabled;
	}

	public get isEndDateOnOrAfterCheck() : boolean {
		return this.isEndDateTouchedAndEnabled()
			&& this.promoDateRangeItem.selected &&
			(this.promoDateRangeItem.date_end_value < this.promoDateRangeItem.date_start_value);
	}

	private IsRangeDateOperator() : boolean {
    const operator = this.promoDateRangeItem.date_range_operator_id;
    return (
      (operator === TDateRangeOperator.Between) ||
      (operator === TDateRangeOperator.NotBetween)
    );
  }

}
