export function isValidEmail(email: string): boolean {
  const emailRegex =
    // eslint-disable-next-line max-len
    /^(?=[A-Za-z0-9][-_%+A-Za-z0-9.@]{5,253}$)(?:(?=[-_%+A-Za-z0-9.]{1,64}@)[A-Za-z0-9][-_%+A-Za-z0-9]*(\.[A-Za-z0-9][-_%+A-Za-z0-9]*)*@)(?:(?=[A-Za-z0-9-]{1,63}\.)[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*\.)+[A-Za-z]{2,63}$/;

  return emailRegex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  const pattern1 = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  const pattern2 = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  const maxPhoneNumberLength = 16;

  if (isAllSameDigits(phoneNumber) || (phoneNumber.length <= 7) || (phoneNumber.length >= maxPhoneNumberLength))
    return false;

  if (isSAMobileNumber(phoneNumber))
    return validateSAMobileNumber(phoneNumber);

  if (phoneNumber.startsWith('0') && (phoneNumber.length < 10))
    return false;

  let result = validateE164PhoneNumber(phoneNumber);

  // If the phone number is not in E.164 format, then it should be in the format of 123-456-7890 or 123.456.7890 or 123 456 7890 or 1234567890
  if (!result)
    result = pattern1.test(phoneNumber) || pattern2.test(phoneNumber);

  return result;
}

export function isSAMobileNumber(phoneNumber: string): boolean {
  const saStartingRegex = /^(0(6|7|8)|\+27(6|7|8))/;
  return saStartingRegex.test(phoneNumber);
}

export function validateSAMobileNumber(phoneNumber: string): boolean {
  const saStartingRegex = /^(0(6|7|8)\d{8}|\+27(6|7|8)\d{8})$/;
  return saStartingRegex.test(phoneNumber);
}

export function validateE164PhoneNumber(phoneNumber: string): boolean {
  const e164Regex = /^\+?[1-9]\d{1,14}$/;
  const result = e164Regex.test(phoneNumber);
  return result;
}

export function isAllSameDigits(phoneNumber: string): boolean {
  // Remove any non-digit characters (like spaces, dashes, etc.)
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the phone number consists of all the same digits
  return /^(\d)\1*$/.test(cleanedPhoneNumber);
}

export function booleanToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No';
}