import { Component, Input } from '@angular/core';
// import { BreadCrumbItem } from "@progress/kendo-angular-navigation";
import { homeIcon, SVGIcon } from '@progress/kendo-svg-icons';
// import { ActivatedRoute, Router } from "@angular/router";
//
// import { isNil } from "lodash";
import { RouteBreadcrumbItem } from '@aex/shared/common-lib';
import { Observable } from 'rxjs';
import { BreadcrumbService } from '@aex/shared/root-services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',

})
export class BreadcrumbComponent /* implements OnInit */ {

	public homeIcon: SVGIcon = homeIcon;

	public $breadcrumbs: Observable<RouteBreadcrumbItem[]> = this.breadcrumbService.$breadcrumbs;

	@Input() mayShowBreadcrumbs: boolean = true;


	constructor(
		private readonly breadcrumbService: BreadcrumbService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
	) {
	}

	public onItemClick($event: RouteBreadcrumbItem) {
		this.router.navigate(
			[$event.url],
			{
				relativeTo: this.route,
			},
		).then();
	}

	// ngOnInit(): void {
	//   this.router.events
	// 	  .pipe(filter(event => event instanceof NavigationEnd))
	// 	  .subscribe(() => this.historyList = this.createBreadcrumbs(this.activatedRoute.root));
	// }


	// private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: BreadCrumbItem[] = []): BreadCrumbItem[] {
	//
	// 	console.log(route);
	//
	// 	const children: ActivatedRoute[] = route.children;
	//
	// 	if (children.length === 0) {
	// 		return breadcrumbs;
	// 	}
	//
	// 	for (const child of children) {
	// 		const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
	// 		if (routeURL !== '') {
	// 			url += `/${routeURL}`;
	// 		}
	//
	// 		const label = child.snapshot.data["routeData"];
	//
	// 		if (!isNil(label)) {
	// 			const breadcrumbItem: BreadCrumbItem = {
	// 				text : label,
	// 			}
	//
	// 			// breadcrumbItem.url = label;
	//
	// 			breadcrumbs.push(breadcrumbItem);
	// 		}
	//
	// 		return this.createBreadcrumbs(child, url, breadcrumbs);
	// 	}
	// 	return [];
	// }

}
