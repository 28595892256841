import { appRoute } from '@aex/ngx-toolbox';
import { Routes } from '@angular/router';
import { ClassicPortalContainerComponent } from './classic-portal-container/classic-portal-container.component';

// objects used in EMBEDDED_PORTAL_ROUTES (objects used for title configuration)
// EMBEDDED_PORTAL_ROUTES used in routing module i.e. ng app routes
export const CLASSIC_PORTAL_ROUTES = {
	// Customer
	customerFullProfile: {
		title: '',
		route: appRoute('customer-full-profile'),
	},
	// PMO
	premiseManagement: {
		title: 'Premise Management',
		route: appRoute('premise-management'),
	},
	predefinedPremises: {
		title: 'Predefined Premises',
		route: appRoute('premises'),
	},
	clientPremises: {
		title: 'Client Premises',
		route: appRoute('client-premises'),
	},
	clientPremisesImport: {
		title: 'Import',
		route: appRoute('client-premises/import'),
	},
	areasAndComplexes: {
		title: 'Manage Areas and Complexes',
		route: appRoute('areas-and-complexes'),
	},
	areasCreate: {
		title: 'Create New Areas and Complexes',
		route: appRoute('areas-and-complexes/create'),
	},
	addresses: {
		title: 'Manage Addresses',
		route: appRoute('addresses'),
	},
	locations: {
		title: 'Manage Locations',
		route: appRoute('locations'),
	},
	// Network
	interconnects: {
		title: 'View Interconnects',
		route: appRoute('interconnects'),
	},
	manageInterconnects: {
		title: 'Manage Interconnects',
		route: appRoute('manageInterconnects'),
	},
	vlans: {
		title: 'VLANs',
		route: appRoute('vlans'),
	},
	// Monitoring
	ontMonitoring: {
		title: 'ONT Monitoring',
		route: appRoute('ont-monitoring'),
	},
	oltMonitoring: {
		title: 'OLT Monitoring',
		route: appRoute('olt-monitoring'),
	},
	// Products
	products: {
		title: 'Manage Products',
		route: appRoute('products'),
	},
	// Scheduler
	scheduler: {
		title: 'Scheduler',
		route: appRoute('scheduler'),
	},
	// Admin
	manageUsers: {
		title: 'Manage Users',
		route: appRoute('manageUsers'),
	},
	taskStatusAllocation: {
		title: 'Task Status Allocation',
		route: appRoute('task-status-allocation'),
	},
	billingSchemes: {
		title: 'Manage Billing Schemes',
		route: appRoute('billing-schemes'),
	},
	providerBilling: {
		title: 'Provider Billing',
		route: appRoute('provider-billing'),
	},
	businessRules: {
		title: 'Business Rules',
		route: appRoute('business-rules'),
	},
	// Services
	serviceChange: {
		title: 'Products',
		route: appRoute('service-change'),
	},
	serviceProviderChange: {
		title: 'Products',
		route: appRoute('service-provider-change'),
	},
};

// routes used in routing module
export const EMBEDDED_PORTAL_ROUTES: Routes = [
	// Customer
	{
		path: CLASSIC_PORTAL_ROUTES.customerFullProfile.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.customerFullProfile.title },
	},
	// PMO
	{
		path: CLASSIC_PORTAL_ROUTES.premiseManagement.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.premiseManagement.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.predefinedPremises.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.predefinedPremises.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.clientPremises.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.clientPremises.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.clientPremisesImport.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.clientPremisesImport.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.areasAndComplexes.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.areasAndComplexes.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.areasCreate.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.areasCreate.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.addresses.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.addresses.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.locations.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.locations.title },
	},
	// Network
	{
		path: CLASSIC_PORTAL_ROUTES.interconnects.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.interconnects.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.manageInterconnects.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.manageInterconnects.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.vlans.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.vlans.title },
	},
	// Monitoring
	{
		path: CLASSIC_PORTAL_ROUTES.ontMonitoring.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.ontMonitoring.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.oltMonitoring.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.oltMonitoring.title },
	},
	// Products
	{
		path: CLASSIC_PORTAL_ROUTES.products.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.products.title },
	},
	// Scheduler
	{
		path: CLASSIC_PORTAL_ROUTES.scheduler.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.scheduler.title },
	},
	// Admin
	{
		path: CLASSIC_PORTAL_ROUTES.manageUsers.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.manageUsers.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.taskStatusAllocation.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.taskStatusAllocation.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.billingSchemes.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.billingSchemes.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.providerBilling.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.providerBilling.title },
	},
	{
		path: CLASSIC_PORTAL_ROUTES.businessRules.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.businessRules.title },
	},
	// Services
	{
		path: CLASSIC_PORTAL_ROUTES.serviceChange.route.name,
		component: ClassicPortalContainerComponent,
		data: { title: CLASSIC_PORTAL_ROUTES.serviceChange.title },
	},
];
