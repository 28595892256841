import {GridColumnItem, REFERENCE_COLUMN_WIDTH} from "@aex/shared/common-lib";
import { DATE_TIME_COLUMN_WIDTH, NAME_COLUMN_WIDTH, SHORT_DESCRIPTION_COLUMN_WIDTH } from "./kendo-helper";

export enum ServiceHistoryTypes{
	All = 'All',
	Service = 'Service',
	Device = 'Device',
	Task = 'Task',
	Billing = 'Billing',
	Customer = 'Customer',
}

export function serviceHistoryGridColumns(): GridColumnItem[] {
	const gridColumns : GridColumnItem[] = [
		new GridColumnItem({ field: 'date', title: 'Date', data_type: 'dateTime', width:  DATE_TIME_COLUMN_WIDTH }),
		new GridColumnItem({ field: 'modified_by', title: 'Modified By', width: NAME_COLUMN_WIDTH }),
		new GridColumnItem({ field: 'reference', title: 'Reference', width: REFERENCE_COLUMN_WIDTH }),
		new GridColumnItem({ field: 'history_type', title: 'Type', width: SHORT_DESCRIPTION_COLUMN_WIDTH }),
		new GridColumnItem({ field: 'detail', title: 'Detail' , data_type: 'html', width: 500 }),
		new GridColumnItem({ field: 'id', title: 'id', hidden: true }),
		new GridColumnItem({ field: 'history_id', title: 'History Id', hidden: true }),
	];

	return [...gridColumns];
}

export function getAllServiceHistoryTypes() : ServiceHistoryTypes[] {
	return [
		ServiceHistoryTypes.All,
		ServiceHistoryTypes.Service,
		ServiceHistoryTypes.Device,
		ServiceHistoryTypes.Task,
		ServiceHistoryTypes.Billing,
	];
}

export function getCommentsFilesServiceHistoryTypes() : ServiceHistoryTypes[] {
	return [
		ServiceHistoryTypes.All,
		ServiceHistoryTypes.Task,
		// ServiceHistoryTypes.Customer,
	];
}
