<div class="page-container">
	<div [hidden]="!hideQuickTools">

		<div *ngIf="displayQuickActionContainer" class="form-row" kendoTooltip position="top">
			<!-- Grid View Management -->
			<div *ngIf="hasGridStateView" class="flex-1">
				<app-grid-state-view
					(loadGridStateEvent)="loadGridState($event)"
					(saveGridStateEvent)="saveGridState($event)"
					[gridViewName]="gridViewId"
					[saveGridState]="gridState"
				></app-grid-state-view>
			</div>

			<div *ngIf="hasQuickActions" class="flex flex-1 justify-end gap-2">
				<!-- Quick Actions Button -->
				<kendo-dropdownbutton
					(itemClick)="quickActionItemClick($event)"
					[data]="quickActionOptions"
					[disabled]="quickButtonDisabled"
					textField="description"
					themeColor="primary"
					title="Quick Actions to Duplicate, Delete or Bulk Apply">
					Quick Actions
				</kendo-dropdownbutton>
				<kendo-dropdownbutton
					(itemClick)="exportGridDataItemClick($event)"
					[data]="exportOptions"
					[svgIcon]="svgDownloadIcon"
					textField="description"
					title="Export grid data to Excel csv format ">
					Export
				</kendo-dropdownbutton>
			</div>

		</div>
	</div>

	<!-- Advance Filter Window -->
	<kendo-expansionpanel
		(collapse)="onAdvSearchCollapse()"
		(expand)="onAdvSearchExpand()"
		*ngIf="hasAdvancedSearch"
		[expanded]="isToggle"
	>
		<ng-template kendoExpansionPanelTitleDirective>
			<div class="expansion-header-title" style="color: blue">
				<kendo-svgicon
					[icon]="svgFilterIcon"
					[style.marginRight.px]="10"
				></kendo-svgicon>
				<span class="text-blue-300">Advanced Search</span>
			</div>
		</ng-template>
		<ng-content>

		</ng-content>
	</kendo-expansionpanel>

	<div>
		<!-- Grid -->
		<kendo-grid
			#dataGrid
			(columnReorder)="gridColumnReorderEvent($event)"
			(filterChange)="filterChange($event)"
			(pageChange)="onGridPageChange($event)"
			(selectionChange)="checkBoxSelectionChange()"
			(sortChange)="onGridSortChange($event)"
			[(selectedKeys)]="selectedItems"
			[data]="gridDataResult"
			[filter]="gridFilterValue"
			[filterable]="showXLStyleFilter"
			[kendoGridSelectBy]="selectGridKeyId"
			[navigable]="true"
			[pageSize]="pageSize"
			[pageable]="pageSettings"
			[reorderable]="true"
			[resizable]="true"
			[selectable]="selectableSettings"
			[skip]="skip"
			[sort]="sort"
			[sortable]="true">

			<!-- Grid Toolbar -->
			<ng-template *ngIf="hideQuickTools" kendoGridToolbarTemplate>
				<div class="flex flex-row justify-start items-center w-full gap-4" kendoTooltip position="top">

					<!-- Grid Column Selection-->
					<div class="flex-1 flex-row items-center justify-center">

						<kendo-multiselect
							(filterChange)="onFilteredGridColumnChange($event)"
							(valueChange)="onGridColumnSelectionChange()"
							[(ngModel)]="selectedColumns"
							[autoClose]="false"
							[checkboxes]="true"
							[clearButton]="true"
							[data]="filteredGridColumns"
							[filterable]="true"
							kendoMultiSelectSummaryTag
							style="width: 350px"
							title="Select Grid Columns to Display">
							<ng-template kendoSuffixTemplate>
								<button (click)="resetGridColumns()"
								        [svgIcon]="svgWindowRestoreIcon"
								        kendoButton
								        title="Reset Grid Columns"
								></button>
								<button (click)="selectALLGridColumns()"
								        [svgIcon]="svgSelectAllIcon"
								        kendoButton
								        title="Select ALL Grid Columns"
								></button>
							</ng-template>
						</kendo-multiselect>

					</div>

					<!-- Search Textbox -->
					<div class="form-row flex-1" style="background-color: blue">
						<kendo-textbox
							(keydown)="handleKeydown($event)"
							[(value)]="searchKeyword"
							class="flex-1"
							placeholder="Search Keyword">

							<!-- Prefix Template -->
							<ng-template kendoTextBoxPrefixTemplate>
								<kendo-svgicon [icon]="svgSearchIcon" title="Search for records containing the provided keyword"></kendo-svgicon>
							</ng-template>

							<!-- Suffix Template for Clear Button -->
							<ng-template *ngIf="searchKeyword" kendoTextBoxSuffixTemplate>
								<button (click)="onSearchKeywordChange()"
								        [svgIcon]="svgSearchIcon"
								        kendoButton
								        title="Search using Keyword">
								</button>
								<button (click)="clearSearch()"
								        [svgIcon]="svgXIcon"
								        kendoButton
								        title="Clear Keyword Search">
								</button>
							</ng-template>

						</kendo-textbox>
					</div>
				</div>

			</ng-template>

			<!-- Conditionally Render Checkbox Column -->
			<kendo-grid-checkbox-column *ngIf="showCheckboxColumn" [width]="checkboxColumnWidth">
				<ng-template kendoGridHeaderTemplate>
					<input
						(change)="toggleSelectAll($event)"
						[checked]="allSelected"
						kendoCheckBox
						type="checkbox"
					/>
				</ng-template>
			</kendo-grid-checkbox-column>

			<!-- Grid Columns -->
			<kendo-grid-column
				*ngFor="let columnItem of visibleColumns"
				[editable]="columnItem.editable"
				[field]="columnItem.comboField"
				[filter]="columnItem.filter"
				[filterable]="columnItem.filterable"
				[hidden]="columnItem.hidden"
				[minResizableWidth]="minResizableWidth"
				[title]="columnItem.title"
				[width]="columnItem.width">
				<!-- Custom templates based on type -->

				<ng-template kendoGridCellTemplate let-dataItem let-field="column.field">
					<ng-container *ngTemplateOutlet="gridCellTemplate || defaultGridCellTemplate; context:{data: dataItem, column: columnItem}">
					</ng-container>
				</ng-template>

			</kendo-grid-column>

			<!-- Custom Grid Commands: Report *** Until Report is defined! -->
			<!-- <kendo-grid-command-column title="Report" [width]="220">
				<ng-template kendoGridCellTemplate let-dataItem>
					<button kendoButton fillMode="outline" [svgIcon]="svgReportIcon" title="Promotion Report" (click)="promoDiscountReportClick(dataItem)">
						Report
					</button>
				</ng-template>

			</kendo-grid-command-column> -->

			<!-- Page Template -->
			<ng-template kendoPagerTemplate
			             let-currentPage="currentPage"
			             let-totalPages="totalPages">
				<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
				<kendo-pager-numeric-buttons
					[buttonCount]="buttonCount"
				></kendo-pager-numeric-buttons>
				<kendo-pager-next-buttons></kendo-pager-next-buttons>

				<!-- Center -->
				<kendo-pager-spacer></kendo-pager-spacer>
				<kendo-pager-page-sizes [pageSizes]="sizes" size="none"></kendo-pager-page-sizes>
				<kendo-pager-spacer></kendo-pager-spacer>

				<kendo-pager-info></kendo-pager-info>

			</ng-template>
		</kendo-grid>

	</div>

</div>


<ng-template #defaultGridCellTemplate let-columnItem="column" let-dataItem="data" let-field="column.field">
	<ng-container [ngSwitch]="columnItem.data_type">
		<ng-container *ngSwitchCase="'boolean'">
			<kendo-checkbox [checkedState]="dataItem[columnItem.field]"
			                class="items-center"
			>
			</kendo-checkbox>
		</ng-container>
		<ng-container *ngSwitchCase="'date'">
			<span>{{ dataItem[field] | date: getDateFormat('date') }}</span>
		</ng-container>
		<ng-container *ngSwitchCase="'dateTime'">
			<span>{{ dataItem[field] | date: getDateFormat('dateTime') }}</span>
		</ng-container>
		<ng-container *ngSwitchCase="'number'">
			<span>{{ dataItem[field] | number }}</span>
		</ng-container>
		<ng-container *ngSwitchCase="'string'">
			<span>{{ dataItem[field] }}</span>
		</ng-container>
		<ng-container *ngSwitchCase="'html'">
			<span [innerHTML]="dataItem[field]"></span>
		</ng-container>
		<ng-container *ngSwitchCase="'list'">
			<ul>
				<li *ngFor="let value of dataItem[field]">
					{{ value }}
				</li>
			</ul>
		</ng-container>
		<ng-container *ngSwitchCase="'hyperlink'">
			<a (click)="nameHyperlinkAction(field, dataItem)"
			   class="router-link">
				{{ dataItem[field] }}
			</a>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<span>{{ dataItem[field] }}</span>
		</ng-container>
	</ng-container>
</ng-template>
