import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import {
  PurchaseOrderService,
} from "@aex/shared/apis";
import {
  ConfigurationMappingType,
  IBillingScheme,
  IMediumAccountResponse,
  IPaymentHistory,
  IPaymentMedium,
  IPlanCollectionDayChangeResponse,
  IPurchasePlan,
  IServiceStateAndAction,
} from "..";

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderDataService {

  constructor(
    private readonly toastr: ToastrService,
    private readonly purchaseOrderService: PurchaseOrderService,
  ){}

  public getServicePaymentDetailsAndActions(serviceId: string): Observable<IServiceStateAndAction> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.getServicePaymentDetailsAndActions(serviceIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Service Payment Details And Actions ${serviceId}:`, error.message);
        return of(null);        // Return null as a fallback
      }),
    );
  }

  public getPlanByServiceId(serviceId: string): Observable<IPurchasePlan> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.getPlanByService(serviceIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Plan By Service ${serviceId}:`, error.message);
        return of(null);        // Return null as a fallback
      }),
    );
  }

  public getPaymentHistoryByServiceId(serviceId: string): Observable<IPaymentHistory[]> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.getPaymentHistory(serviceIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Payment History ${serviceId}:`, error.message);
        return of([] as IPaymentHistory[]);        // Return empty array as a fallback
      }),
    );
  }

  public getActivePaymentMediumByService(serviceId: string): Observable<IPaymentMedium> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.getActivePaymentMediumForService(serviceIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Active Payment Medium for service ${serviceId}:`, error.message);
        return of(null);
      }),
    );
  }

  public updateDebitDay(serviceId: string, debitDay: number): Observable<IPlanCollectionDayChangeResponse> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.updateDebitDay(serviceIdLower, debitDay).pipe(
      catchError((error) => {
        this.toastr.error(`Error updating debit day for service ${serviceId} day=${debitDay}:`, error.message);
        return of(null);
      }),
    );
  }

  public getBillingSchemeByType(configurationMappingType: ConfigurationMappingType, productId: string): Observable<IBillingScheme> {
    const productIdLower = productId.toLowerCase();
    return this.purchaseOrderService.getBillingSchemeByType(configurationMappingType, productIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Billing Scheme ${configurationMappingType} for product ${productId}:`, error.message);
        return of(null);
      }),
    );
  }

  public getPaymentMediums(customerId: string): Observable<IMediumAccountResponse[]> {
    const customerIdLower = customerId.toLowerCase();
    return this.purchaseOrderService.getPaymentMediums(customerIdLower).pipe(
      catchError((error) => {
        this.toastr.error(`Error fetching Payment Mediums for customer ${customerId}:`, error.message);
        return of([] as IMediumAccountResponse[]);
      }),
    );
  }

	public getActivePaymentMediumForService(serviceId: string): Observable<IPaymentMedium> {
    const serviceIdLower = serviceId.toLowerCase();
    return this.purchaseOrderService.getActivePaymentMediumForService(serviceIdLower);
  }

}
