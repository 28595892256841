import { IPagedResponse } from '@aex/ngx-toolbox';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { MenuItem } from '@progress/kendo-angular-menu';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { IArea } from '../types';
import { NetworkStates } from '../enums';

export interface ICarouselResponsiveOptions {
	breakpoint: string;
	numVisible: number;
	numScroll: number;
}

export interface IStatus {
	status: string;
	count: number;
	is_closed?: boolean;
	status_order?: number;
}

export interface IStatusCount {
	id: string;
	name: string;
	count: number;
	isp_statuses?: IStatus[];
	statuses?: IStatus[];
}

export interface IServices {
	status_counts: IStatusCount[];
	summary: IStatus[];
}

export interface IWorkOrderStatusCount {
	type_id: string;
	type: string;
	count: number;
	statuses?: IStatus[];
}

export interface IWorkOrders {
	work_order_type_counts: IWorkOrderStatusCount[];
	summary?: IStatus[];
}

export interface IAreaType {
	enabled: boolean;
	has_parent: boolean;
	has_units: boolean;
	id: number;
	kml_mandatory?: boolean;
	name: string;
	parent_type?: string;
	parent_type_id?: number;
	type?: string;
}

export interface IAreaDropDown {
	name: string;
	data: unknown;
	id: number;
	placeholder: string;
	event: (value: string) => void;
}

export interface IAllAreaDropDowns {
	id: number;
	name: string;
	has_units: boolean;
	has_parent: boolean;
	enabled: boolean;
	kml_mandatory?: boolean;
	type?: string;
	parent_type_id?: number;
	parent_type?: string;
}

export interface IAreaTypesData {
	count: number;
	items: IAreaType[];
	page: number;
	total: number;
}

export interface IAreaData {
	count: number;
	items: IArea[];
	page: number;
	total: number;
}

export interface IAreaXPath {
	areaType: string;
	xPath: string;
}

export interface IGridColumns {
	field: string;
	title: string;
}

export interface ISidebarItem extends MenuItem {
	text: string;
	svgIcon: SVGIcon;
	selected?: boolean;
	items?: { [key: string]: string | boolean | number }[];
}

export type TabQueryParams = {
	status?: string;
	page?: number;
	limit?: number;
	type_id?: number;
};

export interface IRangeData {
	name: string;
	value: [number, number];
	backgroundColor?: string;
	color?: string;
	class?: string;
	navigateTo?: string;
	queryParams?: TabQueryParams;
	count?: number;
	percentageOfTotal?: string;
}

export interface IClickableColumn {
	name: string;
	onClick: (dataItems: unknown) => void;
}

export interface IPagination {
	count?: number;
	page?: number;
	pageSize?: number;
}

export enum SORT_ORDER {
	ASC = 'asc',
	DESC = 'desc',
}

export interface ISortQuery {
	sort_field?: string;
	sort_order?: SORT_ORDER;
}

export interface IFilterQuery {
	search_string?: string;
	service_status?: string;
	work_order_status?: string;
	work_order_type_id?: number;
}

export interface IApiCommonFilters extends IFilterQuery, ISortQuery, IPagination {}

export interface IPaymentMethod {
	isDefault: boolean;
	type: string;
	cardNo: string;
	expDate: string;
	isValid: boolean;
}

export interface ICustomFilterExpression extends FilterExpression {
	isDropdown?: boolean;
	dropdownData?: unknown;
}

export type AlertType = 'danger' | 'success' | 'warning';

export interface IAlertTheme {
	bgColor: string;
	color: string;
	icon?: SVGIcon;
}

export interface IDonutData {
	name?: string;
	kind: string;
	share: number;
	color?: string;
	background?: string;
	class?: string;
	status?: string;
}

export interface ILineChartData {
	values: number[];
	color?: string;
	status?: string;
	name?: string;
	class?: string;
}

export interface IlineChartTimeline {
	text: string;
	value: number;
}

export interface IServiceTab {
	name: string;
	urlSlug: string;
}

export interface IPaymentDetailsType {
	name: string;
	value: string;
	type?: 'selectable';
}

export type IGeneralPanelDataType = {
	name: string;
	value?: string|number;
	class?: string;
};

export type IAdvancedActionsPPPOEDetailsType = {
	userName: string;
	password: string;
};
export type IAdvancedActionsWifiDetailsType = {
	wifiSSID: string;
	wifiPassword: string;
};
export type IAdvancedActionsPortDetailsType = {
	portNo: string;
};

export interface IPanelSectionType {
	title: string;
	description: string;
	dataType: 'row' | 'grid' | 'chart' | 'form';
}

export type IPanelGridDataType = {
	columns: {
		field: string;
		title: string;
	}[];
	data: Record<string, unknown>[];
};

export interface IPanel {
	title: string;
	urlSlug: string;
	expanded: boolean;
	state?: NetworkStates;
}

export interface IDeviceStatusHistory {
	class?: string;
	status?: string;
}

export interface IGlobalSearch {
	search_string: string;
	page?: number;
	count?: number;
	supported_object_type?: string[];
}

export interface IClientPremises {
	id?: number;
	latitude?: number;
	longitude?: number;
	street_number?: string;
	street_name?: string;
	suburb?: string | null;
	city?: string;
	country_code?: string;
	postal_code?: string;
	province?: string;
	area_name?: string | null;
	mdu_name?: string | null;
	mdu_block?: string | null;
	mdu_floor?: string | null;
	mdu_unit?: string | null;
	intersection?: string | null;
	asset_reference?: string | null;
	predefined_premise_id?: number;
	deleted_at?: Date | null;
	created_at?: Date;
	updated_at?: Date;
	nid: boolean;
}

export interface ISort {
	column: string;
	order: 'asc' | 'desc';
}

export interface IFilter {
	global_search?: string;
	include_deleted?: boolean;
	column?: string;
	filter_details?: IFilterDetails;
}

export interface IFilterDetails {
	value: string;
	operator: 'equals' | 'contains' | 'gte' | 'lte' | 'is_null' | 'range';
}

export interface IClientPremisesRequest {
	pagination: IPagination[];
	sort: ISort[];
	filter: IFilter[];
}

export interface IPagedResponseExtended<T> extends IPagedResponse<T> {
	list: T[];
}

export interface IPredefinedPremiseStatus {
	id: number;
	status_id: string; // Guid
	name: string;
	description: string;
	can_order: boolean;
	status_precedence: number;
	status_type: string;
}

export interface IFullPredefinedPremiseStatus extends IPredefinedPremiseStatus {
	is_status_disabled: boolean;
}
