import { IPagedResponse } from '@aex/ngx-toolbox';
import { CustomerApi, FilesApi, PortalApi } from '@aex/shared/apis';
import {
	AuthType,
	CustomerUserService,
	HEADER_LOCATION,
	IApplicationUser,
	ICustomer,
	ICustomerProfile,
	IDocument,
	IEnumerable,
	IInFlightCustomerInfo,
	IInstaller, IPremises,
	IPremisesUpdate, ISalesAgent,
	IUserRoleResponse,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {

	public inFlightCustomerInfo: IInFlightCustomerInfo;

	private readonly defaultFilePageSize = 20;

	constructor(
		private readonly http: HttpClient,
		private readonly customerUserService: CustomerUserService,
		private readonly configService: ConfigService,
	) { }

	public set currentUser(value: ICustomer) {
		this.customerUserService.currentUser = value;
	}

	public get currentUser(): ICustomer {
		return this.customerUserService.currentUser;
	}

	private getGuid(url: string): string {
		return url?.substring(url?.lastIndexOf('/') + 1);
	}

	public getCustomerPremises(customerId: string, loader?: string): Observable<ICustomer> {
		return this.http.get<ICustomer>(CustomerApi.customerPremises(customerId), {
			params: new ParamMetaData({loader}),
		}).pipe(tap(user => this.currentUser = user));
	}

	public getCustomer(customerId: string): Observable<ICustomer> {
		return this.http.get<ICustomer>(CustomerApi.customer(customerId), {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getCustomerProfile(customerId: string): Observable<ICustomerProfile> {
		return this.http.get<ICustomerProfile>(CustomerApi.customer(customerId), {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getCustomersByRole(roleId: string): Observable<IPagedResponse<ICustomer>> {
		return this.http.get<IPagedResponse<ICustomer>>(CustomerApi.customers, {
			params: new ParamMetaData({authToken: AuthType.PROXIED})
					.set('role_id', roleId).withAllCount(),
		});
	}

	public getSalesAgent(salesAgentId: string): Observable<ISalesAgent> {
		return this.http.get<ISalesAgent>(CustomerApi.customerSalesAgents, {
			params: new ParamMetaData({authToken: AuthType.PROXIED})
				.set('sales_agent_id', salesAgentId).withAllCount(),
		})
	}

	public getSalesAgents(): Observable<ISalesAgent[]> {
		return this.http.get<ISalesAgent[]>(CustomerApi.customerSalesAgents, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getCustomerRoles(): Observable<IUserRoleResponse> {
		return this.http.get<IUserRoleResponse>(CustomerApi.customerRoles(this.currentUser.id));
	}

	// TODO:portal2 update customer profile
	public updateCustomerProfile(customerId: string, customer: Partial<ICustomerProfile>): Observable<ICustomerProfile> {
		return this.http.put<ICustomerProfile>(CustomerApi.customer(customerId), {customer}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	// TODO:portal2 manage user profile
	public updateCustomer(customerId: string, customer: Partial<ICustomer>, authToken?: string): Observable<ICustomer> {
		return this.http.put<ICustomer>(CustomerApi.customer(customerId), {customer}, {
			params: new ParamMetaData({authToken}),
		}).pipe(tap(user => this.customerUserService.mergeCurrentUser(user)));
	}

	public createCustomer(customer: Partial<ICustomer>, handleError: boolean = true): Observable<string> {
		return this.http.post(CustomerApi.customers, {customer}, {
			observe: 'response',
			params: new ParamMetaData({handleError: handleError ? 'customers' : false, authToken: AuthType.PROXIED}),
		}).pipe(
				map(response => this.getGuid(response.headers.get(HEADER_LOCATION))),
		);
	}

	public sendPasswordResetLink(customerId: string): Observable<void> {
		return this.http.post<void>(CustomerApi.resetPassword(customerId), {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED, handleError: 'authentication'}),
		});
	}

	public createCustomerPremise(premise: IPremisesUpdate): Observable<string> {
		return this.http.post(CustomerApi.premises, premise, {
			observe: 'response',
			params: new ParamMetaData({handleError: 'premise', authToken: AuthType.PROXIED}),
		}).pipe(
				map(response => this.getGuid(response.headers.get(HEADER_LOCATION))),
		);
	}

	public updateCustomerPremise(premiseId: string, premise: IPremisesUpdate): Observable<IPremisesUpdate> {
		return this.http.put<IPremisesUpdate>(CustomerApi.updatePremise(premiseId), premise, {
			params: new ParamMetaData({handleError: 'premise', authToken: AuthType.PROXIED}),
		});
	}

	public updateCustomerPremiseDevice(premiseId: string, premise: Partial<IPremisesUpdate>): Observable<IPremises> {
		return this.http.put<IPremises>(CustomerApi.updatePremiseDevice(premiseId), premise, {
			params: new ParamMetaData({handleError: 'premise', authToken: AuthType.PROXIED}),
		});
	}

	public getInstallers(): Observable<IInstaller[]> {
		return this.http.get<IPagedResponse<IInstaller>>(CustomerApi.installers, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}).withAllCount(),
		}).pipe(
				map(response => sortBy(response.items, i => i.name)),
		);
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public uploadRicaFile(customerId: string, file: any): Observable<void> {
		const formData: FormData = new FormData();
		formData.append('fileKey', file, file.name);

		return this.http.post<void>(FilesApi.file(`rica/${ customerId }`), formData);
	}

	public getIdTypes(): Observable<IEnumerable[]> {
		return this.http.get<IPagedResponse<IEnumerable>>(CustomerApi.idTypes, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}).withAllCount(),
		}).pipe(
				map(response => sortBy(response.items, i => i.id)),
		);
	}

	public fileDownload(docId: number): Observable<HttpResponse<Blob>> {
		return this.http.get(FilesApi.download(docId), {
			params: new ParamMetaData({handleError: 'files'}),
			responseType: 'blob',
			observe: 'response',
		});
	}

	public getFiles(namespace: string, pageSize : number = this.defaultFilePageSize, pageIndex = 1): Observable<IPagedResponse<IDocument>> {
		return this.http.get<IPagedResponse<IDocument>>(FilesApi.files, {
			params:
				new ParamMetaData()
		  		.set('namespace', namespace)
     			.set('page', pageIndex)
					.set('count', pageSize),
		}).pipe(map(files => {
			files.items.forEach(file => {
				if (file.namespace !== namespace) {
					const indexOf = file.namespace.lastIndexOf('/');
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
					file.upload_type = file.namespace.substring(indexOf + 1);
				}
			});
			return files;
		}));
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public fileUpload(filename: string, namespace: string, upload: any): Observable<any> {
		const formData = new FormData();
		formData.set('file[filename]', filename);
		formData.set('file[namespace]', namespace);
		formData.set('upload', upload);
		return this.http.post(CustomerApi.files(), formData);
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public sendAccountCreationLink(user: IApplicationUser): Observable<any> {
		return this.http.post(PortalApi.emailConfirmLink, user, {
			params: new ParamMetaData({authToken: AuthType.NONE}),
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public confirmAccountCreation(email: string, code: string): Observable<any> {
		return this.http.post(PortalApi.emailConfirm, '', {
			params: new ParamMetaData({authToken: AuthType.NONE}).set('email', email).set('code', code),
			headers: new HttpHeaders({'Content-Type': 'application/json'}),
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public resetPasswordLink(user: IApplicationUser): Observable<any> {
		return this.http.post(PortalApi.resetPasswordLink, user, {
			params: new ParamMetaData({authToken: AuthType.NONE}),
		});
	}

	public accountActivationLink(user: IApplicationUser): Observable<void> {
		return this.http.post<void>(PortalApi.accountActivationLink, user, {
			params: new ParamMetaData({authToken: AuthType.NONE}),
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public resetPassword(email: string, token: string, password: string): Observable<any> {
		return this.http.post(PortalApi.resetPassword, '', {
			params: new ParamMetaData({authToken: AuthType.NONE}).set('email', email).set('token', token).set('password', password),
			headers: new HttpHeaders({'Content-Type': 'application/json'}),
		});
	}

	public sendVerificationEmail(email: string, returnAddress: string = this.configService.fnoPortalTwoBaseUrl): Observable<boolean> {
		return this.http.post<boolean>(CustomerApi.emailVerification(), {
			EmailAddress: email,
			ReturnAddress: returnAddress,
		}, {
			headers: new HttpHeaders({'Content-Type': 'application/json'}),
		})
	}
}
