import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appNoWhitespace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoWhitespaceValidatorDirective,
      multi: true ,
    },
  ],
})
export class NoWhitespaceValidatorDirective implements Validator {

  @Input() appNoWhitespace = true;

  public validate(control: AbstractControl): ValidationErrors | null {
    if (!this.appNoWhitespace || !control.value || control.value === '')
      return null;

    const isWhitespaceOnly = control.value.trim().length === 0;
    return isWhitespaceOnly ? { whitespace: true } : null;
  }
}