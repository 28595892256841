import { IGeneralPanelDataType } from '@aex/shared/common-lib';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ICustomer } from './customer';
import { IClientPremise, IPredefinedPremise, IPremises } from './premise';
import { IProduct } from './product';
import { Company } from './scheduler';
import { IArea, IProvider, IServiceStateAndAction, QuestionAnswers } from './types';
import { Type } from 'class-transformer';

export interface IWorkOrderStatusResponse {
	count: number;
	items: IWorkOrderStatus[];
	page: number;
	total: number;
}

export interface IWorkOrderTransition {
	id: number;
	type_id: number;
	updated_at: string;
	enabled: boolean;
	source_status_id: string;
	destination_status_id: string;
	is_admin_override?: boolean | null;
}

export interface IWorkOrderTransitionWithStatusNames extends IWorkOrderTransition {
	source_status_name: string;
	destination_status_name: string;
	order : number;												// Destination Status Order
	is_admin_override: boolean;
}

export interface IWorkOrderStatus {
	activated: boolean;
	action_name: string;
	allow_admin_override: boolean;
	allow_product_change: boolean;
	asset_attribute_required: number;
	asset_type_required: number;
	asset_validation_required: boolean;
	cancelled: boolean;
	client_description: string;
	closed: boolean;
	create_work_order_type_id: string;
	document_uploads_enabled: boolean;
	enable_clear_fsan: boolean;
	enable_edit_fsan: boolean;
	enabled: boolean;
	entity_notification_email: string;
	entity_notification_sms: string;
	entity_notification_subject: string;
	id: string;
	installer_allocated: boolean;
	isp_provisioned: boolean;
	name: string;
	notify_entity: boolean;
	notify_installer: boolean;
	notify_service_provider: boolean;
	on_network: boolean;
	order: number;
	previous_entity_allocated: boolean;
	require_payment: boolean;
	schedule_time_required: boolean;
	scheduled_date_required: boolean;
	scheduled_id: string;
	service_provider_allocated: boolean;
	send_email: boolean;
	send_sms: boolean;
	skip_asset_required: boolean;
	type: string;
	type_id: number;
	user_allocated: boolean;
	user_allowed: boolean;
}

export interface IInstaller {
	name: string;
	id: string;
	assigned_company: string;
	parent_id: string;
	registration_number: string;
	support_number: string;
	website: string;
	email: string;
	active: boolean;
	enabled: boolean;
}

export interface IInFlightCustomerInfo {
	customerId: string;
	premiseId: string;
	answers?: QuestionAnswers;
	email: string;
	password?: string;
}

export interface IServiceInfo {
	service_id: string;
	plan_id: string;
	debit_day: number;
	customer_id: string;
}

export interface IServiceOrder {
	service: Partial<IService>;
	force_index: boolean;
	bypass_wo_creation: boolean;
	bypass_feasibility: boolean;
	bypass_premise: boolean;
	work_order: {
		type_id: number;
	};
	pppoe_username?: string;
	pppoe_password?: string;
}

export interface IWorkOrder {
	id: string;
	type_id: number;
	type: string;
	reference: string;
	on_hold: boolean;
	completed_date: string;
	close_date: string;
	schedule_date: string;
	schedule_time: string;
	external_reference: string;
	category: string;
	updated_at: string;
	created_at: string;
	status_id: string;
	assigned_id: string;
	assigned_user_id: string;
	created_by: string;
	created_by_id: string; // double check which is correct
	status: string;
	service_id: string;
	last_comment?: string;
	comments?: string;
	account_id: string;
	client_status?: string;
	service?: Partial<IFullService>;
	last_update_date: string;
	closed?: boolean;
	assigned_to_name?: string;
	assigned_user_name?: string;
	assigned_user?: string;
	assigned?: string | null;
	installer_id?: string | null;
	installer_name?: string | null;
	last_updated_days?: number;
	days_open?: number;
	comment?: string;
	summary?: string | null;
	description?: string | null;
	network_parent_product_name?: string;
	workOrder_history?: IWorkOrderHistory[];
	company_details?: Company[]
	workOrder_status: IWorkOrderStatusResponse;
	modified_by_entity: string;
	modified_by_id: string;
}

export interface IWorkOrderHistory {
	id: string;
	work_order: string;
	status_changed: boolean;
	previous_status: string;
	status_id: string;
	new_status: string;
	new_status_id: string;
	company_changed: boolean;
	previous_company: string;
	entity_id: string;
	new_company: string;
	new_entity_id: string;
	person_changed: boolean;
	previous_person: string;
	entity_person_id: string;
	new_person: string;
	new_entity_person_id: string;
	installer_changed: boolean;
	previous_installer: string;
	installer_entity_id: string;
	new_installer: string;
	new_installer_entity_id: string;
	schedule_date_set: boolean;
	schedule_date: string;
	on_hold_set: boolean;
	on_hold: boolean;
	modified_by: string;
	modified_by_id: string;
	comments: string;
	modified_date: Date;
	modified_by_entity_id: string;
	modified_by_entity: string;
	schedule_time: Date;
}

export interface IWorkOrderUpdate {
	work_order: Partial<IWorkOrder>;
}

export interface IExtendedWorkOrderUpdate extends IWorkOrderUpdate {
	work_order: Partial<IWorkOrder> & {
		comments: string;
	};
}

export interface IServiceCreate extends IServiceOrder {
	customer: Partial<ICustomer>;
	premise: Partial<IPremises>;
}

export interface IService {
	id: string;
	guid: string;
	area_id: string;
	status: string;
	status_id: number;
	product_id: string;
	customer_id: string;
	premise_id: string;
	preorder: boolean;
	isp_product_id: string;
	network_product_id: string;
	backhaul_product_id: string;
	operator_id: string;
	provider_id: string;
	isp_reference: string;
	port_number: string;
	provisioned_date: string;
	on_network_date: string;
	completed_date: string;
	cancelled_date: string;
	expiry_date: string;
	promo_code: string;
	sales_agent: string;
	deleted_at: string;
	created_at: string;
	updated_at: string;
	auto_configured: boolean;
	wifi_username: string;
	wifi_password: string;
	pppoe_username: string;
	pppoe_ip_address: string;
	reference_code: string;
	rica_validated: boolean;
	suspended_date: string;

	ip_v4_public : boolean;
	ip_v6_public : boolean;
	pppoe_v6_address: string;
	service_configuration_id: number,
	pppoe_password: string;
}

export interface IServiceStatus {
	id: number;
	name: string;
}

export interface IWorkOrderType {
	id: number;
	name: string;
}

export interface IFullServiceResponse {
	code: number;
	full_service: IFullService;
}

export interface ISuspendServiceResponse {
	code: number;
	message: string[];
}

export interface IActivateServiceResponse {
	code: number;
	message: string[];
}


//#todo:portal2 - check if duplicate-> can use other !
export interface IFullService {
	customer: Partial<ICustomer>;
	service: Partial<IService>;
	work_order?: Partial<IWorkOrder>;
	isp_product: Partial<IProduct>;
	provider: Partial<IProvider>;
	predefined_premise?: IPredefinedPremise;
	area: Partial<IArea>;
	pmo_areas?: IPmoArea[] | [];
	client_premise?: IClientPremise;
	premise: Partial<IPremises>;
	status?: Partial<IDeviceStatus>;
	device_status?: Partial<IDeviceStatus>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	point: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	region: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	network_product: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	backhaul_product: any;
	last_updated: string;
	device_asset_attribute?: IDeviceAssetAttributes;
	billing_status?: IServiceStateAndAction;
}

export interface IDeviceStatus {
	serial_number: string;
	last_updated: string;
	service: string;
	pop_status: string;
	status: string;
}

export interface IFullDeviceNetworkConfig {
	service_port: string;
	ip_address: string;
	isp_modem_mac: string;
	nni_mac: string;
	configuration: string;
	devices_connected: number | string;
	pppoe_status: string;
	order_status: string;
	last_updated: string;
	pop_id: number;
	pop_description: string;
	pop_status: string;
	vlans: string;
	download_speed: number;
	upload_speed: number;
	ont_port: number;
	service_provider: string;
	speeds_success: boolean;
	vlans_success: boolean;
	configuration_type: string;
}

export interface IFullDeviceNetworkStatus {
	network: string;
	pop: string;
	serial_number: string;
	gpon_status: string;
	cpe: string;
	model: string;
	lease_expiry: string;
	modem_port_speed: string;
	modem_connected: string;
	object_status: string;
	last_updated: string;
	service: string;
	pop_name: string;
	alerts: string[];
	alarms: string[];
	populated_ports: string[];
	olt_port: string;
	distance: number;
	rx_status: string;
	rx_power: number;
	sfp_status: string;
	sfp_power: number;
	sfp_rx_status: string;
	sfp_rx_power: number;
	tx_power: number;
	tx_status: string;
	switch: string;
	sfp_sync_speed: string;
	switch_port: string;
}

export interface IRadiusStatus {
	pppoe_username: string;
	status: string;
}

export interface IConfiguredWifiFrequency {
	wmm: string;
	channel: string;
	band_width: string;
	information: string[];
	channel_width: string;
	ssid: string;
}

export interface IWifiConfig {
	transport_power: string;
	auth_mode: string;
	encryption_mode: string;
	connection_status: string;
	frequency: IConfiguredWifiFrequency[];
}

export interface ILayer3Details {
	pppoe_username: string;
	pppoe_password?: string;
	device_id: string;
	added: Date;
	configured_wifi: IWifiConfig;
}

export interface IDeviceHistory {
	tx_status: string;
	sfp_light_level: number;
	added: string;
	tx_light_level: number;
	alarms: string[];
	rx_light_level: number;
	oper_status: string;
	rx_status: string;
	reference: string;
	alerts: string[];
	olt_name: string;
	ont_distance: number;
	vendor: string;
	olt: string;
	sfp_status: string;
	name: string;
	admin_status: string;
	model: string;
	sfp_rx_light_level: number;
	network_type: string;
	sfp_rx_status: string;
	status: string;
}

export interface IWifiDevices {
	devices: IWifiDeviceRecord[][];
}

export interface IWifiDeviceRecord {
	field: string;
	value: string;
}
export interface IPmoArea {
	name?: string;
	id?: string;
	area_type?: string;
}
export interface IServicePayload {
	product_id?: string;
	dhcp_enabled: boolean;
	level?: number;
	provisioned?: boolean;
	on_network?: boolean;
	completed?: boolean;
	cancelled?: boolean;
	cancellation_reason?: string;
	sales_agent?: string;
	sales_channel?: string;
	start_date?: string;
	deleted_at?: string;
	last_network_check?: string;
	ip_v4_public?: boolean;
	ip_v6_public?: boolean;
	pppoe_v6_address: null;
	id: string;
	customer_id: string;
	area_id: string;
	premise_id: string;
	isp_product_id: string;
	network_product_id: string;
	backhaul_product_id: string;
	operator_id: string;
	provider_id: string;
	preorder: boolean;
	status: string;
	isp_reference?: string;
	port_number: string;
	pppoe_username: string;
	pppoe_ip_address?: string;
	wifi_username: string;
	provisioned_date?: string;
	on_network_date?: string;
	completed_date?: string;
	cancelled_date?: string;
	expiry_date?: string;
	promo_code?: string;
	promotion_ids?: string[];
	auto_configured?: string;
	created_by_id?: string;
	updated_at: string;
	created_at: string;
	created_date?: string;
	category?: string;
	created_by_user?: string;
	guid?: string;
	reference_code?: string;
	rica_validated?: string;
	suspended_date?: string;
}

export interface IPoint {
	lat: number;
	lng: number;
}

export interface IDeviceAssetAttributes {
	id: number;
	operator_id: number;
	parent_id: number;
	network_id: number;
	node_type?: string;
	type?: string;
	identifier?: string;
	description?: string;
	reference?: string;
	status?: string;
	hub_number?: string;
	hub_switch_blade?: string;
	hub_switch_port?: string;
	hub_model?: string;
	ip_address?: string;
	ont_model?: string;
	vendor?: string;
	mac_address?: string;
	updated_at: string;
	created_at: string;
}

export interface IRegion {
	id?: number;
	name?: string;
	updated_at?: string;
	created_at?: string;
}

export interface IServiceRecord {
	area: IArea;
	parent_area: IArea;
	complex: IArea;
	isp_product: IProduct;
	network_product: IProduct;
	backhaul_product: IProduct;
	predefined_premise: IPredefinedPremise;
	client_premise: IClientPremise;
	pmo_areas?: IPmoArea[];
	location_asset_attributes: unknown;
	device_asset_attributes: IDeviceAssetAttributes;
	customer: ICustomer;
	provider: IProvider;
	premise: IPremises;
	point: IPoint;
	region: IRegion;
	parent_region: null | unknown;
	service: IServicePayload;
	asset_attributes: unknown;
}

export interface IServicesGridData {
	items: IServiceRecord[];
	count: number;
	total: number;
}

export interface IGridData {
	items: IRecord[];
	count: number;
	total: number;
}

// Data Grid is generic. Types for API responses are declared but since values passed to data grid can vary, IRecord is specifically used to accommodate this functionality
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IRecord = Record<any, any>;

export interface IGridColumn {
	heading: string;
	field: string;
	sub_field: string;
	type: string;
}

export interface IExtendedCompositeFilterDescriptor extends CompositeFilterDescriptor {
	editor: string;
	title: string;
}

export interface IFilters {
	logic: 'and' | 'or';
	filters: (CompositeFilterDescriptor | FilterDescriptor | IExtendedCompositeFilterDescriptor)[];
}

export interface IView {
	name: string;
	searchQuery: string;
	selectedColumns: string[];
	selectedFilters: IFilters;
	removable: boolean;
}

export interface IGlobalSearchFieldMatches {
	field_name: string;
	matches: string[];
}

export interface IGlobalSearchMatchedObject {
	object_type: string;
	id: string;
	field_matches: IGlobalSearchFieldMatches[];
}

export type IServiceRecordValue = IServiceRecord[keyof IServiceRecord];
export type MyRecordType = Record<string, IServiceRecordValue>;

export type GlobalSearchObjects = {
	customer: MyRecordType,
	service: MyRecordType,
	premise: MyRecordType
}

export interface IInformationalObjects {
	object:MyRecordType,
	object_type: string;
}

export interface IGlobalSearchItems {
	score: number;
	matched_objects: IGlobalSearchMatchedObject[];
	informational_objects:IInformationalObjects[]
}

export interface IGlobalSearchResponse {
	count: number;
	items: IGlobalSearchItems[];
}

export interface ILayerThreeData {
	pppoe: IGeneralPanelDataType[];
	lastAuthAttempt: IGeneralPanelDataType[];
	activeSessions: IGeneralPanelDataType[];
}

export interface ICancellationReason {
	id: number;
	cancellation_option: string;
	order_by: number;
	deleted: boolean;
}

export interface INetworkDataUsage {
	DATE: string;
	username: string;
	up: number;
	upload: number;
	down: number;
	download: number;
	Cal_Key_Workings: string;
	FSAN: string;
	Key: string;
	ESID: string;
}

export interface INetworkDataUsageReport {
	title: string;
	categories: string[];
	series: IDataUsageSeries[];
}

export interface IDataUsageSeries {
	name: string;
	series: number[];
}

export interface IDeviceCpeStateInfo {
	state: number;
	message: string;
}

export interface IDeviceSpeedTestResponse {
	device_reference: string;
	type: string;
	message: string;
	upload_speed: string;
	download_speed: string;
	cpe_state_info: IDeviceCpeStateInfo;
}

export interface IDeviceSpeedTest {
	response: IDeviceSpeedTestResponse
}

export interface IDeviceSpeedTestHistory extends IDeviceSpeedTest {
	test_passed: boolean;
	boolean_value: boolean;
	raw_value: string;
	value: boolean;
	unit: string;
	formatted_value: string;
	id: string;
	operator_id: string;
	device_id: string;
	timestamp: string;
	metric_id: string;
	metric_name: string;
	collector_name: string;
	collection_name: string;
	interface_id: string;
	interface_name: string;
	fish_tag: string;
	is_error: boolean;
	error_message: string;
}

export interface IDeviceSpeedTestHistoryFlat extends IDeviceSpeedTestHistory {
	device_state: number;
	device_message: string;
	upload_speed: string;
	download_speed: string;
	failure_reason: string;
}

//#region --- Ping ---
export interface IPingDetails {
	minimum_response_time: number;
	maximum_response_time: number;
	average_response_time: number;
	success_count: number;
	failure_count: number;
}
export interface IPingHistoryDetails {
	MinimumResponseTime: number;
	MaximumResponseTime: number;
	AverageResponseTime: number;
	SuccessCount: number;
	FailureCount: number;
}

export interface IPingBase {
  device_reference: string;
  external_reference: string;
  message: string;
  test_type: string;
  test_passed: boolean;
}

export interface IPing extends IPingBase{
	details: IPingDetails;
}

export interface IPingHistoryBase extends IPingBase {
	details: IPingHistoryDetails;
}
export interface IPingHistory extends IPingHistoryBase {
	boolean_value: boolean;
	raw_value: string;
	value: boolean;
	unit: string;
	formatted_value: string;
	id: string;
	operator_id: string;
	device_id: string;
	timestamp: string;
	metric_id: string;
	metric_name: string;
	collector_name: string;
	collection_name: string;
	interface_id: string;
	interface_name: string;
	fish_tag: string;
	is_error: boolean;
	error_message: string;
}
export interface IPingHistoryFlat extends IPingHistory {
	minimum_response_time: number;
	maximum_response_time: number;
	average_response_time: number;
	success_count: number;
	failure_count: number;
}

//#endregion --- Ping ---

//#region --- Trace Route ---

export interface ITraceRouteDetailRouteHop {
	host: string;
	host_address: string;
	round_trip_times: number[];
}

export interface ITraceRouteDetails {
	response_time: number;
	route_hops: ITraceRouteDetailRouteHop[];
}
export interface ITraceRoute {
	device_reference: string;
	external_reference: string;
	message: string;
	test_type: string;
	details: ITraceRouteDetails;
}

export interface ITraceRouteHistory{
	id: string,
	operator_id: string,
	device_id: string,
	timestamp: string,
	metric_id: string,
	metric_name: string,
	collector_name: string,
	collection_name: string,
	interface_id: string,
	interface_name: string,
	test_passed: boolean,
	boolean_value: boolean,
	raw_value: string,
	value: boolean,
	unit: string,
	formatted_value: string,
	fish_tag: string,
	is_error: boolean,
	error_message: string
	details: ITraceRouteDetails;
}

export interface ITraceRouteHistoryFlat extends ITraceRouteHistory {
	response_time: number;
	hop_route: string[]; // Response + (Host + Host Address + Round Trip Times)
}

//#endregion --- Trace Route ---

//#region --- NS Lookup ---
export interface INSLookupDetail {
	answer_type: string;
	diagnostics_state: string;
	dns_server_ip: string;
	host: string;
	success: string;
	response_time: number;
	ip_addresses: string[];
}

export interface INSLookup {
	device_reference: string,
	external_reference: string,
	message: string,
	test_type: string,
	test_passed: boolean,
	details: INSLookupDetail;
}

export interface INSLookupHistory extends INSLookup {
	boolean_value: boolean;
	raw_value: string;
	value: boolean;
	unit: string;
	formatted_value: string;
	id: string;
	operator_id: string;
	device_id: string;
	timestamp: string;
	metric_id: string;
	metric_name: string;
	collector_name: string;
	collection_name: string;
	interface_id: string;
	interface_name: string;
	fish_tag: string;
	is_error: boolean;
	error_message: string;
}

export interface INSLookupHistoryFlat extends INSLookupHistory {
	answer_type: string;
	dns_server_ip: string;
	host: string;
	response_time: number;
	ip_addresses: string[];
}

//#endregion --- NS Lookup ---

//#region --- Radius History ---
export interface IRadiusHistoryAttributeBase {
	attribute: string;
	op: string;
	value: string;
}
export interface IRadiusHistoryCheck extends IRadiusHistoryAttributeBase {
}
export interface IRadiusHistoryAttribute extends IRadiusHistoryAttributeBase {
}
export interface IRadiusHistoryAuthenticationAttempt {
	auth_date: string;
	reply: string;
}
export interface IRadiusHistoryAccountingBase {
	id: number;
	acct_session_id: string;
	acct_unique_id: string;
	user_name: string;
	realm: string;
	nasipaddress: string;
	nasport_id: string;
	nasport_type: string;
	acct_start_time: string;
	acct_stop_time: string;
	acct_session_time: number;
	acct_authentic: string;
	connect_info_start: string;
	connect_info_stop: string;
	acct_input_octets: number;
	acct_output_octets: number;
	acct_ipv6_input_octets: number;
	acct_ipv6_output_octets: number;
	called_station_id: string;
	calling_station_id: string;
	acct_terminate_cause: string;
	service_type: string;
	framed_protocol: string;
	framed_ipaddress: string;
	framed_ipv6_address: string;
	framed_ipv6_prefix: string;
	framed_interface_id: string;
	delegated_ipv6_prefix: string;
	acct_update_time: string;
	// Extended on Client size
	humanSessionTime: string;
	humanInputSize: string;
	humanOutputSize: string;
}
export interface IRadiusHistoryAccounting extends IRadiusHistoryAccountingBase {
}
export interface IRadiusHistoryAccountingActive extends IRadiusHistoryAccountingBase {
}

export interface IRadiusHistory {
	username: string;
	password: string;
	checks: IRadiusHistoryCheck[];
	attributes: IRadiusHistoryAttribute[];
	authentication_attempts: IRadiusHistoryAuthenticationAttempt[];
	accounting: IRadiusHistoryAccounting[];
	accounting_active: IRadiusHistoryAccountingActive[];
}

//#endregion --- Radius History ---

//#todo remove if no longer used - Morris
export interface IRawWifiDeviceAttribute {
	field: string;
	value: string;
}

export interface IRawWifiDevices {
	devices: IRawWifiDeviceAttribute[][];
	added: string;
}

export interface IServiceHistory{
	id: string;
	service_id: string;
	portal_user_id: string;
	message: string;
	attribute: [];
	value: [];
	created_at: string;
	transition_user: string;
}

export interface IWorkOrderWithComments {
	id: string;
	guid: string;
	reference: string;
	taskDetails: IWorkOrder;
	ispDetails: string;
	comments: IWorkOrderHistory[];
}

export interface IServiceConfiguration{
	id: number;
	description : string;
	enabled: boolean;
	created_at: Date;
	updated_at: Date;
	brief	: string;
}

export class ServiceConfiguration implements IServiceConfiguration{
	id: number;
	description : string;
	enabled: boolean;
	@Type(() => Date)
	created_at: Date;
	@Type(() => Date)
	updated_at: Date;
	brief	: string;

  constructor(init?: Partial<ServiceConfiguration>) {
    Object.assign(this, init);
  }
}

// export class SaveServiceConfigurationRequestDto {
// 	service_configuration_id: number;
// }

// export class SaveServicePPPoEDetailsRequestDto {
// 	pppoe_username: string;
// 	pppoe_password: string;
// }

// export class SaveServiceWifiDetailsRequestDto {
// 	wifi_username: string;
// 	wifi_password: string;
// }

// export class SaveServiceiPV6RequestDto {
// 	publicIpv4Address: boolean;
// 	publicIpv6Address: boolean;
// 	ipv6Address: string;
// }

// export class SaveServicePortNumberRequestDto {
// 	port_number: number;
// }

export class IRebootOntResponseDto{
	code: string;
	message: string;
	reference: string;
}
// TODO:portal2 move these interfaces into libs\shared\common-lib\src\interfaces directory


export class IServiceCancellationDateDto{
	service_id: string;
	effective_date: string
}
