import {IAdvanceGridSearch, ISearchData} from "./kendo-grid";
import {ServiceHistoryTypes} from "../helpers";

export class ServiceHistoryData implements ISearchData, IAdvanceGridSearch {
	id: string;
	history_id: string;
	reference: string;
	date: Date;
	modified_by: string;
	history_type: ServiceHistoryTypes;
	detail: string;
}
