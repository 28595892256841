import { Exclude, Type } from "class-transformer";
import { ServiceCreatedDateRangeItem } from "./date-range-selector";
import { IAdvanceGridSearch } from "./kendo-grid";
import { IFullService } from "./search-service";

export interface ICustomerService extends IFullService{
  area_status_class: string;
  service_status_class: string;
}

export class CustomerServiceAdvSearchFilter implements IAdvanceGridSearch
{
  public search_string : string = '';
  @Exclude()
  public get include_created_date_filter() : boolean {
    return this.serviceDataRangeSelector.selected;
  };
  @Exclude()
  public get created_date_from() : Date {
    return this.serviceDataRangeSelector.date_start ? this.serviceDataRangeSelector.date_start_value : new Date();
  }
  @Exclude()
  public get created_date_to() : Date {
    return this.serviceDataRangeSelector.date_end ? this.serviceDataRangeSelector.date_end_value : new Date();
  }

  public service_statuses : string[] = [];
  public parent_area_id : string = '';
  public area_id : string = '';

  @Type(() => ServiceCreatedDateRangeItem)
  public serviceDataRangeSelector : ServiceCreatedDateRangeItem = new ServiceCreatedDateRangeItem();

  public clearFilters() : void {
    this.search_string = '';
    this.serviceDataRangeSelector.selected  = false;
    this.serviceDataRangeSelector.date_start_value = new Date();
    this.serviceDataRangeSelector.date_end_value = new Date();
    this.service_statuses = [];
    this.parent_area_id = '';
    this.area_id = '';
  }
}

// #todo:portal 2- move to different file
export interface custom_chip {
  label: string;
  hint: string;
  chipClass: string;
}

export interface NetworkDeviceManagementAction {
  actionName: string;
  buttonName: string;
  buttonDisabled: boolean;
  action: () => void;
}

export interface ICustomerProfile {
  id: string;
  parent_id: string;
  portal_user_id: string;
  first_name: string;
  last_name: string;
  business_name: string;
  email: string;
  username: string;
  mobile_number: string;
  home_number: string;
  work_number: string;
  password: string;
  external_reference: string;
  fno_opt_in: string;
  isp_opt_in: string;
  opt_in_ip_address: string;
  vat_number: string;
  identity_number: string;
  registration_number: string;
  billing_address: string;
  billing_suburb: string;
  billing_city: string;
  billing_postal_code: string;
  billing_province: string;
  billing_country: string;
  physical_address: string;
  physical_suburb: string;
  physical_city: string;
  physical_postal_code: string;
  physical_province: string;
  physical_country: string;
  security_stamp: string;
  service_provider_flag: string;
  fax_number: string;
  nationality: string;
  identification_type_id: string;
  created_at: string;
  updated_at: string;
  enabled: string;
  alternative_number: string;
  preferred_contact_method: string;
  comments: string;
  preferred_contact_time: Date;
}

export class CustomerProfile implements ICustomerProfile {
  id: string = '';
  parent_id: string = '';
  portal_user_id: string = '';
  first_name: string = '';
  last_name: string = '';
  business_name: string = '';
  email: string = '';
  username: string = '';
  mobile_number: string = '';
  home_number: string = '';
  work_number: string = '';
  password: string = '';
  external_reference: string = '';
  fno_opt_in: string = '';
  isp_opt_in: string = '';
  opt_in_ip_address: string = '';
  vat_number: string = '';
  identity_number: string = '';
  registration_number: string = '';
  billing_address: string = '';
  billing_suburb: string = '';
  billing_city: string = '';
  billing_postal_code: string = '';
  billing_province: string = '';
  billing_country: string = '';
  physical_address: string = '';
  physical_suburb: string = '';
  physical_city: string = '';
  physical_postal_code: string = '';
  physical_province: string = '';
  physical_country: string = '';
  security_stamp: string = '';
  service_provider_flag: string = '';
  fax_number: string = '';
  nationality: string = '';
  identification_type_id: string = '';
  created_at: string = '';
  updated_at: string = '';
  enabled: string = '';
  alternative_number: string = '';
  preferred_contact_method: string = '';
  comments: string = '';
  preferred_contact_time: Date = new Date();
}

// export class ServicePersonalDetails {
//   public first_name: string = '';
//   public last_name: string = '';
//   public email: string = '';
//   public mobile_number: string = '';
//   public alternative_number: string = '';
// }

// export class ServicePremiseDetails {
//   public address: string = '';
//   public complex: string = '';
//   public suburb: string = '';
//   public city: string = '';
//   public area: string = '';
// }

// export class ServiceProductDetails {
//   public product_id: string = '';
// }

// export class CustomerService {
//   public personalDetails: ServicePersonalDetails = new ServicePersonalDetails();
//   public premiseDetails: ServicePremiseDetails = new ServicePremiseDetails();
//   public productDetails: ServiceProductDetails = new ServiceProductDetails();
// }
