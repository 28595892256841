import { Injectable } from '@angular/core';
import {ServiceService, WorkOrderService} from "@aex/shared/apis";

import {DateHelper, IActivateServiceResponse, ISuspendServiceResponse, IWorkOrder, IWorkOrderUpdate, WorkOrderType} from "@aex/shared/common-lib";
import {AuthService } from "@aex/shared/root-services";
import {Observable, of} from "rxjs";
import { tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";


@Injectable({
	providedIn: 'root',
})
export class TaskActionDataService {

	constructor(
		private readonly authService: AuthService,
		// private readonly configService: BaseConfigService,
		private readonly toastrService: ToastrService,
		private readonly serviceService: ServiceService,
		private readonly workOrderService: WorkOrderService,
	) {}

	private generateReference(): string {
		// Get current date at midnight
		const now: Date = new Date();
		const dtStart: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate());

		// Calculate remaining milliseconds
		const remainingMilliseconds: number = now.getTime() - dtStart.getTime();

		// Convert to nanoseconds approximation (C# Ticks are 100ns each)
		const remainingTicks: number = Math.floor(remainingMilliseconds * 10000);

		// Format date as yyyyMMdd
		const datePart: string = now.toISOString().slice(0, 10).replace(/-/g, '');

		// Format ticks with padding and substring to match C# logic
		const ticksPart: string = remainingTicks.toString().padStart(9, '0').substring(0, 9);

		return `${datePart}-${ticksPart}`;
	}

	public logFault(serviceId: string, faultComment: string): Observable<IWorkOrderUpdate>{
		const userId: string = this.authService.getUserIdClaim();
		const comment = `Reason for Fault: ${faultComment}`
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.Fault,
			account_id: serviceId,
			installer_id: null,
			reference: this.generateReference(),
			created_by_id : userId,
			modified_by_id : userId,
			on_hold: false,
			comments: comment,
		};
		return this.workOrderService.createWorkOrder(
			{
				work_order: workOrder,
			},
			true,
			);
	}

	public logRepair(serviceId: string): Observable<IWorkOrderUpdate>{
		const userId: string = this.authService.getUserIdClaim();
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.Repair,
			account_id: serviceId,
			installer_id: null,
			reference: this.generateReference(),
			created_by_id : userId,
			modified_by_id : userId,
			on_hold: false,
		};
		return this.workOrderService.createWorkOrder(
			{
				work_order: workOrder,
			},
			true,
		);
	}

	private createCancellationWorkOrder(
		serviceId: string,
		comment: string,
		cancellationDate: Date,
		userId: string,
	)	: Observable<IWorkOrderUpdate> {
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.Cancellation,
			account_id: serviceId,
			installer_id: null,
			reference: this.generateReference(),
			created_by_id : userId,
			modified_by_id : userId,
			on_hold: false,
			schedule_date: DateHelper.formatDate(cancellationDate),
			schedule_time: cancellationDate.toISOString(),
			comments: comment,
		};
		return this.workOrderService.createWorkOrder(
			{
				work_order: workOrder,
			},
			true,
		);
	}

	private mayCreateDeviceRecoveryWorkOrder(): boolean{
		return false;
	}

	private mayBoltOnServicesEnabled(): boolean{
		return false;
	}

	private createDeviceRecoveryWorkOrder(
		serviceId: string,
		userId: string,
	)	: Observable<IWorkOrderUpdate> {
		const workOrder: Partial<IWorkOrder> = {
			type_id: WorkOrderType.RecoverDevice,
			account_id: serviceId,
			installer_id: null,
			reference: this.generateReference(),
			created_by_id : userId,
			modified_by_id : userId,
			on_hold: false,
			comments: '',
		};
		return this.workOrderService.createWorkOrder(
			{
				work_order: workOrder,
			},
			true,
		);
	}

	public cancelBoltOnServices(
		 _parentServiceId : string,
	   _userId: string,
	   _effectiveDate: Date,
		 _comment: string,
	): Observable<string>
	{
		// Fetch All Bolt on Service for service Ids
		return of('')

// 	errorMessage = string.Empty;
// 	FNOAPI fnoApi = new FNOAPI();
//
// 	try
// {
// 	IEnumerable<dynamic> boltOnServices = fnoApi.GetBoltOnServices(parentServiceId).items;
//
// 	foreach (dynamic boltOnService in boltOnServices)
// {
// 	WorkOrder.CreateWorkOrder(
// (int)WorkOrderType.Cancellation,
// (Guid)boltOnService.bolt_on_id,
// 	userId,
// 	comment: comment,
// 	errorMessage: out errorMessage,
// 	scheduleDate: effectiveDate.LocalDateTime
// );
//
// 	if (!string.IsNullOrWhiteSpace(errorMessage))
// {
// 	return;
// }
// }
// }
// catch (Exception ex)
// {
// 	errorMessage = "Could not create cancelleation Work Order for bolt-on service";
// 	Log.Error(ex, errorMessage);
// 	return;
// }
//
// try
// {
// 	fnoApi.UnMapBoltOnChildServices(parentServiceId);
// }
// catch (Exception ex)
// {
// 	errorMessage = "Could not unmap bolt-on services from parent service";
// 	Log.Error(ex, errorMessage);
// }
}

	public cancelService(
		serviceId: string,
		cancellationReason: string,
		comment: string,
		effectiveDate: Date,
	): Observable<IWorkOrderUpdate>{
		const userId: string = this.authService.getUserIdClaim();
		const reason: string = comment ?  `${cancellationReason} : ${comment}` : cancellationReason;
		// // initiate work order - since cancellation date is auto generated (schedule time has not been selected)
		// // we use tz of operator
		// var cancellationEffectiveDate = TzUtils.ToOperatorDateTimeOffset(
		// 	new DateTime(
		// 		model.CancellationEffectiveDate.Year,
		// 		model.CancellationEffectiveDate.Month,
		// 		model.CancellationEffectiveDate.Day
		// 	)
		// ).AddDays(1).AddMinutes(-1);
		return this.createCancellationWorkOrder(
			serviceId,
			reason,
			effectiveDate,
			userId,
		).pipe(
			tap(
				() => {
					// if EnableTerminationCauses
					this.toastrService.success('Cancellation work order created');
				},
				() =>{
					this.toastrService.success('An error occurred for cancellation work order');
				},
			),
			tap(
				() => {
					// if EnableTerminationCauses
				},
			),
			tap(
				() => {
					// if Enable Device Recovery Work Order
					if (this.mayCreateDeviceRecoveryWorkOrder())
						this.createDeviceRecoveryWorkOrder(
							serviceId,
							userId,
						).subscribe(
							() => {
								this.toastrService.success("Device recovery work order created")
							},
							()=>{
								this.toastrService.error("An error occurred for Device recovery work order")
							},
						);
				},
			),
			tap(
				() => {
					// if Enable Device Recovery Work Order
					if (this.mayBoltOnServicesEnabled())
						this.cancelBoltOnServices(
							serviceId,
							userId,
							effectiveDate,
							reason,
						).subscribe(
							() => {
								this.toastrService.success("Bolt On service work-orders created")
							},
							()=>{
								this.toastrService.error("An error occurred for bolt-on-service work-orders")
							},
						);
				},
			),
		)
	}

	public suspendService(
		serviceId: string,
		comment: string,
	): Observable<ISuspendServiceResponse>{
		return this.serviceService.suspendService(
			serviceId,
			comment,
		);
	}

	public activateService(
		serviceId: string,
		comment: string,
	) : Observable<IActivateServiceResponse>{
		return this.serviceService.activateService(
			serviceId,
			comment,
		);
	}

}
