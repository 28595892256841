import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ServiceApi } from '@aex/shared/apis';
import {
	IApiCommonFilters,
	IGlobalSearch,
	IGlobalSearchResponse,
	IGridColumn,
	IServicesGridData,
} from '@aex/shared/common-lib';
import {
	IColumnDto,
	ISearchPagedResponse,
	IServiceSearchParams,
	IWorkOrderSearchParams,
} from 'libs/shared/common-lib/src/interfaces/search-service';
import {ApiBaseService} from "./api-base.service";

@Injectable({
	providedIn: 'root',
})
export class SearchService extends ApiBaseService{
	constructor(http: HttpClient) {
		super(http)
	}

	//#todo:portal2 - shouldn't use httpresponse??
	public getAllCustomers(body: IApiCommonFilters): Observable<HttpResponse<IServicesGridData>> {
		return this.http.post<IServicesGridData>(ServiceApi.searchServiceServicesSearch, body, { observe: 'response' });
	}

	public getAllCustomerColumns(): Observable<HttpResponse<IGridColumn[]>> {
		return this.http.get<IGridColumn[]>(ServiceApi.servicesColumns, { observe: 'response' });
	}

	public exportCustomerGridData(selected_grid_columns: IGridColumn[]): Observable<string> {
		return this.http.post(ServiceApi.servicesExportCsv(), { selected_grid_columns: selected_grid_columns }, { responseType: 'text' });
	}

	public globalSearch(body: IGlobalSearch) {
		return this.http.post<IGlobalSearchResponse>(ServiceApi.globalSearch, body, { observe: 'response' });
	}

	// Get all columns from search-service using the way it was done in fno-frontend
	public getServiceColumns(): Observable<HttpResponse<IColumnDto[]>> {
		return this.http.get<IColumnDto[]>(ServiceApi.servicesColumns, { observe: 'response' });
	}

	public getServices(request: Partial<IServiceSearchParams>): Observable<HttpResponse<ISearchPagedResponse>> {
		return this.http.post<ISearchPagedResponse>(ServiceApi.searchServiceServicesSearch, request, { observe: 'response' });
	}

	public getWorkOrderColumns(): Observable<HttpResponse<IColumnDto[]>> {
		return this.http.get<IColumnDto[]>(ServiceApi.workOrderColumns, { observe: 'response' });
	}

	public getWorkOrders(request: Partial<IWorkOrderSearchParams>): Observable<HttpResponse<ISearchPagedResponse>> {
		return this.http.post<ISearchPagedResponse>(ServiceApi.searchWorkOrdersServicesSearch, request, { observe: 'response' });
	}

	public servicesExportCsv(request: Partial<IServiceSearchParams>): Observable<HttpResponse<Blob>> {
		return this.blobPost<Partial<IServiceSearchParams>>(ServiceApi.servicesExportCsv(), request);
	}

	public workOrdersExportCsv(request: Partial<IWorkOrderSearchParams>): Observable<HttpResponse<Blob>> {
		return this.blobPost<Partial<IServiceSearchParams>>(ServiceApi.workOrdersExportCsv(), request);
	}

}
