import { Injectable  } from '@angular/core';
import { GridState } from '..';

@Injectable({
  providedIn: 'root',
})
export class GridStateService {
  private readonly storageKeyPrefix = 'gridState_';

  /**
   * Saves the state of a specific grid.
   * @param gridId - Unique identifier for the grid.
   * @param name - The name for the saved state.
   * @param state - The grid state to save.
   */
  public saveState(gridId: string, name: string, state: GridState): void {
    const key = this.getStorageKey(gridId, name);
    localStorage.setItem(key, JSON.stringify(state));
  }

  /**
   * Loads the state of a specific grid by name.
   * @param gridId - Unique identifier for the grid.
   * @param name - The name of the state to load.
   * @returns The grid state or null if not found.
   */
  public loadState(gridId: string, name: string): GridState | null {
    const key = this.getStorageKey(gridId, name);
    const savedState = localStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : null;
  }

  /**
   * Deletes a specific state for a grid.
   * @param gridId - Unique identifier for the grid.
   * @param name - The name of the state to delete.
   */
  public deleteState(gridId: string, name: string): void {
    const key = this.getStorageKey(gridId, name);
    localStorage.removeItem(key);
  }

  /**
   * Retrieves all saved state names for a specific grid.
   * @param gridId - Unique identifier for the grid.
   * @returns An array of state names.
   */
  public getAllStateNames(gridId: string): string[] {
    return Object.keys(localStorage)
      .filter((key) => key.startsWith(`${this.storageKeyPrefix}${gridId}_`))
      .map((key) => key.replace(`${this.storageKeyPrefix}${gridId}_`, ''));
  }

  private getStorageKey(gridId: string, name: string): string {
    return `${this.storageKeyPrefix}${gridId}_${name}`;
  }

  public getDefaultGridViewName(gridId: string): string {
    const defaultGridStateKey = this.getDefaultGridName(gridId);
    return localStorage.getItem(defaultGridStateKey);
  }

  public setDefaultGridViewName(gridId: string, name: string): void {
    const defaultGridStateKey = this.getDefaultGridName(gridId);
    localStorage.setItem(defaultGridStateKey, name);
  }

  private getDefaultGridName(gridId: string): string {
    return `def_gridstate_${gridId}`;
  }


}
