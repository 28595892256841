import { NavigationService, WithDestroy } from '@aex/ngx-toolbox';
import { CustomerUserService, ICustomer, PORTAL, SERVER_CONFIG } from '@aex/shared/common-lib';
import { AuthService, ConfigService } from '@aex/shared/root-services';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { PortalAuthService } from '../../root-services/portal-auth.service';
import { PortalConfigService } from '../../root-services/portal-config.service';
import { WindowPostMessageService } from '../../services/window-post-message.service';
import { CLASSIC_PORTAL_ERROR_NOT_FOUND } from '../../shared/constant';
import { userIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-portal-settings',
	templateUrl: './portal-settings.component.html',
	styleUrls: ['./portal-settings.component.scss'],
})
export class PortalSettingsComponent extends WithDestroy() implements OnDestroy {

	public readonly userIconSvg = userIcon;
	public currentUser: ICustomer;
	public currentUserDisplayName: string;
	public profileList: ICustomer[] = [];
	public readonly manageProfileRoute: string = PORTAL.manageProfilePage.name;

	@Output() public readonly viewClassicPortalClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public readonly logOutClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public readonly manageProfileClicked: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('portalIframeLogout') public portalIframeRef: ElementRef<HTMLIFrameElement>;
	private isIframeReady = false;
	public trustedUrl = SERVER_CONFIG.portalUrl;

	private get portalAuthService(): PortalAuthService {
		return this.authService as PortalAuthService;
	}

	constructor(
		private readonly authService: AuthService,
		private readonly customerUserService: CustomerUserService,
		private readonly windowPostMessageService: WindowPostMessageService,
		private readonly navigationService: NavigationService,
		private readonly configService: ConfigService,
	) {
		super();
		this.noZombie(this.customerUserService.currentUserStream).subscribe((user) => {
			this.currentUser = user;
			console.log('user', user);
			this.currentUserDisplayName = this.currentUser.full_name || `${this.currentUser.first_name} ${this.currentUser.last_name}` || this.currentUser.username;
			// TODO:portal2 what api fetches profile
			this.profileList = [this.currentUser];
		});

		this.windowPostMessageService.registerLogoutHandler();
		this.noZombie(this.windowPostMessageService.isIframeReadyStream).subscribe((isReady: boolean) => (this.isIframeReady = isReady));
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.windowPostMessageService.removeLogoutHandler();
		// reset iframe state
		this.windowPostMessageService.isIframeReady = false;
	}

	public viewManageProfile(): void {
		this.manageProfileClicked.emit();
	}

	public initiateLogOut(): void {
		const periodTime = 2000;
		const maxRetries = 3;
		let retryCount = 0;
		this.navigationService.startLoading();

		this.noZombie(interval(periodTime))
			.pipe(
				takeWhile(() => retryCount < maxRetries),
				tap(() => retryCount++),
			)
			.subscribe(() => {
				if (this.isIframeReady || retryCount === maxRetries)
					this.logOut();
			});
	}

	private logOut(): void {
		try {
			const portalConfigService = this.configService as PortalConfigService;
			if (portalConfigService?.bypassCookieAuthFlow) {
				this.authService.logout().subscribe();
				this.logOutClicked.emit();
				this.authService.gotoLogin();
			}
			else
				this.portalAuthService.initiatePortalLogOut(this.portalIframeRef);
		} catch (error) {
			console.error(error);
			if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.InternalServerError && error.statusText === CLASSIC_PORTAL_ERROR_NOT_FOUND)
				this.authService.gotoLogin();
		} finally {
			this.navigationService.stopLoading();
		}
	}

	public navigateToClassicPortal(): void {
		this.viewClassicPortalClicked.emit();
		window.location.href = this.configService.operatorPortalBaseUrl;
	}
}
