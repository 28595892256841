import { NotificationsApi } from '@aex/shared/apis';
import { INotificationRequest, INotificationsList } from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(
		private readonly http: HttpClient,
	) {}

	public getNotificationsList(): Observable<INotificationsList[]> {
		return this.http.get<INotificationsList[]>(NotificationsApi.getRequestList());
	}

	public getNotificationRequest(NotificationId: number): Observable<INotificationsList> {
		return this.http.get<INotificationsList>(NotificationsApi.getRequest(NotificationId));
	}

	public publishCustomerNotification(notificationRequest: INotificationRequest): Observable<void> {
		return this.http.post<void>(
			NotificationsApi.publishCustomerNotification(),
			notificationRequest,
		);
	}
}
