export interface INotificationRequest{
	customer_id: string;
	notification_type: NotificationTypes;
	attachments: string[];
	context: object;
}

export enum NotificationTypes{
	documentRequest = 'document-request'
}
