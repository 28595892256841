<div class="flex flex-row justify-end items-center gap-x-2 help-profile-section">
	<div #helpPopupAnchor kendoTooltip position="top" title="Need help?" class="support-icon">
		<kendo-svg-icon [icon]="questionCircleIconSvg" [themeColor]="'light'" [size]="'xlarge'" (click)="toggleHelpPopup()"></kendo-svg-icon>
	</div>

	<span class="k-appbar-separator"></span>
	<!--			kendo chip - icon too small-->
	<!--			kendo button - label always uppercase -->
	<div #profilePopupAnchor class="account-chip" (click)="toggleProfilePopup()">
		<kendo-svg-icon [icon]="userIconSvg" [themeColor]="'dark'" [size]="'large'"></kendo-svg-icon>
		<span>
			{{ fno | titlecase }}
		</span>
	</div>
</div>

<!--TODO:portal2 submit ticket to kendo to provide solution for hiding popups-->
<kendo-popup *ngIf="showHelpPopup" #helpPopup [anchor]="helpPopupAnchor" [anchorAlign]="helpPopupAnchorAlign" [popupAlign]="helpPopupAlign" [margin]="helpPopupMargin" id="help-pop-up"
	(anchorViewportLeave)="hidePopups()">
	<div class="help-popup-container">
		<span>
			Need help?
		</span>
		<p>
			Browse our FAQs for quick answers or submit a support ticket if you need more expert assistance
		</p>
		<button kendoButton [routerLink]="faqRoute" (click)="hidePopups()">FREQUENTLY ASKED QUESTIONS</button>
		<button kendoButton (click)="logATicket()">LOG A TICKET</button>
	</div>
</kendo-popup>

<kendo-popup *ngIf="showProfilePopup" #profilePopup [anchor]="profilePopupAnchor" [anchorAlign]="profilePopupAnchorAlign" [popupAlign]="profilePopupAlign" [margin]="profilePopupMargin" id="profile-pop-up"
	(anchorViewportLeave)="hidePopups()">
	<app-portal-settings (manageProfileClicked)="hidePopups()" (viewClassicPortalClicked)="hidePopups()" (logOutClicked)="hidePopups()"></app-portal-settings>
</kendo-popup>

