import { ConfigService } from '@aex/shared/root-services';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { questionCircleIcon, userIcon } from '@progress/kendo-svg-icons';
import { Align, Margin } from '@progress/kendo-angular-popup';
import { PORTAL } from '@aex/shared/common-lib';

@Component({
	selector: 'app-portal-container-header-settings',
	templateUrl: './portal-container-header-settings.component.html',
	styleUrls: ['./portal-container-header-settings.component.scss'],
})
export class PortalContainerHeaderSettingsComponent {
	public readonly questionCircleIconSvg = questionCircleIcon;
	public readonly userIconSvg = userIcon;

	public fno: string;

	@ViewChild('helpPopupAnchor', { read: ElementRef }) public helpPopupAnchor: ElementRef;
	@ViewChild('helpPopup', { read: ElementRef }) public helpPopup: ElementRef;
	public helpPopupAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
	public helpPopupAlign: Align = { horizontal: 'right', vertical: 'top' };
	private readonly helpPopupMarginHorizontalOffset = -80;
	private readonly helpPopupMarginVerticalOffset = 40;
	public helpPopupMargin: Margin = { horizontal: this.helpPopupMarginHorizontalOffset, vertical: this.helpPopupMarginVerticalOffset };
	public showHelpPopup = false;

	@ViewChild('profilePopupAnchor', { read: ElementRef }) public profilePopupAnchor: ElementRef;
	@ViewChild('profilePopup', { read: ElementRef }) public profilePopup: ElementRef;
	public profilePopupAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
	public profilePopupAlign: Align = { horizontal: 'right', vertical: 'top' };
	private readonly	profilePopupMarginHorizontalOffset= 0;
	private readonly	profilePopupMarginVerticalOffset = 40;
	public profilePopupMargin: Margin = { horizontal: this.profilePopupMarginHorizontalOffset, vertical: this.profilePopupMarginVerticalOffset };
	public showProfilePopup = false;

	public readonly faqRoute: string = PORTAL.faq.name;

	constructor(
		private readonly configService: ConfigService,
	) {
		this.fno = this.configService.fnoName;
	}

	public toggleHelpPopup(): void {
		this.showHelpPopup = !this.showHelpPopup;
		this.showProfilePopup = false;
	}

	public toggleProfilePopup(): void {
		this.showProfilePopup = !this.showProfilePopup;
		this.showHelpPopup = false;
	}

	public hidePopups(): void {
		this.showHelpPopup = false;
		this.showProfilePopup = false;
	}

	@HostListener('document:keydown', ['$event'])
	public keydown(event: KeyboardEvent): void {
		if (event.code === 'Escape')
			this.hidePopups();
	}

	public logATicket(): void {
		this.hidePopups();
		window.location.href = this.configService.operatorTicketUrl;
	}

}
