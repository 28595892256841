import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';

import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import {
	ColumnReorderEvent,
	FilterableSettings,
	GridComponent,
	GridDataResult,
	PageChangeEvent,
	PagerSettings,
	SelectableMode,
	SelectableSettings,
} from '@progress/kendo-angular-grid';
import {
	IPagedResponse,
	WithDestroy,
} from '@aex/ngx-toolbox';
import {
	CompositeFilterDescriptor,
	filterBy,
	groupBy,
	GroupDescriptor,
	orderBy,
	SortDescriptor,
} from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import {
	cancelIcon,
	filterIcon,
	searchIcon,
	selectAllIcon,
	windowRestoreIcon,
	xIcon,
} from '@progress/kendo-svg-icons';
import { instanceToInstance } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';

import {
	DateHelper,
	getKendoEditorType,
	GridColumnItem, hasShowDetailRowRoutine, IDetailSearchData,
	IPagedData,
	ISearchData,
	PAGE_SIZES,
} from '@aex/shared/common-lib';

import { ConfigService } from '@aex/shared/root-services';

@Component({
	selector: 'app-search-grid',
	templateUrl: './search-grid.component.html',
	styleUrls: ['./search-grid.component.scss'],
})
export class SearchGridComponent extends WithDestroy() {
	// TODO:portal2 discuss any for generic
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ContentChild('gridCellTemplate') gridCellTemplate: TemplateRef<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ContentChild('gridDetailTemplate') gridDetailTemplate: TemplateRef<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ContentChild('gridCommandColumnTemplate') gridCommandColumnTemplate: TemplateRef<any>;

	@ViewChild('dataGrid', { static: true }) public dataGrid!: GridComponent;

	public readonly svgCancelIcon = cancelIcon;
	public readonly svgFilterIcon = filterIcon;
	public readonly svgSearchIcon = searchIcon;
	public readonly svgXIcon = xIcon;
	public readonly svgWindowRestoreIcon = windowRestoreIcon;
	public readonly svgSelectAllIcon = selectAllIcon;
	public dateFormat: string;

	public checkboxColumnWidth = 65;
	public minResizableWidth = 150;
	public originalGridData: ISearchData[] = [];
	public totalRecordCount: number = 0;

	// Settings for grid command. Remember to implement template for gridDetailTemplate
	@Input() public haveGridDetailTemplate: boolean = false;

	// Settings for grid command. Remember to implement template for gridCommandColumnTemplate
	@Input() public haveGridCommandTemplate: boolean = false;
	@Input() public gridCommandHeaderClass: string = 'actionsColumnClass';
	@Input() public gridCommandClass: string = 'actionsColumnClass';
	@Input() public isGridCommandFirst : boolean = true;

	// Set to true if data must be fetched from server every time paging has changed.
	@Input() public isRemotePaging: boolean = false;
	@Input() public isGroupable: boolean = false;
	public groups: GroupDescriptor[] = [];
	// Pagination
	@Input() public pageSize = 20;
	public buttonCount = 5;
	public sizes = PAGE_SIZES;
	public skip = 0;
	public currentPage = 1;
	@Input() public pageSettings: PagerSettings = {
		buttonCount: this.buttonCount,
		info: true,
		type: 'numeric',
		pageSizes: this.sizes,
		previousNext: true,
	};
	// Grid Data to be displayed on screen
	public gridDataResult: GridDataResult = {
		data: this.viewableGridData,
		total: this.totalRecordCount,
	};
	public selectableColumns: string[] = [];
	public filteredGridColumns: string[] = [];
	public selectedColumns: string[] = [];
	public visibleColumns: GridColumnItem[] = [];
	@Output() public readonly updateVisibleGridColumnsEvent: EventEmitter<GridColumnItem[]> = new EventEmitter<GridColumnItem[]>();
	@Output() public readonly fetchDataEvent: EventEmitter<{ pageNumber: number, pageSize: number, skip: number }> = new EventEmitter();
	@Output() public readonly nameHyperlinkActionEvent: EventEmitter<{ fieldName: string, dataItem: ISearchData }> =
		new EventEmitter<{ fieldName: string, dataItem: ISearchData }>();
	@Output() public readonly checkBoxSelectionChangeEvent: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() public readonly gridPageChangeEvent: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();
	// Grid Filtering
	@Input() public sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }]; 		// Grid Sort Value
	@Input() public showXLStyleFilter: FilterableSettings = 'menu';            		// Show the XL style filter
	public searchKeyword: string = '';                                		        // Search keyword
	@Input() public selectGridKeyId: string = '';                                // Key Id for the Kendo Grid Select
	public gridFilterFields: FilterExpression[] = [];
	// Selectable Items
	public allSelected: boolean = false;                                  // All rows selected
	@Input() public showCheckboxColumn: boolean = false;                  // Show the checkbox column
	@Input() public gridSelectable: SelectableMode = 'single';           // 'single' | 'multiple'
	@Input() public hideQuickTools: boolean = true;
	@Input() public hideColumnFilter: boolean = true;
	protected readonly defaultFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	public gridFilterValue: CompositeFilterDescriptor = { ...this.defaultFilter };

	constructor(
		protected readonly toastr: ToastrService,
		protected readonly router: Router,
		protected readonly route: ActivatedRoute,
		protected readonly configService: ConfigService,
	) {
		super();
		this.dateFormat = this.configService.operatorDateFormat || 'MM-dd-yyyy HH:mm:ss';
	}

	private _internalDataSet: ISearchData[];

	// @Input() public isToggle : boolean = false;

	public get internalDataSet(): ISearchData[] {
		return this._internalDataSet;
	}

	public set internalDataSet(value: ISearchData[]) {
		// Reset skip/
		this.skip = 0;
		this._internalDataSet = orderBy(value, this.sort);
		this.totalRecordCount = this.internalDataSet.length;
		this.calculateViewableGridData();
	}

	// Grid Data to be displayed on grid
	private _viewableGridData: ISearchData[] = [];

	public get viewableGridData(): ISearchData[] {
		return this._viewableGridData;
	};

	public set viewableGridData(value: ISearchData[]) {
		console.debug('viewableGridData', value);
		this._viewableGridData = value;
		const gridDataResult = {
			data: this.viewableGridData,
			total: this.totalRecordCount,
		};
		this.gridDataResult = { ...gridDataResult };
	}

	@Input()
	public set filterableGridData(gridData: ISearchData[]) {
		if (this.isRemotePaging)
			throw new Error('RemotePaging is set to true');

		// Set grid data
		this.originalGridData = gridData;
		this.internalDataSet = gridData;
	}

	private readonly _pagedData: IPagedData<ISearchData> = {
		items: [],
		total: 0,
		page: 0,
		count: 0,
	};

	public get pagedData(): IPagedData<ISearchData> {
		return this._pagedData;
	}

	// Pass to grid if remote paging is set to true.
	@Input()
	public set pagedData(value: IPagedData<ISearchData>) {
		if (!this.isRemotePaging)
			throw new Error('RemotePaging is set to false');

		this.setPagedData(value);
	}

	// Columns to display
	private _columns: GridColumnItem[] = [];

	public get columns(): GridColumnItem[] {
		return this._columns;
	}

	@Input()
	public set columns(value: GridColumnItem[]) {
		this._columns = value;
		this.selectableColumns = value.map((column) => column.title)
			.sort((a, b) => a.localeCompare(b));
		this.resetGridColumns();
		this.initialiseGridFilterFields();
	}

	private _selectedItems: string[] = [];

	public get selectedItems(): string[] {
		return this._selectedItems;
	}

	public set selectedItems(value: string[])  // Selected row items
	{
		this._selectedItems = value;
		const selectedCount = this.selectedItems.length;
		const totalCount = this.viewableGridData.length;
		this.allSelected = selectedCount > 0 && selectedCount === totalCount;

		this.checkBoxSelectionChangeEvent.emit(this._selectedItems);
	}

	public get selectableSettings(): SelectableSettings {
		return {
			checkboxOnly: this.showCheckboxColumn,
			mode: this.gridSelectable,
		};
	}

	public resetGridColumns(): void {
		this.filteredGridColumns = [...this.selectableColumns];
		const gridColumns = [...this.columns];
		const selectedColumns = [...gridColumns]
			.filter((column) => !column.hidden)
			.map((column) => column.title);

		this.selectedColumns = [...selectedColumns];

		this.visibleColumns = [...gridColumns];
		this.updateVisibleGridColumnsEvent.emit(this.visibleColumns);
	}

	public selectALLGridColumns(): void {
		const selectableColumns = this.columns.map((column) => column.title);
		this.selectedColumns = [...selectableColumns];
		this.selectableColumns = [...selectableColumns];
		this.updateVisibleGridColumns();
	}

	public onFilteredGridColumnChange(filter: string): void {
		const normalizedFilter = filter.toLowerCase();
		this.filteredGridColumns = this.selectableColumns.filter(column =>
			column.toLowerCase().includes(normalizedFilter),
		);
	}

	public onGridSortChange(event: SortDescriptor[]) {
		this.sort = event;
		if (this.internalDataSet) {
			const orderedData = orderBy(this.internalDataSet, this.sort);

			this.internalDataSet = [...orderedData];
		}
	}

	// For client-side filtering
	public filterChange(filter: CompositeFilterDescriptor): void {
		this.gridFilterValue = filter;

		this.internalDataSet = this.filterGridData(this.originalGridData, filter);
	}

	public onGridPageChange(event: PageChangeEvent): void {
		this.pageSize = event.take;
		const currentPage = Math.floor(event.skip / this.pageSize) + 1; // Calculate the page number
		this.skip = event.skip;
		this.currentPage = currentPage;

		if (this.isRemotePaging)
			this.gridPageChangeEvent.emit(event);
		else
			this.calculateViewableGridData();
	}

	public onGridColumnSelectionChange(): void {
		this.updateVisibleGridColumns();
	}

	public gridColumnReorderEvent(event: ColumnReorderEvent): void {
		const sourceIndex = event.oldIndex - 1;
		const targetIndex = event.newIndex - 1;

		// Reorder the records in the visibleColumns array
		const reorderedColumns = [...this.visibleColumns];
		const [removed] = reorderedColumns.splice(sourceIndex, 1);
		reorderedColumns.splice(targetIndex, 0, removed);
		this.visibleColumns = [...reorderedColumns];

		// Updated the columns array
		this.columns = [...reorderedColumns];
	}

	public onSearchKeywordChange() {
		const searchText = this.searchKeyword.trim().toLowerCase(); // Trim to avoid whitespace issues
		if (!searchText) {
			this.internalDataSet = [...this.originalGridData];
			return;
		}
		const filteredSearchKeywordData = this.originalGridData.filter((item) => {
			return Object.values(item).some((value) => {
				if (value !== null) { // Check for non-nullish values

					let formattedValue: string;

					if (value instanceof Date)
						formattedValue = DateHelper.formatDate(value); 	// Format the Date object
					else if (typeof value === 'string')
						formattedValue = value.toLowerCase();			// Convert string to lowercase
					else if (typeof value === 'number')
						formattedValue = value.toString(); 				// Convert number to string
					else
						return false; 														// Skip non-stringifiable values

					return formattedValue.includes(searchText); // Ensure case-insensitivity
				}
				return false;
			});
		});

		this.internalDataSet = [...filteredSearchKeywordData];
	}

	public clearSearch(): void {
		this.searchKeyword = '';
		this.onSearchKeywordChange();
	}

	// If we don't prevent the default action, the Enter key will trigger the Filter Window
	public handleKeydown(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			event.preventDefault();           // Prevent default action
			event.stopPropagation();          // Stop event bubbling
			this.onSearchKeywordChange();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public toggleSelectAll(event: any): void {
		this.allSelected = event.target.checked;

		if (this.allSelected)
			// Select all rows: Get the Ids of all rows
			this.selectedItems = this.viewableGridData.map((item) => item[this.selectGridKeyId as keyof ISearchData]);
		else
			this.selectedItems = [];

		this.checkBoxSelectionChange();
	}

	public checkBoxSelectionChange(): void {
		this.checkBoxSelectionChangeEvent.emit(this.selectedItems);
	}

	public nameHyperlinkAction(field: string, dataItem: ISearchData) {
		this.nameHyperlinkActionEvent.emit({ fieldName: field, dataItem: dataItem });
	}

	getDateFormat(type: 'date' | 'dateTime'): string {
		return type === 'date' ? this.dateFormat.split(' ')[0]: this.dateFormat;
	}

	// Handle the keydown event in the search textbox

	public groupChange(groups: GroupDescriptor[]): void {
		this.groups = groups;
		this.loadData();
	}

	protected initialiseGridFilterFields(): void {
		const filterableColumns = this.columns.filter((column) => column.filterable);
		this.gridFilterFields = filterableColumns.map((column) => {
			return {
				field: column.field || '',
				title: column.title || 'Title Not Set',
				editor: getKendoEditorType(column.data_type),
			};
		});
	}

	protected setPagedData(pagedData: IPagedResponse<ISearchData>): void {
		if (pagedData) {
			this.originalGridData = pagedData?.items;
			this.totalRecordCount = pagedData?.total;
			this.viewableGridData = [...pagedData?.items];
		} else {
			this.originalGridData = [];
			this.totalRecordCount = 0;
			this.viewableGridData = [];
		}
		this.onGridSortChange(this.sort);
	}

	protected updateVisibleGridColumns(): void {
		const selectedSet = new Set(this.selectedColumns);
		const selectedColumns = instanceToInstance(this.columns);
		selectedColumns.forEach((column) => {
			column.hidden = !selectedSet.has(column.title);
		});

		this.visibleColumns = [...selectedColumns];
		this.updateVisibleGridColumnsEvent.emit(this.visibleColumns);
	}

	protected filterGridData(data: ISearchData[], filter: CompositeFilterDescriptor): ISearchData[] {
		return filterBy(data, filter);
	}

	private calculateViewableGridData(): void {
		this.viewableGridData = this.internalDataSet.slice(this.skip, this.skip + this.pageSize);
	}

	private loadData(): void {
		this.viewableGridData = groupBy(this.internalDataSet, this.groups);;
	}

	public showDetailRow(dataItem: IDetailSearchData): boolean {
		// this part needs to be tested
		if (hasShowDetailRowRoutine(dataItem))
			return dataItem.mayShowDetailRow();

		return true;
	}
}
