import { ILoaderDef, IPagedResponse } from '@aex/ngx-toolbox';
import { PurchaseOrderService, ServiceApi} from '@aex/shared/apis';
import {
	AuthType,
	IActivateServiceResponse,
	IFullService,
	IFullServiceResponse,
	IService,
	IServiceCancellationDateDto,
	IServiceHistory,
	IServices,
	IServiceStatus,
	ISuspendServiceResponse,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ServiceService {
	constructor(
		private readonly http: HttpClient,
		protected readonly purchaseOrderService: PurchaseOrderService,
	) {}
	public orderServiceLoader: ILoaderDef = { loader: 'order-service' };

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static buildQuery(field: string, value: string): any {
		return {
			query: {
				bool: {
					must: [
						{
							query_string: {
								query: `${field}:${value}`,
							},
						},
					],
				},
			},
		};
	}

	public getFullServicesForCurrentUserId(currentUserId: string): Observable<IFullService[]> {
		const q = ServiceService.buildQuery('service.customer_id', currentUserId);
		return this.http.post<IPagedResponse<IFullService>>(ServiceApi.servicesSearch, q).pipe(map((r) => r.items));
	}

	public getCustomerServicesDetails(service_id: string): Observable<HttpResponse<IFullServiceResponse>> {
		return this.http.get<IFullServiceResponse>(ServiceApi.fullService(service_id), { observe: 'response' });
	}

	public getAllServices(payload?: unknown): Observable<HttpResponse<IServices>> {
		let body = {};
		if (payload)
			body = payload;
		return this.http.post<IServices>(ServiceApi.statusCounter(), body, {
			params: new ParamMetaData({ authToken: AuthType.USER }),
			observe: 'response',
		});
	}

	public getServicesForCustomer(customer_id: string): Observable<HttpResponse<IPagedResponse<IService>>> {
		return this.http.get<IPagedResponse<IService>>(ServiceApi.customerServices(customer_id), {
			params: new ParamMetaData({ authToken: AuthType.USER }),
			observe: 'response',
		});
	}

	public suspendService(serviceId: string, message: string): Observable<ISuspendServiceResponse> {
		return this.http.post<ISuspendServiceResponse>(ServiceApi.suspendService(serviceId), {
			message,
		});
	}

	public activateService(serviceId: string, message: string): Observable<IActivateServiceResponse> {
		return this.http.post<IActivateServiceResponse>(ServiceApi.suspendService(serviceId), {
			message,
		});
	}

	// #todo portal2.0 -> remove routine
	public suspendCustomerService(serviceId: string, message: string): Observable<HttpResponse<ISuspendServiceResponse>> {
		return this.http.post<ISuspendServiceResponse>(ServiceApi.suspendService(serviceId), {
			message,
		}, {
			observe: 'response',
		});
	}

	public getServiceStatuses(): Observable<HttpResponse<IPagedResponse<IServiceStatus>>> {
		return this.http.get<IPagedResponse<IServiceStatus>>(ServiceApi.serviceStatuses(), {
			observe: 'response',
			responseType: 'json',
		});
	}

	public getServiceHistory(serviceId: string): Observable<IPagedResponse<IServiceHistory>> {
		let paramMetData = new ParamMetaData().withAllCount();
		paramMetData = paramMetData.set('service',serviceId );

		return this.http.get<IPagedResponse<IServiceHistory>>(
			ServiceApi.serviceHistory(),
			{
				params: paramMetData,
			},
	);
	}

	public getCancellationEffectiveDate(serviceId:string): Observable<IServiceCancellationDateDto>{
		return this.http.get<IServiceCancellationDateDto>(
			ServiceApi.serviceCancellationEffectiveDate(serviceId),
		);
	}


}
