import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[appPasswordValidator]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PasswordValidatorDirective,
			multi: true,
		},
	],
})
export class PasswordValidatorDirective implements Validator {
	@Input() public minLength = 8;
	@Input() public requireLowercase = true;
	@Input() public requireUppercase = true;
	@Input() public requireSpecialChar = true;

	constructor() {
	}

	public validate(control: AbstractControl): ValidationErrors | null {
		return this.validatePassword(control);
	}

	private validatePassword(control: AbstractControl): ValidationErrors | null {
		const value: string = control.value || '';

		if (!value)
			return null;

		const errors: ValidationErrors = {
			lowercase: this.requireLowercase && !/[a-z]/.test(value),
			uppercase: this.requireUppercase && !/[A-Z]/.test(value),
			specialChar: this.requireSpecialChar && !/[\W_]/.test(value),
			minlength: value.length < this.minLength,
		};

		// only return error if at least one condition is true
		return Object.values(errors).some(value => value === true) ? errors : null;
	}
}
