import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import { 
	CustomerService,
	SearchService, 
	ServiceService, 
} from "@aex/shared/apis";
import { IPagedResponse } from "@aex/ngx-toolbox";
import { HttpResponse } from "@angular/common/http";
import { 
	IColumnDto, 
	ICustomerService, 
	IFullService, 
	IServiceSearchParams,
} from "../interfaces/search-service";
import { 
  CustomerProfile,
	getAreaStatusClass, 
	getServiceStatusClass, 
	ICustomerProfile, 
	IPagedData, 
	IServiceStateAndAction, 
	IServiceStatus,
	PurchaseOrderDataService,
} from "..";
import { plainToInstance } from "class-transformer";

export const CUSTOMERS_COLUMNS : string[]= [
  'Customer: Full Name',
  'Device Field: Status',
  'Premise: Full Address',
  'Service: Status',
  'Isp Product: Name',
  'Area: Area Status',
  'Area: Name',
];

@Injectable({
	providedIn: 'root',
})
export class CustomerDataService {

	constructor(
		private readonly toastr: ToastrService,
		private readonly purchaseOrderDataService: PurchaseOrderDataService,
		private readonly searchService: SearchService,
		private readonly serviceService: ServiceService,
		private readonly customerService: CustomerService,
	){}
	
	public getCustomerServices(request: Partial<IServiceSearchParams>): Observable<IPagedData<ICustomerService>> {
		return this.searchService.getServices(request).pipe(
			map((response) => {
				const responseResult = response.body;
				const extendedFullServiceData: ICustomerService[] = responseResult.items.map((fullService: IFullService) => ({
					...fullService,
					area_status_class: getAreaStatusClass(fullService.area.area_status),
					service_status_class: getServiceStatusClass(fullService.service.status),
				}));

				const pagedData: IPagedData<ICustomerService> = {
					page: responseResult.page,
					count: responseResult.count,
					total: responseResult.total,
					items: extendedFullServiceData,
				};
				return pagedData;
			}),
			catchError((error) => {
				this.toastr.error(`Error fetching customer services: ${error.message || error}`);
				return of(null);
			}),
		);
	}

	public getGridServiceColumns(): Observable<IColumnDto[]> {		
		return this.searchService.getServiceColumns().pipe(
			map((response) => {
				const columns: IColumnDto[] = response.body;
				columns.sort((a, b) => a.heading.localeCompare(b.heading));
				return columns;
			}),
			catchError((error) => {
				this.toastr.error(`Error fetching customer columns: ${error.message || error}`);
				return of([]); // Return an empty array as a fallback
			}),
		);
	}

	public getServiceStatuses(): Observable<IServiceStatus[]> {	
		return this.serviceService.getServiceStatuses().pipe(
			map((response) => {
				const responseResult: IPagedResponse<IServiceStatus> = response.body;
				const responseData = responseResult.items;
        responseData.sort((a, b) => a.name.localeCompare(b.name));

				return responseData;
			}),
			catchError((error) => {
				this.toastr.error(`Error fetching Service Statuses: ${error.message || error}`);
				return of([]); // Return an empty array as a fallback
			}),
		);
	}

	public getServicePaymentDetailsAndActions(serviceId: string): Observable<IServiceStateAndAction | null> {
		return this.purchaseOrderDataService.getServicePaymentDetailsAndActions(serviceId).pipe(
			catchError((error) => {
				console.error(`Error fetching Service Payment Details And Actions ${serviceId}:`, error);
				return of(null); // Return null as a fallback
			}),
		);
	}

	public getCustomerServicesAndBillingStatus(request: Partial<IServiceSearchParams>): Observable<IPagedData<ICustomerService>> {
		return this.getCustomerServices(request).pipe(
			switchMap((response) => {
				if (response?.count > 0) {
					const customerServiceList = response.items;
	
					// Fetch the billing status for each service
					const billingStatusObservables = customerServiceList.map((customerService) => {
						const serviceId = customerService.service.id.toLowerCase();
						return this.getServicePaymentDetailsAndActions(serviceId).pipe(
							catchError(() => of(null)),
							map((serviceStateAndAction : IServiceStateAndAction) => {
								customerService.billing_status = serviceStateAndAction;
								return customerService;
							}),
						);
					});
	
					return forkJoin(billingStatusObservables).pipe(
						map((updatedServices) => ({
							...response,
							items: updatedServices,
						})),
					);
				} else 
					// If no services, just return the empty response
					return of(response);				
			}),
		);
	}
	
	public servicesExportCsv(request: Partial<IServiceSearchParams>): Observable<HttpResponse<Blob>> {
		return this.searchService.servicesExportCsv(request);
	}

  public getCustomerProfile(selectedCustomerId: string) : Observable<ICustomerProfile> {
    return this.customerService.getCustomerProfile(selectedCustomerId);
  }

	public updateCustomer(id: string, customerProfile: CustomerProfile): Observable<CustomerProfile> {
		return this.customerService.updateCustomerProfile(id, customerProfile).pipe(
			map((response: ICustomerProfile) => plainToInstance(CustomerProfile, response)),
		);
	}
	
}
