import { IPagedResponse } from '@aex/ngx-toolbox';
import {
	AuthType,
	IDeviceHistory,
	IDeviceSpeedTest,
	IDeviceSpeedTestHistory,
	IDeviceStatus,
	IFullDeviceNetworkConfig,
	IFullDeviceNetworkStatus,
	ILayer3Details,
	INetworkDataUsage,
	INetworkState,
	INSLookup,
	INSLookupHistory,
	IPing,
	IPingHistory,
	IQrRedirect,
	IRadiusHistory,
	IRadiusStatus,
	IRawWifiDevices,
	IRebootOntResponseDto,
	IService,
	IServiceConfiguration,
	ITraceRoute,
	ITraceRouteHistory,
	IWifiDevices,
	ParamMetaData,
} from '@aex/shared/common-lib';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceApi, PortalApi, ServiceApi, StatusApi, WifiApi} from '../api';

@Injectable({
	providedIn: 'root',
})
export class DeviceStatusService {
	constructor(private readonly http: HttpClient) {}

	public getNetworkStatus(networkId: string): Observable<INetworkState> {
		return this.http.get<INetworkState>(StatusApi.networkStatus(networkId), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getDeviceFromSerial(serialNumber: string): Observable<IDeviceStatus> {
		return this.http.get<IDeviceStatus>(StatusApi.deviceFromSerial(serialNumber), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getQrCodeRedirects(serialNumber: string): Observable<IQrRedirect> {
		return this.http
			.get<IQrRedirect>(PortalApi.qrRedirect(serialNumber), {
				params: new ParamMetaData({ authToken: AuthType.NONE }),
			})
			.pipe(map((response) => response));
	}

	public getFullServiceDeviceConfigFromSerial(
		serialNumber: string,
		portNo: string,
		serviceId: string,
		refreshStatus : boolean = false,
	): Observable<HttpResponse<IFullDeviceNetworkConfig>> {
		const url = refreshStatus
			? `${StatusApi.fullServiceDeviceConfigFromSerial(serialNumber, portNo)}?refresh=true&service_guid=${serviceId}`
			: `${StatusApi.fullServiceDeviceConfigFromSerial(serialNumber, portNo)}?refresh=false&service_guid=${serviceId}`;
		return this.http.get<IFullDeviceNetworkConfig>(
			url,
			{
				params: new ParamMetaData({ authToken: AuthType.PROXIED }),
				observe: 'response',
			},
		);
	}

	public getFullServiceDeviceStatusFromSerial(serialNumber: string, serviceId: string, refreshStatus: boolean = false): Observable<IFullDeviceNetworkStatus> {
		const url = refreshStatus
			? `${StatusApi.fullServiceDeviceStatusFromSerial(serialNumber)}?refresh=true&service_guid=${serviceId}`
			: `${StatusApi.fullServiceDeviceStatusFromSerial(serialNumber)}?refresh=false&service_guid=${serviceId}`;
		return this.http.get<IFullDeviceNetworkStatus>(
			url,
			{ params: new ParamMetaData({ authToken: AuthType.PROXIED }) },
		);
	}

	public getServiceRadiusStatusFromSerial(serialNumber: string, portNo: string, refreshStatus: boolean = false): Observable<IRadiusStatus> {
		const url = refreshStatus
			? `${StatusApi.serviceRadiusStatusFromSerial(serialNumber, portNo)}?refresh=true`
			: `${StatusApi.serviceRadiusStatusFromSerial(serialNumber, portNo)}?refresh=false`;
		return this.http.get<IRadiusStatus>(
			url,
			{
				params: new ParamMetaData({ authToken: AuthType.PROXIED }),
			},
		);
	}

	public getServiceConfigurationList(): Observable<IPagedResponse<IServiceConfiguration>> {
		return this.http.get<IPagedResponse<IServiceConfiguration>>(`${StatusApi.serviceConfigurations()}`);
	}

	public updateService(serviceId: string, request : Partial<IService>): Observable<IService> {
		return this.http.put<IService>(`${ServiceApi.service(serviceId)}`, { service: request });
	}

	public getWifiInformation(serialNumber: string, refreshStatus: boolean = false): Observable<ILayer3Details> {
		const url = refreshStatus
			? `${WifiApi.wifiInformation(serialNumber)}?refresh=true`
			: `${WifiApi.wifiInformation(serialNumber)}?refresh=false`;
		return this.http.get<ILayer3Details>(
			url,
			{
				params: new ParamMetaData({ authToken: AuthType.PROXIED }),
			},
		);
	}

	public getConnectedWifiDevices(serialNumber: string): Observable<IRawWifiDevices> {
		return this.http.get<IRawWifiDevices>(`${WifiApi.wifiDevicesInfo(serialNumber)}`);
	};

	public getDeviceHistory(serialNumber: string, page: number = 1, count: number = 10): Observable<IPagedResponse<IDeviceHistory>> {
		return this.http.get<IPagedResponse<IDeviceHistory>>(`${StatusApi.deviceHistory(serialNumber)}?page=${page}&count=${count}`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getRadiusHistory(ppoeUserName: string): Observable<IRadiusHistory> {
		return this.http.get<IRadiusHistory>(`${StatusApi.radiusHistory(ppoeUserName)}?full=true`);
	}

  public deviceSpeedTest(serialNumber: string): Observable<IDeviceSpeedTest> {
		return this.http.get<IDeviceSpeedTest>(
			`${DeviceApi.speedTest(serialNumber)}`,
			{
				params: new ParamMetaData({authToken: AuthType.PROXIED}),
			},
		);
	}

  public deviceSpeedTestHistory(serialNumber: string): Observable<IDeviceSpeedTestHistory[]> {
		return this.http.get<IDeviceSpeedTestHistory[]>(`${DeviceApi.speedTestHistory(serialNumber)}`);
	}

	public pingTest(serialNumber: string): Observable<IPing> {
		return this.http.get<IPing>(
			`${DeviceApi.pingTest(serialNumber)}`,
			{
			  params: new ParamMetaData({authToken: AuthType.PROXIED}),
			},
  	);
	}

	public pingTestHistory(serialNumber: string): Observable<IPingHistory[]> {
		return this.http.get<IPingHistory[]>(`${DeviceApi.pingTest(serialNumber)}`);
	}

	public traceRoute(serialNumber: string): Observable<ITraceRoute> {
		return this.http.post<ITraceRoute>(
			`${DeviceApi.traceRouteTest(serialNumber)}`,
			{
				params: new ParamMetaData({authToken: AuthType.PROXIED}),
			},
		);
	}

	public traceRouteHistory(serialNumber: string): Observable<ITraceRouteHistory[]> {
		return this.http.get<ITraceRouteHistory[]>(`${DeviceApi.traceRouteHistory(serialNumber)}`);
	}

	public ngLookup(serialNumber: string): Observable<INSLookup> {
		return this.http.post<INSLookup>(`${DeviceApi.nSLookupTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public ngLookupHistory(serialNumber: string): Observable<INSLookupHistory[]> {
		return this.http.get<INSLookupHistory[]>(`${DeviceApi.nSLookupTest(serialNumber)}`);
	}

	public getWifiDeviceInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.get<IWifiDevices>(`${WifiApi.wifiDevicesInfo(serialNumber)}`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getPingTestInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.pingTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getTracerRouteInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.traceRouteTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}


	public getNsLookupInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.nSLookupTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

  public getNetworkDataUsage(serialNumber: string, fromDate: string, toDate: string):Observable<INetworkDataUsage[]> {
		const url = `${StatusApi.networkDataUsage(serialNumber)}?from_date=${fromDate}&to_date=${toDate}`;
		return this.http.get<INetworkDataUsage[]>(url);
	}

	public rebootOntDevice(serialNumber: string): Observable<IRebootOntResponseDto> {
		return this.http.post<IRebootOntResponseDto>(`${DeviceApi.rebootOnt(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}


}
