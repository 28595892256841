import {
  IBooleanSelection,
  IPromoCategory,
  PromoAreaTreeItem,
  DateRangeSelectorItem,
  TDateRangeOperator,
} from '..';

// export const PROMO_STEP_PROMO_CODE = 1 + PROMO_STEP_DETAIL;

export const PROMO_STEP_DETAIL          = 0;
export const PROMO_STEP_FILTER          = 1 + PROMO_STEP_DETAIL;
export const PROMO_STEP_FILTER_CUSTOMER = 1 + PROMO_STEP_FILTER;
export const PROMO_STEP_SUMMARY         = 1 + PROMO_STEP_FILTER_CUSTOMER;

export function inArrearsList(): IBooleanSelection[] {
  return [
    { id: null, name: 'Not Applicable' },
    { id: true, name: 'In Arrears' },
    { id: false, name: 'Up To Date' },
  ]
}

export enum TPromoCategoryQueryTypeId {
  SingleService = 1,
  MultipleService = 2,
  EligibilityFilter = 3,
  Any = 4,
}

export enum TPromoStatus {
  Active = 1,
  FutureDated = 2,
  Draft = 3,
  Deleted = 4,
  Expired = 5,
}

export function promoStatusAsText(promoStatus: TPromoStatus): string {
  switch (promoStatus) {
    case TPromoStatus.Active:       return 'Active';
    case TPromoStatus.FutureDated:  return 'Future Dated';
    case TPromoStatus.Draft:        return 'Draft';
    case TPromoStatus.Deleted:      return 'Deleted';
    case TPromoStatus.Expired:      return 'Expired';
    default:                        return '';
  }
}

export function getPromoFormTitleClass(promoStatus: TPromoStatus): string {
  switch (promoStatus) {
    case TPromoStatus.Draft:				return 'status-draft';
    case TPromoStatus.Active:				return 'status-active';
    case TPromoStatus.FutureDated:	return 'status-future-dated';
    case TPromoStatus.Expired:
    case TPromoStatus.Deleted:  	  return 'status-deleted';
    default:												return '';
  }
}

export enum TBillingSchemeProductType {
  Recurring = 1,
  DayBundle = 2,
  DayBundleWithAutoRecharge = 3
}

export function getCategoryProcessDescription(
  priceReductionPriceCode: string,
  promoCategoryItem: IPromoCategory,
): string {
  let description: string;
  switch (promoCategoryItem.query_type_id) {
    case TPromoCategoryQueryTypeId.SingleService:
      description = `${priceReductionPriceCode} is applicable to a SINGLE customer.`;
      break;
    case TPromoCategoryQueryTypeId.MultipleService:
      description = `${priceReductionPriceCode} is applicable to MULTIPLE customers.`;
      break;
    case TPromoCategoryQueryTypeId.EligibilityFilter:
      description = `${priceReductionPriceCode} is defined by the ELIGIBILITY FILTER criteria.`;
      break;
    default:
      description = `Unhandled category query type: ${promoCategoryItem.query_type_id}`;
  }

  if (promoCategoryItem.benefit_count_applicable)
    description += ` Period Length is required.`;
  else
    description += ` Period Length cannot be adjusted.`;

  return description;
}

export function findPromoAreaTreeItemById(areaTreeArray: PromoAreaTreeItem[], id: string): PromoAreaTreeItem | null {
  // Search in the current level
  for (const areaTreeItem of areaTreeArray) {
    // Found item?
    if (areaTreeItem.id === id)
      return areaTreeItem;
    // Not found, search in subtree
    if (areaTreeItem.children.length > 0) {
      // Recursive search
      const found = findPromoAreaTreeItemById(areaTreeItem.children, id);
      if (found)
        return found;
    }
    // Not found, continue searching
  }
  return null;
}

export function formatDateYYYYMMDD(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getLocalDate(dateString: string): Date{
  if (dateString === '')
    return null
  else
	return new Date(`${dateString}T00:00:00`);
}

export function setDateRangeItem(dateItem: DateRangeSelectorItem) {

  const noEndDateOperators = [
    TDateRangeOperator.Before,
    TDateRangeOperator.BeforeOrOn,
    TDateRangeOperator.On,
    TDateRangeOperator.After,
    TDateRangeOperator.AfterOrOn,
  ];

  if (noEndDateOperators.includes(dateItem.date_range_operator))
    dateItem.date_end_value = null;

  dateItem.date_start = dateItem.date_start_value ? formatDateYYYYMMDD(dateItem.date_start_value) : null;
  dateItem.date_end = dateItem.date_end_value ? formatDateYYYYMMDD(dateItem.date_end_value) : null;
}

export function getDateRangeItemLocalDate(dateItem: DateRangeSelectorItem) {
  dateItem.date_start_value = getLocalDate(
    dateItem.date_start);
  dateItem.date_end_value = getLocalDate(
    dateItem.date_end);
}

