import { IAppRoute } from '@aex/ngx-toolbox';
import { CUSTOMER_ROUTES, PORTAL_ROUTES, PROMO_AND_DISCOUNT_ROUTES, WORK_ORDERS_ROUTES } from '@aex/shared/common-lib';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerComponent, DrawerItem, DrawerItemExpandedFn, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import
{
	aggregateFieldsIcon,
	calendarIcon,
	chevronDownIcon,
	chevronLeftIcon,
	chevronRightIcon,
	chevronUpIcon,
	gearsIcon,
	graphIcon,
	homeIcon,
	listUnorderedIcon,
	rssIcon,
	SVGIcon,
	userIcon,
} from '@progress/kendo-svg-icons';
import { CLASSIC_PORTAL_ROUTES } from '../../module/classic-portal/classic-portal.routes';

interface INavigationMenuItem extends DrawerItem {
	navMenuRoute: { title: string; route: IAppRoute };
	items?: INavigationMenuItem[];
}

@Component({
	selector: 'app-portal-container',
	templateUrl: './portal-container.component.html',
	styleUrls: ['./portal-container.component.scss'],
})
export class PortalContainerComponent {
	@ViewChild('navDrawer') public navDrawer: DrawerComponent;

	public readonly chevronRightIcon: SVGIcon = chevronRightIcon;
	public readonly chevronLeftIcon: SVGIcon = chevronLeftIcon;
	public readonly chevronDownIcon: SVGIcon = chevronDownIcon;
	public readonly chevronUpIcon: SVGIcon = chevronUpIcon;
	public readonly drawerWidth = 300;
	public readonly drawerMiniWidth = 80;
	public drawerExpanded = true;
	public isHeaderHidden = false;
	private readonly homeNavMenuId = 0;
	private readonly customersNavMenuId = this.homeNavMenuId + 1;
	private readonly tasksNavMenuId = this.customersNavMenuId + 1;
	private readonly pmoNavMenuId = this.tasksNavMenuId + 1;
	private readonly premiseManagementMenuId = this.pmoNavMenuId + 1;
	private readonly predefinedPremisesMenuId = this.premiseManagementMenuId + 1;
	private readonly clientPremisesMenuId = this.predefinedPremisesMenuId + 1;
	private readonly areasAndComplexesMenuId = this.clientPremisesMenuId + 1;
	private readonly addressesMenuId = this.areasAndComplexesMenuId + 1;
	private readonly locationsMenuId = this.addressesMenuId + 1;
	private readonly automatedInsightsNavMenuId = this.locationsMenuId + 1;
	private readonly networkNavMenuId = this.automatedInsightsNavMenuId + 1;
	private readonly devicesMenuId = this.networkNavMenuId + 1;
	private readonly interconnectsMenuId = this.devicesMenuId + 1;
	private readonly manageInterconnectsMenuId = this.interconnectsMenuId + 1;
	private readonly vlansMenuId = this.manageInterconnectsMenuId + 1;
	private readonly productsNavMenuId = this.vlansMenuId + 1;
	private readonly schedulerNavMenuId = this.productsNavMenuId + 1;
	private readonly adminNavMenuId = this.schedulerNavMenuId + 1;
	private readonly manageUsersMenuId = this.adminNavMenuId + 1;
	private readonly taskStatusAllocationMenuId = this.manageUsersMenuId + 1;
	private readonly billingSchemesMenuId = this.taskStatusAllocationMenuId + 1;
	private readonly providerBillingMenuId = this.billingSchemesMenuId + 1;
	private readonly businessRulesMenuId = this.providerBillingMenuId + 1;
	private readonly promotionsAndDiscountsMenuId = this.businessRulesMenuId + 1;
	// TODO:portal2 - update menu icons
	public navMenuItems: INavigationMenuItem[] = [
		{
			navMenuRoute: PORTAL_ROUTES.home,
			text: PORTAL_ROUTES.home.title,
			svgIcon: homeIcon,
			id: this.homeNavMenuId,
		},
		{
			navMenuRoute: { title: CUSTOMER_ROUTES.view.name, route: CUSTOMER_ROUTES.view },
			text: 'Customers (Services)',
			svgIcon: userIcon,
			id: this.customersNavMenuId,
		},
		{
			navMenuRoute: { title: WORK_ORDERS_ROUTES.view.name, route: WORK_ORDERS_ROUTES.view },
			text: `${PORTAL_ROUTES.tasks.title}`,
			svgIcon: listUnorderedIcon,
			id: this.tasksNavMenuId,
		},
		{
			navMenuRoute: null,
			text: PORTAL_ROUTES.pmo.title,
			svgIcon: homeIcon,
			id: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.premiseManagement,
			text: CLASSIC_PORTAL_ROUTES.premiseManagement.title,
			svgIcon: homeIcon,
			id: this.premiseManagementMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.predefinedPremises,
			text: CLASSIC_PORTAL_ROUTES.predefinedPremises.title,
			svgIcon: homeIcon,
			id: this.predefinedPremisesMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.clientPremises,
			text: CLASSIC_PORTAL_ROUTES.clientPremises.title,
			svgIcon: homeIcon,
			id: this.clientPremisesMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.areasAndComplexes,
			text: CLASSIC_PORTAL_ROUTES.areasAndComplexes.title,
			svgIcon: homeIcon,
			id: this.areasAndComplexesMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.addresses,
			text: CLASSIC_PORTAL_ROUTES.addresses.title,
			svgIcon: homeIcon,
			id: this.addressesMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.locations,
			text: CLASSIC_PORTAL_ROUTES.locations.title,
			svgIcon: homeIcon,
			id: this.locationsMenuId,
			parentId: this.pmoNavMenuId,
		},
		{
			navMenuRoute: PORTAL_ROUTES.automatedInsights,
			text: PORTAL_ROUTES.automatedInsights.title,
			svgIcon: graphIcon,
			id: this.automatedInsightsNavMenuId,
		},
		{
			navMenuRoute: null,
			text: PORTAL_ROUTES.network.title,
			svgIcon: rssIcon,
			id: this.networkNavMenuId,
		},
		{
			navMenuRoute: PORTAL_ROUTES.devices,
			text: PORTAL_ROUTES.devices.title,
			svgIcon: rssIcon,
			id: this.devicesMenuId,
			parentId: this.networkNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.interconnects,
			text: CLASSIC_PORTAL_ROUTES.interconnects.title,
			svgIcon: rssIcon,
			id: this.interconnectsMenuId,
			parentId: this.networkNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.manageInterconnects,
			text: CLASSIC_PORTAL_ROUTES.manageInterconnects.title,
			svgIcon: rssIcon,
			id: this.manageInterconnectsMenuId,
			parentId: this.networkNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.vlans,
			text: CLASSIC_PORTAL_ROUTES.vlans.title,
			svgIcon: rssIcon,
			id: this.vlansMenuId,
			parentId: this.networkNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.products,
			text: CLASSIC_PORTAL_ROUTES.products.title,
			svgIcon: aggregateFieldsIcon,
			id: this.productsNavMenuId,
		},
		{
			navMenuRoute: CLASSIC_PORTAL_ROUTES.scheduler,
			text: CLASSIC_PORTAL_ROUTES.scheduler.title,
			svgIcon: calendarIcon,
			id: this.schedulerNavMenuId,
		},
		{
			navMenuRoute: null,
			text: PORTAL_ROUTES.admin.title,
			svgIcon: gearsIcon,
			id: this.adminNavMenuId,
		},
		{
			text: CLASSIC_PORTAL_ROUTES.manageUsers.title,
			navMenuRoute: CLASSIC_PORTAL_ROUTES.manageUsers,
			svgIcon: gearsIcon,
			id: this.manageUsersMenuId,
			parentId: this.adminNavMenuId,
		},
		{
			text: CLASSIC_PORTAL_ROUTES.taskStatusAllocation.title,
			navMenuRoute: CLASSIC_PORTAL_ROUTES.taskStatusAllocation,
			svgIcon: gearsIcon,
			id: this.taskStatusAllocationMenuId,
			parentId: this.adminNavMenuId,
		},
		{
			text: CLASSIC_PORTAL_ROUTES.billingSchemes.title,
			navMenuRoute: CLASSIC_PORTAL_ROUTES.billingSchemes,
			svgIcon: gearsIcon,
			id: this.billingSchemesMenuId,
			parentId: this.adminNavMenuId,
		},
		{
			text: CLASSIC_PORTAL_ROUTES.providerBilling.title,
			navMenuRoute: CLASSIC_PORTAL_ROUTES.providerBilling,
			svgIcon: gearsIcon,
			id: this.providerBillingMenuId,
			parentId: this.adminNavMenuId,
		},
		{
			text: CLASSIC_PORTAL_ROUTES.businessRules.title,
			navMenuRoute: CLASSIC_PORTAL_ROUTES.businessRules,
			svgIcon: gearsIcon,
			id: this.businessRulesMenuId,
			parentId: this.adminNavMenuId,
		},
		{
			// NOTE - requires promos and discounts refactoring; so done this way
			text: 'Promos And Discounts',
			navMenuRoute: { title: PROMO_AND_DISCOUNT_ROUTES.promotionsAndDiscounts.name, route: PROMO_AND_DISCOUNT_ROUTES.promotionsAndDiscounts },
			svgIcon: gearsIcon,
			id: this.promotionsAndDiscountsMenuId,
			parentId: this.adminNavMenuId,
		},
	];

	private expandedIndices: number[] = []; // Collection with the indices of all expanded items
	private lastScrollTop = 0; // used to determine scroll direction
	private readonly scrollThreshold = 152; // same height as portal-app-header-container class in portal-shell-header.component.scss

	constructor(private readonly router: Router) {
		this.initialiseNavMenuState();
	}

	// Predicate function - determines if an item is expanded
	public isItemExpanded: DrawerItemExpandedFn = (item: DrawerItem): boolean => {
		return this.expandedIndices.indexOf(item.id as number) >= 0;
	};

	public onScroll(event: Event): void {
		const target = event.target as HTMLElement;
		const scrollTop = target.scrollTop;

		// Hide header when scrolling DOWN past threshold
		if (scrollTop > this.scrollThreshold && scrollTop > this.lastScrollTop)
			this.isHeaderHidden = true;
		// Show header when scrolling UP and above the show threshold
		else if (scrollTop < this.lastScrollTop && scrollTop < this.scrollThreshold)
			this.isHeaderHidden = false;

		this.lastScrollTop = scrollTop;
	}

	public onSelect(ev: DrawerSelectEvent): void {
		const navigationMenuItem: INavigationMenuItem = ev.item;
		navigationMenuItem.selected = !navigationMenuItem.selected;
		const menuItemId = ev.item.id;

		if (this.expandedIndices.indexOf(menuItemId) >= 0)
			this.expandedIndices = this.expandedIndices.filter((id) => id !== menuItemId && !navigationMenuItem.selected);
		else
			this.expandedIndices.push(menuItemId);

		if (navigationMenuItem.navMenuRoute) {
			this.navDrawer.toggle(false);
			this.router.navigate([navigationMenuItem.navMenuRoute.route.name]).then();
		}
	}

	private initialiseNavMenuState(): void {
		const currentRoute = this.router.url;

		const matchedMenuItem = this.navMenuItems.find((item) => {
			if (item.items)
				return item.items.find((subItem) => currentRoute.includes(subItem.navMenuRoute?.route.path.split('/')[1]));
			else
				return currentRoute.includes(item.navMenuRoute?.route.path.split('/')[1]);
		});

		if (matchedMenuItem) {
			matchedMenuItem.selected = true; // Update the selected state
			if (matchedMenuItem.parentId) {
				const parentMenuItem = this.navMenuItems.find((item) => item.id === matchedMenuItem.parentId);
				this.expandedIndices = [parentMenuItem.id as number];
			}
		} else
			console.error(`No menu item found for route: ${currentRoute}`);
	}
}
